/* eslint-disable react/no-unknown-property */
import React, { useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { MeetingInfo } from './meetingInfo'
import { ProjectContext } from '../../../../context/ProjectContext'
import { useCollapse } from 'react-collapsed'
import { EmptyMeeting } from './emptyMeeting'
import { Notes } from '../../needs/notes'

export const Meeting = ({ meeting }) => {
  const { scrollToMeeting, scrollTopContainer, setSelectedMeeting, scrollToEnd, setScrollToEnd } =
    useContext(ProjectContext)
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({ defaultExpanded: true })
  const [prevScrollPos, setPrevScrollPos] = useState(0)
  const meetRef = useRef(null)

  useEffect(() => {
    if (!meetRef.current || !scrollTopContainer) return

    const checkIntersection = () => {
      if (!meetRef.current || !scrollTopContainer) return

      const meetRect = meetRef.current.getBoundingClientRect()
      const containerRect = scrollTopContainer.getBoundingClientRect()

      const currentScrollPos = scrollTopContainer.scrollTop
      let isIntersecting = false
      if (currentScrollPos > prevScrollPos) {
        // Scrolling down, check if intercepts the top of the container
        isIntersecting = meetRect.top >= containerRect.top && meetRect.top <= containerRect.top + 100
      } else {
        // Scrolling up, check if intercepts the bottom of the container
        isIntersecting = meetRect.bottom <= containerRect.bottom && meetRect.bottom >= containerRect.bottom - 500
      }

      if (isIntersecting) {
        setSelectedMeeting(meeting.id)
      }
      setPrevScrollPos(currentScrollPos)
    }

    // Check intersection on scroll
    scrollTopContainer.addEventListener('scroll', checkIntersection)

    return () => {
      scrollTopContainer.removeEventListener('scroll', checkIntersection)
    }
  }, [scrollTopContainer, prevScrollPos])

  useEffect(() => {
    // scroll to ref
    if (scrollToMeeting === meeting.id) {
      // Calculate the offset
      let topOffset = 0
      let element = meetRef.current
      while (element) {
        topOffset += element.offsetTop
        element = element.offsetParent
      }
      topOffset -= 170
      // Scroll to the element
      scrollTopContainer.scrollTo({
        top: topOffset,
        behavior: 'smooth',
      })
    }
    if (scrollToMeeting === null) {
      scrollTopContainer.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }, [scrollToMeeting, scrollTopContainer])

  useEffect(() => {
    // scroll to ref
    if (scrollToEnd === meeting.id) {
      // Calculate the offset
      let topOffset = 0
      let element = meetRef.current
      while (element) {
        topOffset += element.offsetTop
        element = element.offsetParent
      }
      topOffset += meetRef.current.offsetHeight - scrollTopContainer.offsetHeight

      // Scroll to the element
      scrollTopContainer.scrollTo({
        top: topOffset,
        behavior: 'smooth',
      })
      setScrollToEnd(null)
    }
  }, [scrollToEnd])

  return (
    <div className="mb-4 pb-8 border-b-2 border-grey-200" ref={meetRef}>
      <div>
        <MeetingInfo meeting={meeting} isExpanded={isExpanded} getToggleProps={getToggleProps} />
      </div>
      <div {...getCollapseProps()}>
        {meeting.processing ? (
          <div className="mt-5 relative overflow-hidden h-[215px]">
            {[...Array(3)].map((_, index) => (
              <div className="flex" key={index}>
                {[...Array(24)].map((_, index) => (
                  <div key={index} className="min-w-[105px] min-h-[67px] m-2 bg-[#F9F6EB] rounded shadow"></div>
                ))}
              </div>
            ))}
            <div
              className="absolute"
              style={{ left: '50%', top: '50%', transform: 'translate(-60%, -50%)', opacity: '90%' }}
            >
              <div className="w-[323px] h-[76px] px-10 py-3 bg-white rounded-lg flex justify-center items-center gap-[5px]">
                <svg className="w-[39px] h-[42px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
                  <radialGradient id="a1" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)">
                    <stop offset="0" stopColor="#FF156D"></stop>
                    <stop offset=".3" stopColor="#FF156D" stopOpacity=".9"></stop>
                    <stop offset=".6" stopColor="#FF156D" stopOpacity=".6"></stop>
                    <stop offset=".8" stopColor="#FF156D" stopOpacity=".3"></stop>
                    <stop offset="1" stopColor="#FF156D" stopOpacity="0"></stop>
                  </radialGradient>
                  <circle
                    transform-origin="center"
                    fill="none"
                    stroke="url(#a1)"
                    strokeWidth="15"
                    strokeLinecap="round"
                    strokeDasharray="200 1000"
                    strokeDashoffset="0"
                    cx="100"
                    cy="100"
                    r="70"
                  >
                    <animateTransform
                      type="rotate"
                      attributeName="transform"
                      calcMode="spline"
                      dur="2"
                      values="360;0"
                      keyTimes="0;1"
                      keySplines="0 0 1 1"
                      repeatCount="indefinite"
                    ></animateTransform>
                  </circle>
                  <circle
                    transform-origin="center"
                    fill="none"
                    opacity=".2"
                    stroke="#FF156D"
                    strokeWidth="15"
                    strokeLinecap="round"
                    cx="100"
                    cy="100"
                    r="70"
                  ></circle>
                </svg>
                <div>
                  <p className="mb-[5px] text-slate text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">
                    Usermuse is Analyzing
                  </p>
                  <p className="text-center text-slate text-[13px] font-medium font-['Manrope'] leading-none">
                    Please come back in a few hours.
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : meeting.isEmpty ? (
          <EmptyMeeting meeting={meeting} />
        ) : (
          <Notes notes={meeting.notes} key={meeting.id} />
        )}
      </div>
    </div>
  )
}

Meeting.propTypes = {
  meeting: PropTypes.object,
}
