import React, { useContext } from 'react'
import { ProjectContext } from '../../../context/ProjectContext'
import { Problems } from './problems'
import { Validation } from './validation'
import { Quotes } from './quotes'
import { Needs } from './needs'
import { OverviewFeedbacks } from './feedbacks'
import { Roadmap } from './roadmap'

export function Overview() {
  const { overview, validations, quotes, feedbacks, notes } = useContext(ProjectContext)
  const starredQuotes = quotes.filter((quote) => quote.star)

  return (
    <div className="w-full bg-white rounded-2xl flex flex-col gap-[60px] pl-[60px] pr-7">
      <p className="pt-[40px] -mb-[10px] text-[#3f424a] text-5xl font-normal font-['DM_Sans'] leading-[41.40px]">Overview</p>

      {overview.background && !!overview.background.length && (
        <div className="bg-white rounded-2xl">
          <div className='py-[8px] border-b-2 border-grey-200'>
            <p className="text-[#3f424a] text-4xl font-bold font-['DM_Sans'] leading-[41.40px]">Background</p>
          </div>
          <p className="mt-[36px] text-[#40424A] text-lg font-normal font-['DM_Sans'] leading-[23px]">{overview.background}</p>
        </div>
      )}

      {/* {overview.features && (
        <div className="pt-4 bg-white rounded-2xl">
          <Roadmap features={overview.features} />
        </div>
      )} */}

      {!!starredQuotes.length && <Quotes quotes={starredQuotes} label="Key Quotes" />}

      {Array.isArray(overview.problems) && !!overview.problems.length && <Problems problems={overview.problems} />}

      {!!notes.length && <Needs />}

      {!!feedbacks.length && <OverviewFeedbacks />}

      {!!validations.length && <Validation validations={validations} />}
    </div>
  )
}
