import React, { useContext, useState } from 'react'
import { MeetingsContext } from '../../../../context/MeetingContext'
import { createPortal } from 'react-dom'
import { QuoteDialog } from './quoteDialog'
import { Quote } from './quote'

export function Quotes() {
  const { quotes, setQuotesContainerRef, quotesLoading } = useContext(MeetingsContext)
  const [currentQuote, setCurrentQuote] = useState(-1)
  const [visibleQuotes, setVisibleQuotes] = useState(5)

  // Handle loading more quotes
  const loadMoreQuotes = () => {
    setVisibleQuotes((prev) => prev + 5)
  }

  if (quotesLoading) {
    return (
      <div className="flex justify-center">
        <svg className="w-16 h-16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
          <radialGradient id="a1" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)">
            <stop offset="0" stopColor="#FF156D"></stop>
            <stop offset=".3" stopColor="#FF156D" stopOpacity=".9"></stop>
            <stop offset=".6" stopColor="#FF156D" stopOpacity=".6"></stop>
            <stop offset=".8" stopColor="#FF156D" stopOpacity=".3"></stop>
            <stop offset="1" stopColor="#FF156D" stopOpacity="0"></stop>
          </radialGradient>
          <circle
            transformOrigin="center"
            fill="none"
            stroke="url(#a1)"
            strokeWidth="15"
            strokeLinecap="round"
            strokeDasharray="200 1000"
            strokeDashoffset="0"
            cx="100"
            cy="100"
            r="70"
          >
            <animateTransform
              type="rotate"
              attributeName="transform"
              calcMode="spline"
              dur="2"
              values="360;0"
              keyTimes="0;1"
              keySplines="0 0 1 1"
              repeatCount="indefinite"
            ></animateTransform>
          </circle>
          <circle
            transformOrigin="center"
            fill="none"
            opacity=".2"
            stroke="#FF156D"
            strokeWidth="15"
            strokeLinecap="round"
            cx="100"
            cy="100"
            r="70"
          ></circle>
        </svg>
      </div>
    )
  }

  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-col gap-4 overflow-hidden" ref={setQuotesContainerRef}>
          {quotes.slice(0, visibleQuotes).map((quote, index) => (
            <Quote key={quote.id} quote={quote} index={index} setCurrentQuote={setCurrentQuote} />
          ))}
        </div>

        {visibleQuotes < quotes.length && (
          <button
            className="mt-4 py-2 px-4 bg-blue-600 rounded-md w-full bg-[#E6E6E6] text-zinc"
            onClick={loadMoreQuotes}
          >
            Load More Quotes
          </button>
        )}
      </div>

      {currentQuote >= 0 &&
        currentQuote < quotes.length &&
        createPortal(
          <QuoteDialog
            quote={quotes[currentQuote]}
            currentQuote={currentQuote}
            totalQuotes={quotes.length}
            next={currentQuote > 0 ? () => setCurrentQuote((prev) => prev - 1) : null}
            prev={currentQuote < quotes.length - 1 ? () => setCurrentQuote((prev) => prev + 1) : null}
            closeDialog={() => setCurrentQuote(-1)}
          />,
          document.body
        )}
    </>
  )
}
