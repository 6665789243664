import React, { createContext, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useParams } from 'react-router-dom'
import { collection, doc, getDoc, getDocs, onSnapshot, orderBy, query, where } from 'firebase/firestore'
import { db } from '../firebase'
import { EventsContext } from './EventsContext'
import { useCustomFetch } from '../hooks/useCustomFetch'
import { useDebounce } from '../hooks/useDebounce'
import toast from 'react-hot-toast'
import { AuthContext } from './AuthContext'
import { useFilteredData } from '../hooks/useFilteredData'
import { getPublicStorageUrl } from '../utils'

export const ProjectContext = createContext()

export const ProjectContextProvider = ({ children }) => {
  const param = useParams()
  const navigate = useNavigate()
  const customFetch = useCustomFetch()
  const [isLoading, setIsLoading] = useState(true)
  const [listenRealTime, setListenRealTime] = useState(false)
  const [categories, setCategories] = useState([])
  const [transcripts, setTranscripts] = useState([])
  const [participants, setParticipants] = useState({})
  const [notes, setNotes] = useState([])
  const [qas, setQas] = useState([])
  const [newNoteId, setNewNoteId] = useState('')
  const [groupNotesBy, setGroupNotesBy] = useState('meeting')
  const [menu, setMenu] = useState('overview')
  const [emptyFlag, setEmptyFlag] = useState(true)
  const [scrollToCategory, setScrollToCategory] = useState('')
  const [showDuplicates, setShowDuplicates] = useState(true)
  const [scrollToMeeting, setScrollToMeeting] = useState(null)
  const [scrollToEnd, setScrollToEnd] = useState(null)
  const [scrollTopContainer, setScrollTopContainer] = useState(null)
  const [sidebarScrollContainer, setSidebarScrollContainer] = useState(null)
  const [notesInputRef, setNotesInputRef] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedMeeting, setSelectedMeeting] = useState(null)
  const [meetings, setMeetings] = useState([])
  const [sessions, setSessions] = useState([]) // sessions are meetings with files
  const [overview, setOverview] = useState({})
  const [feedbacks, setFeedbacks] = useState([])
  const [quotes, setQuotes] = useState([])
  const [problems, setProblems] = useState([])
  const [validations, setValidations] = useState([])
  const [indexedValidations, setIndexedValidations] = useState([])
  const [project, setProject] = useState({
    name: '',
    description: '',
    notes_format: 'HMW',
  })
  const [workspace, setWorkspace] = useState({})
  const [newCategory, setNewCategory] = useState([])
  const [categoryCreated, setCategoryCreated] = useState('')
  const debouncedCategoryName = useDebounce(newCategory, 500)
  const [meetingChange, setMeetingChange] = useState({})
  const debouncedMeetingChange = useDebounce(meetingChange, 500)
  const { finishProcessingMeeting, setFinishProcessingMeeting, finishProcessingOverview, setFinishProcessingOverview } =
    useContext(EventsContext)
  const { user } = useContext(AuthContext)

  /******************* FILTERS *******************/
  const {
    data: filteredNotes,
    setFilteredData: setFilteredNotes,
    filters: needsFilters,
    setFilters: setNeedsFilters,
  } = useFilteredData(notes, {
    categories: [],
    star: true,
  })

  const {
    data: filteredFeedbacksOverview,
    filters: feedbacksOverviewFilters,
    setFilters: setFeedbacksOverviewFilters,
  } = useFilteredData(feedbacks, {
    star: true,
  })

  const {
    data: filteredFeedbacks,
    filters: feedbacksFilters,
    setFilters: setFeedbacksFilters,
  } = useFilteredData(feedbacks)

  const { data: filteredQAs, filters: qasFilters, setFilters: setQAsFilters } = useFilteredData(qas)

  const { data: filteredQuotes, filters: quotesFilters, setFilters: setQuotesFilters } = useFilteredData(quotes)

  const { data: filteredProblems, filters: problemsFilters, setFilters: setProblemsFilters } = useFilteredData(problems)

  useEffect(() => {
    if (project.id === finishProcessingMeeting.projectId) {
      setFinishProcessingMeeting({ projectId: '', isLastFile: false })
      setIsLoading(true)
      initializeData()
      if (finishProcessingMeeting.isLastFile && notes.length >= 30) {
        setGroupNotesBy('category')
      }
    }
  }, [finishProcessingMeeting])

  useEffect(() => {
    if (project.id === finishProcessingOverview.projectId) {
      setFinishProcessingOverview({ projectId: '' })
      setIsLoading(true)
      initializeData()
    }
  }, [finishProcessingOverview])

  // ******************** START LIVE LISTENERS ********************

  useEffect(() => {
    // listen to notes in real time
    if (!listenRealTime) return
    if (!project.id || isLoading) return
    const q = query(collection(db, 'needs'), where('project_id', '==', project.id))
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        // check if note already exists before adding
        let isNew = true
        for (const note of notes) {
          if (change.doc.id === note.id) {
            isNew = false
            break
          }
        }

        if (change.type === 'added' && isNew) {
          setNotes((prevNotes) => {
            const updatedNotes = [...prevNotes, { ...change.doc.data(), id: change.doc.id }]
            return updatedNotes.sort((a, b) => a.created_at - b.created_at)
          })
          setNewNoteId(change.doc.id)
          setCategories((prevCats) => {
            if (change.doc.data().category_id) {
              return prevCats.map((cat) =>
                cat.id === change.doc.data().category_id
                  ? {
                      ...cat,
                      amount: cat.amount + 1,
                    }
                  : cat
              )
            }
            if (prevCats.filter((cat) => cat.id === 'Uncategorized').length) {
              return prevCats.map((cat) => (cat.id === 'Uncategorized' ? { ...cat, amount: cat.amount + 1 } : cat))
            }
            return [{ id: 'Uncategorized', content: 'Uncategorized', amount: 1 }, ...prevCats]
          })
        }
      })
    })

    return () => {
      unsubscribe()
      console.log('unsubscribed from needs!')
    }
  }, [listenRealTime, project])

  useEffect(() => {
    // listen to transcripts in real time
    if (!listenRealTime) return
    if (meetings.length === 0 || isLoading) return

    // Array to store all unsubscribe functions
    const unsubscribeFns = []

    for (let i = 0; i < meetings.length; i += 10) {
      const q = query(
        collection(db, 'transcripts'),
        where(
          'meeting_id',
          'in',
          meetings.slice(i, i + 10).map((meet) => meet.id)
        )
      )
      const unsubscribe = onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          let isNew = true
          for (const transcript of transcripts) {
            if (change.doc.id === transcript.id) {
              isNew = false
              break
            }
          }
          if (change.type === 'added' && isNew) {
            setTranscripts((prevTranscripts) => prevTranscripts.concat({ ...change.doc.data(), id: change.doc.id }))
          }
        })
      })

      unsubscribeFns.push(unsubscribe)
    }

    return () => {
      unsubscribeFns.forEach((unsub) => unsub())
      console.log('unsubscribed from transcripts!')
    }
  }, [listenRealTime, meetings])

  useEffect(() => {
    // listen to participants in real time
    if (!listenRealTime) return
    if (meetings.length === 0 || isLoading) return

    // Array to store all unsubscribe functions
    const unsubscribeFns = []

    for (let i = 0; i < meetings.length; i += 10) {
      const q = query(
        collection(db, 'participants'),
        where(
          'meeting_id',
          'in',
          meetings.slice(i, i + 10).map((meet) => meet.id)
        )
      )
      const unsubscribe = onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          // check if transcript already exists before adding
          let isNew = true
          if (participants[change.doc.id] !== undefined) {
            isNew = false
          }

          if (change.type === 'added' && isNew) {
            setParticipants((prevParticipants) => ({ ...prevParticipants, [change.doc.id]: change.doc.data().name }))
          }
        })
      })

      unsubscribeFns.push(unsubscribe)
    }

    return () => {
      unsubscribeFns.forEach((unsub) => unsub())
      console.log('unsubscribed from participants!')
    }
    // return unsubscribe
  }, [listenRealTime, meetings])

  useEffect(() => {
    // listen to meetings in real time (only isLive field)
    if (!listenRealTime) return
    if (meetings.length === 0 || isLoading) return
    const q = query(collection(db, 'meetings'), where('project_id', '==', project.id))
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === 'modified') {
          const index = meetings.findIndex((meet) => meet.id === change.doc.id)
          if (index !== -1) {
            if (change.doc.data().isLive && !meetings[index].isLive) {
              setMeetings((prevMeets) =>
                prevMeets.map((meet) =>
                  meet.id === change.doc.id
                    ? {
                        ...meet,
                        isLive: true,
                      }
                    : meet
                )
              )
            }
            if (!change.doc.data().isLive && meetings[index].isLive) {
              setMeetings((prevMeets) =>
                prevMeets.map((meet) =>
                  meet.id === change.doc.id
                    ? {
                        ...meet,
                        isLive: undefined,
                      }
                    : meet
                )
              )
            }
          }
        }
      })
    })

    return () => {
      unsubscribe()
      console.log('unsubscribed from meetings!')
    }
  }, [listenRealTime, meetings])

  useEffect(() => {
    // listen to workspace in real time (only additional_amount and sub_hours_left fields)
    if (!listenRealTime || isLoading || !user.active_workspace) return

    const unsubscribe = onSnapshot(doc(db, 'workspaces', user.active_workspace), (doc) => {
      if (doc.exists()) {
        if (
          doc.data().additional_amount !== workspace.additional_amount ||
          doc.data().sub_hours_left !== workspace.sub_hours_left
        ) {
          setWorkspace((prevWorkspace) => ({
            ...prevWorkspace,
            additional_amount: doc.data().additional_amount,
            sub_hours_left: doc.data().sub_hours_left,
          }))
        }
      } else {
        console.error('Workspace does not exists')
      }
    })

    return () => {
      unsubscribe()
      console.log('unsubscribed from workspaces!')
    }
  }, [listenRealTime, user.active_workspace])

  // useEffect(() => {
  //   // listen to overview in real time
  //   if (!listenRealTime || isLoading || !user.active_workspace) return
  //   if (meetings.length === 0 || isLoading) return

  //   const unsubscribe = onSnapshot(doc(db, 'overviews', project.id), (doc) => {
  //     if (doc.exists()) {
  //       setOverview({ ...doc.data(), id: doc.id })
  //     } else {
  //       console.error('Overview does not exists')
  //     }
  //   })

  //   return () => {
  //     unsubscribe()
  //     console.log('unsubscribed from overviews!')
  //   }
  // }, [listenRealTime, user.active_workspace])

  useEffect(() => {
    // listen to feedbacks in real time
    if (!listenRealTime) return
    if (!project.id || isLoading) return
    const q = query(collection(db, 'feedbacks'), where('projectId', '==', project.id))
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        // check if feedback already exists before adding
        let isNew = true
        for (const feedback of feedbacks) {
          if (change.doc.id === feedback.id) {
            isNew = false
            break
          }
        }

        if (change.type === 'added' && isNew) {
          setFeedbacks((prevFeedbacks) => prevFeedbacks.concat({ ...change.doc.data(), id: change.doc.id }))
        }
      })
    })

    return () => {
      unsubscribe()
      console.log('unsubscribed from feedbacks!')
    }
  }, [listenRealTime, project])

  // useEffect(() => {
  //   // listen to problems in real time
  //   if (!listenRealTime) return
  //   if (!project.id || isLoading) return
  //   const q = query(collection(db, 'problems'), where('projectId', '==', project.id))
  //   const unsubscribe = onSnapshot(q, (snapshot) => {
  //     snapshot.docChanges().forEach((change) => {
  //       // check if problem already exists before adding
  //       let isNew = true
  //       for (const problem of problems) {
  //         if (change.doc.id === problem.id) {
  //           isNew = false
  //           break
  //         }
  //       }

  //       if (change.type === 'added' && isNew) {
  //         setProblems((prevProblems) => prevProblems.concat({ ...change.doc.data(), id: change.doc.id }))
  //       }
  //     })
  //   })

  //   return () => {
  //     unsubscribe()
  //     console.log('unsubscribed from problems!')
  //   }
  // }, [listenRealTime, project])

  // ******************** FINISH LIVE LISTENERS ********************

  const setQuotesWithThumbnail = async (quotes) => {
    for (let i = 0; i < quotes.length; i++) {
      if (quotes[i].thumb_stored_name) {
        const thumbnailUrl = getPublicStorageUrl('quotes', quotes[i].thumb_stored_name)
        quotes[i].thumbnailUrl = thumbnailUrl
      }
    }
    setQuotes(quotes)
  }

  useEffect(() => {
    if (!meetings.length) return

    async function getValidations() {
      const newValidations = []
      const meetingsOverview = {}
      for (let i = 0; i < meetings.length; i += 10) {
        const q = query(
          collection(db, 'meetingsOverview'),
          where(
            '__name__',
            'in',
            meetings.slice(i, i + 10).map((meet) => meet.id)
          )
        )
        const querySnapshot = await getDocs(q)
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            newValidations.push(doc.data().validation)
            meetingsOverview[doc.id] = doc.data().validation
          })
        }
      }
      setValidations(newValidations)
      setIndexedValidations(meetingsOverview)
    }

    getValidations()
  }, [meetings])

  useEffect(() => {
    // handle browser visibility change (like interruptions, tab changes, etc.)
    // needs to be tested
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        console.log('visibility changed to visible')
        setListenRealTime(true)
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      console.log('visibility changed to invisible')
      setListenRealTime(false)
    }
  }, [])

  async function setInitialData() {
    try {
      // get project data
      const projRef = doc(db, 'projects', param.id)
      const projSnap = await getDoc(projRef)

      if (!projSnap.exists()) {
        toast.error('No documents with that project id!')
        navigate('/dashboard/home')
        return
      }
      if (!Object.keys(user.workspaces).includes(projSnap.data().workspace_id)) {
        toast.error('You do not have access to this project')
        navigate('/dashboard/home')
        return
      }

      setProject({ ...projSnap.data(), id: projSnap.id })

      const workspaceRef = doc(db, 'workspaces', projSnap.data().workspace_id)
      const workspaceSnap = await getDoc(workspaceRef)
      if (!workspaceSnap.exists()) {
        toast.error('No documents with that project id!')
        navigate('/dashboard/home')
        return
      }
      setWorkspace({ ...workspaceSnap.data(), id: workspaceSnap.id })

      // get meetings data
      let q = query(collection(db, 'meetings'), where('project_id', '==', projSnap.id))
      let querySnapshot = await getDocs(q)
      // If no meetings show empty state
      if (querySnapshot.empty) return
      const newMeets = []
      querySnapshot.forEach((doc) => {
        newMeets.push({ ...doc.data(), id: doc.id })
      })
      newMeets.sort((a, b) => {
        let timeA = a.created_at
        let timeB = b.created_at
        if (a.start) {
          timeA = new Date(a.start).getTime()
        }
        if (b.start) {
          timeB = new Date(b.start).getTime()
        }
        return timeA < timeB ? 1 : -1
      })
      setMeetings(newMeets)
      if (newMeets.length) {
        setSelectedMeeting(newMeets.length && newMeets[0].id)
      }

      // transcripts, overview are setted in real time

      // get notes data
      const newNotes = []
      q = query(collection(db, 'needs'), where('project_id', '==', param.id), orderBy('created_at', 'asc'))
      querySnapshot = await getDocs(q)
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          newNotes.push({ ...doc.data(), id: doc.id })
        })
        setNotes(newNotes)
        // If no starred notes, default to all notes
        if (newNotes.filter((note) => note.star).length === 0) {
          setNeedsFilters((prevFilters) => ({ ...prevFilters, star: 'all' }))
        }
      }

      // get qas data
      const qas = []
      q = query(collection(db, 'qas'), where('projectId', '==', param.id), orderBy('start_time', 'asc'))
      querySnapshot = await getDocs(q)
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          qas.push({ ...doc.data(), id: doc.id })
        })
        setQas(qas)
      }

      // get feedbacks data
      const newFeedbacks = []
      q = query(collection(db, 'feedbacks'), where('projectId', '==', param.id), orderBy('start_time', 'asc'))
      querySnapshot = await getDocs(q)
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          newFeedbacks.push({ ...doc.data(), id: doc.id })
        })
        setFeedbacks(newFeedbacks)
        // If no starred feedbacks, default to all feedbacks
        if (newFeedbacks.filter((f) => f.star).length === 0) {
          setFeedbacksOverviewFilters((prevFilters) => ({ ...prevFilters, star: 'all' }))
        }
      }

      const quotes = []
      q = query(collection(db, 'quotes'), where('projectId', '==', param.id))
      querySnapshot = await getDocs(q)
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          quotes.push({ ...doc.data(), id: doc.id })
        })
        setQuotesWithThumbnail(quotes)
      }

      // get categories data
      q = query(collection(db, 'categories'), where('project_id', '==', projSnap.id))
      querySnapshot = await getDocs(q)

      const newCategories = []
      let uncategorizedAmount = newNotes.length
      if (!querySnapshot.empty) {
        setGroupNotesBy('category')
        querySnapshot.forEach((doc) => {
          const amount = newNotes.filter((note) => note.category_id === doc.id).length
          uncategorizedAmount -= amount
          newCategories.push({ ...doc.data(), id: doc.id, amount })
        })
      }
      newCategories.push({ id: 'Uncategorized', content: 'Uncategorized', amount: uncategorizedAmount })

      // Uncategorized should go first, Misc should go last, and the others ordered by amount
      newCategories.sort((a, b) => {
        if (a.content === 'Uncategorized') {
          return -1
        } else if (b.content === 'Uncategorized') {
          return 1
        } else if (a.content === 'Misc') {
          return 1
        } else if (b.content === 'Misc') {
          return -1
        } else if (a.amount > b.amount) {
          return -1
        } else if (a.amount === b.amount) {
          return a.content > b.content ? 1 : -1
        } else {
          return 1
        }
      })
      setCategories(newCategories)

      // get problems data
      const problems = []
      q = query(collection(db, 'problems'), where('projectId', '==', param.id))
      querySnapshot = await getDocs(q)
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          problems.push({ ...doc.data(), id: doc.id })
        })
        setProblems(problems)
      }

      // get overview data
      let overview = {}
      const overviewRef = doc(db, 'overviews', param.id)
      const overviewSnap = await getDoc(overviewRef)
      if (overviewSnap.exists()) {
        let features = overviewSnap.data().features
        if (features) {
          features = features.map((feature) => {
            const needs = []
            for (const need of feature.needs) {
              const note = newNotes.find((note) => note.id === need)
              if (note) {
                needs.push(note)
              } else {
                console.error('Note not found for feature', feature, 'and need', need)
              }
            }
            const pain_points = []
            for (const pain_point of feature.pain_points) {
              const painPoint = problems.find((problem) => problem.id === pain_point)
              if (painPoint) {
                pain_points.push(painPoint)
              } else {
                console.error('Problem not found for feature', feature, 'and problem', pain_point)
              }
            }
            return { ...feature, needs, pain_points }
          })
          features.sort((a, b) => (b.needs.length + b.pain_points.length) - (a.needs.length + a.pain_points.length))
        }
        delete overviewSnap.data().features
        setOverview({ ...overviewSnap.data(), id: overviewSnap.id, features })
        overview = { ...overviewSnap.data(), id: overviewSnap.id, features }
      }

      // If no overview set empty project state.
      if (!!Object.keys(overview).length) {
        setEmptyFlag(false)
      }
    } catch (err) {
      console.log('error initiating project!', err)
    }
  }

  async function initializeData() {
    await setInitialData()
    await new Promise((resolve) => setTimeout(resolve, 500))
    setListenRealTime(true)
    setIsLoading(false)
  }

  useEffect(() => {
    if (typeof user !== 'object' || typeof user.workspaces === 'undefined') return

    initializeData()
  }, [user])

  useEffect(() => {
    if (meetings.length === 0) return

    const setSessionsWithUrls = async () => {
      const files = meetings.filter((meet) => meet.stored_name)
      const sessionsWithUrls = []
      for (const file of files) {
        file.project_name = project.name
        try {
          const res = await customFetch(`/getMeetUrl/${file.id}`, 'GET', null)
          if (res.error) throw res
          file.url = res.url
        } catch (error) {
          console.error('Error getting file URL:', error)
          continue
        }
        // If no thumbnail will use default image
        if (file.thumbnail) {
          const thumbnailUrl = getPublicStorageUrl('thumbs', file.thumbnail)
          file.thumbnailUrl = thumbnailUrl
        }
        sessionsWithUrls.push(file)
      }
      setSessions(sessionsWithUrls)
    }

    setSessionsWithUrls()
  }, [meetings])

  async function handleDeleteCategory(category) {
    if (confirm('Are you sure you want to delete the category?')) {
      let newCategories = categories.filter((cat) => cat.id !== category.id)
      newCategories = newCategories.map((cat) =>
        cat.content === 'Uncategorized'
          ? {
              ...cat,
              amount: cat.amount + category.amount,
            }
          : cat
      )
      setCategories(newCategories)
      const newNotes = notes.map((note) =>
        note.category_id === category.id ? { ...note, category_id: undefined } : note
      )
      setNotes(newNotes)
      await customFetch('/deleteCategory', 'DELETE', { categoryId: category.id, projectId: category.project_id })
    }
  }

  function handleRenameCategory(newValue, category) {
    setNewCategory(newValue)
    setCategories((prevCategories) =>
      prevCategories.map((cat) =>
        cat.id === category.id
          ? {
              ...cat,
              content: newValue,
            }
          : cat
      )
    )
  }

  useEffect(() => {
    if (newCategory === '') return
    const category = categories.filter((cat) => cat.content === newCategory)
    if (category.length === 0) return

    async function updateNotesCategory() {
      await customFetch('/renameCategory', 'PUT', { content: newCategory, categoryId: category[0].id })
    }

    updateNotesCategory()
  }, [debouncedCategoryName])

  useEffect(() => {
    if (!meetingChange || Object.keys(meetingChange).length === 0) return

    async function updateMeetingName() {
      const { meetingId, newValue } = meetingChange
      await customFetch('/updateMeetingName', 'PUT', { meetingId, newValue })
    }

    updateMeetingName()
  }, [debouncedMeetingChange])

  function handleRenameMeeting(newValue, meetingId) {
    setMeetingChange({ newValue, meetingId })
    setMeetings((prevMeetings) =>
      prevMeetings.map((meeting) =>
        meeting.id === meetingId
          ? {
              ...meeting,
              name: newValue,
            }
          : meeting
      )
    )
  }

  /******************* SELECTIONS *******************/

  const [selectedNotes, setSelectedNotes] = useState([])
  const [firstNoteSelected, setFirstNoteSelected] = useState(null)
  const [noteHover, setNoteHover] = useState(null)
  const [showDeleteNotesDialog, setShowDeleteNotesDialog] = useState(false)

  const [selectedFeedbacks, setSelectedFeedbacks] = useState([])
  const [firstFeedbackSelected, setFirstFeedbackSelected] = useState(null)
  const [feedbackHover, setFeedbackHover] = useState(null)
  const [showDeleteFeedbacksDialog, setShowDeleteFeedbacksDialog] = useState(false)

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Delete' || event.key === 'Backspace') {
        if (
          document.activeElement &&
          (document.activeElement.tagName === 'INPUT' ||
            document.activeElement.tagName === 'TEXTAREA' ||
            document.activeElement.isContentEditable)
        ) {
          return
        }

        if (selectedNotes.length) {
          console.log('selectedNotes', selectedNotes)
          prepareDeleteNotes()
        }
        if (selectedFeedbacks.length) {
          console.log('selectedFedbacks', selectedFeedbacks)
          prepareDeleteFeedbacks()
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [selectedNotes, selectedFeedbacks])

  const prepareDeleteNotes = async () => {
    if (selectedNotes.length === 1) {
      const prevNotes = [...notes]
      const prevCategories = [...categories]
      setNotes((prevNotes) => prevNotes.filter((n) => n.id !== selectedNotes[0]))
      const newCategories = JSON.parse(JSON.stringify(categories))
      const note = notes.find((n) => n.id === selectedNotes[0])
      const noteCat = note.category_id ? note.category_id : 'Uncategorized'
      const catIndex = newCategories.findIndex((cat) => cat.id === noteCat)
      newCategories[catIndex].amount--
      setCategories(newCategories)
      customFetch('/deleteNotes', 'DELETE', { notes: [selectedNotes[0]], projectId: project.id })

      toast.custom(
        () => (
          <div
            style={{
              background: '#fff',
              color: '#363636',
              boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05)',
              padding: '16px 20px',
              borderRadius: '8px',
            }}
          >
            {selectedNotes.length} Notes Permanently Deleted!&nbsp;
            <button
              style={{ color: '#0052CC' }}
              onClick={() => {
                setNotes(prevNotes)
                setCategories(prevCategories)
                customFetch('/undoDeleteNotes', 'PUT', { noteId: selectedNotes[0] })
              }}
            >
              Undo
            </button>
          </div>
        ),
        { duration: 5000 }
      )
    } else {
      setShowDeleteNotesDialog(true)
    }
  }

  const handleDeleteNotes = async () => {
    try {
      const prevNotes = [...notes]
      const prevCategories = [...categories]
      const newCategories = JSON.parse(JSON.stringify(categories))
      selectedNotes.forEach((noteId) => {
        const note = notes.find((n) => n.id === noteId)
        const noteCat = note.category_id ? note.category_id : 'Uncategorized'
        const catIndex = newCategories.findIndex((cat) => cat.id === noteCat)
        newCategories[catIndex].amount--
      })
      setCategories(newCategories)
      setNotes((prevNotes) => prevNotes.filter((note) => !selectedNotes.includes(note.id)))
      setSelectedNotes([])
      setFirstNoteSelected(null)
      setNoteHover(null)
      setShowDeleteNotesDialog(false)
      customFetch('/deleteNotes', 'DELETE', {
        notes: selectedNotes,
        projectId: project.id,
      })

      toast.custom(
        () => (
          <div
            style={{
              background: '#fff',
              color: '#363636',
              boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05)',
              padding: '16px 20px',
              borderRadius: '8px',
            }}
          >
            {selectedNotes.length} Notes Permanently Deleted!&nbsp;
            <button
              style={{ color: '#0052CC' }}
              onClick={() => {
                setNotes(prevNotes)
                setCategories(prevCategories)
                customFetch('/undoDeleteNotes', 'PUT', { noteId: selectedNotes[0] })
              }}
            >
              Undo
            </button>
          </div>
        ),
        { duration: 5000 }
      )
    } catch (error) {
      console.log(error)
      toast.error('Whoops! Something went wrong. Please try again.')
    }
  }

  const handleChangeColorNotes = async (event) => {
    function changeColor(data) {
      return data.map((note) => {
        if (selectedNotes.includes(note.id)) {
          return { ...note, color: event }
        }
        return note
      })
    }

    try {
      setNotes(changeColor(notes))
      setFilteredNotes(changeColor(filteredNotes))

      await customFetch('/updateNoteColor', 'PUT', { notes: selectedNotes, projectId: project.id, color: event })
    } catch (error) {
      console.log(error)
      toast.error('Whoops! Something went wrong. Please try again.')
    }
  }

  const handleDuplicateNotes = async (event) => {
    try {
      await customFetch('/duplicateNote', 'POST', { notes: selectedNotes, createdBy: user.id })
    } catch (error) {
      console.log(error)
      toast.error('Whoops! Something went wrong. Please try again.')
    }
  }

  const handleChangeNoteCategory = async (categoryId) => {
    try {
      if (selectedNotes.length === 0) {
        toast.error('No notes selected to update category')
        return
      }
      const newCategories = JSON.parse(JSON.stringify(categories))
      // Update Counters
      selectedNotes.forEach((noteId) => {
        const note = notes.find((n) => n.id === noteId)
        const prevCatId = note.category_id || 'Uncategorized'

        const prevCatIndex = newCategories.findIndex((c) => c.id === prevCatId)
        const newCatIndex = newCategories.findIndex((c) => c.id === categoryId)

        if (prevCatIndex !== -1) newCategories[prevCatIndex].amount--
        if (newCatIndex !== -1) newCategories[newCatIndex].amount++
      })
      setCategories(newCategories)
      // Update Notes
      const updateNotes = (prevNotes) =>
        prevNotes.map((n) =>
          selectedNotes.includes(n.id)
            ? { ...n, category_id: categoryId === 'Uncategorized' ? undefined : categoryId }
            : n
        )

      setNotes(updateNotes)
      setFilteredNotes(updateNotes)
      await customFetch('/updateNoteCategory', 'PUT', { notes: selectedNotes, categoryId })
    } catch (error) {
      console.log(error)
      toast.error('Whoops! Something went wrong. Please try again.')
    }
  }

  const prepareDeleteFeedbacks = async () => {
    if (selectedFeedbacks.length === 1) {
      const prevFeedbacks = [...feedbacks]
      setFeedbacks((prevFeedbacks) => prevFeedbacks.filter((n) => n.id !== selectedFeedbacks[0]))
      customFetch('/deleteFeedbacks', 'DELETE', { feedbacks: [selectedFeedbacks[0]], projectId: project.id })

      toast.custom(
        () => (
          <div
            style={{
              background: '#fff',
              color: '#363636',
              boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05)',
              padding: '16px 20px',
              borderRadius: '8px',
            }}
          >
            {selectedFeedbacks.length} Feedback Permanently Deleted!&nbsp;
            <button
              style={{ color: '#0052CC' }}
              onClick={() => {
                setFeedbacks(prevFeedbacks)
                customFetch('/undoDeleteFeedbacks', 'PUT', { feedbackId: selectedFeedbacks[0] })
              }}
            >
              Undo
            </button>
          </div>
        ),
        { duration: 5000 }
      )
    } else {
      setShowDeleteFeedbacksDialog(true)
    }
  }

  const handleDeleteFeedbacks = async () => {
    try {
      const prevFeedbacks = [...feedbacks]
      setFeedbacks((prevFeedbacks) => prevFeedbacks.filter((feedback) => !selectedFeedbacks.includes(feedback.id)))
      setSelectedFeedbacks([])
      setFirstFeedbackSelected(null)
      setFeedbackHover(null)
      setShowDeleteFeedbacksDialog(false)
      customFetch('/deleteFeedbacks', 'DELETE', {
        feedbacks: selectedFeedbacks,
        projectId: project.id,
      })

      toast.custom(
        () => (
          <div
            style={{
              background: '#fff',
              color: '#363636',
              boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05)',
              padding: '16px 20px',
              borderRadius: '8px',
            }}
          >
            {selectedFeedbacks.length} Feedback Permanently Deleted!&nbsp;
            <button
              style={{ color: '#0052CC' }}
              onClick={() => {
                setFeedbacks(prevFeedbacks)
                customFetch('/undoDeleteFeedbacks', 'PUT', { feedbackId: selectedFeedbacks[0] })
              }}
            >
              Undo
            </button>
          </div>
        ),
        { duration: 5000 }
      )
    } catch (error) {
      console.log(error)
      toast.error('Whoops! Something went wrong. Please try again.')
    }
  }

  return (
    <ProjectContext.Provider
      value={{
        categories,
        setCategories,
        newNoteId,
        notes,
        setNotes,
        qas,
        filteredQAs,
        transcripts,
        participants,
        project,
        setProject,
        sessions,
        meetings,
        setMeetings,
        selectedMeeting,
        setSelectedMeeting,
        selectedCategory,
        setSelectedCategory,
        groupNotesBy,
        setGroupNotesBy,
        isLoading,
        setIsLoading,
        menu,
        setMenu,
        setListenRealTime,
        categoryCreated,
        setCategoryCreated,
        handleDeleteCategory,
        handleRenameCategory,
        handleRenameMeeting,
        showDuplicates,
        setShowDuplicates,
        scrollToCategory,
        setScrollToCategory,
        scrollTopContainer,
        setScrollTopContainer,
        sidebarScrollContainer,
        setSidebarScrollContainer,
        scrollToEnd,
        setScrollToEnd,
        notesInputRef,
        setNotesInputRef,
        scrollToMeeting,
        setScrollToMeeting,
        prepareDeleteNotes,
        handleDeleteNotes,
        handleChangeColorNotes,
        handleDuplicateNotes,
        handleChangeNoteCategory,
        handleDeleteFeedbacks,
        showDeleteNotesDialog,
        setShowDeleteNotesDialog,
        setShowDeleteFeedbacksDialog,
        firstNoteSelected,
        setFirstNoteSelected,
        showDeleteFeedbacksDialog,
        overview,
        validations,
        indexedValidations,
        quotes,
        selectedNotes,
        setSelectedNotes,
        noteHover,
        setNoteHover,
        selectedFeedbacks,
        setSelectedFeedbacks,
        firstFeedbackSelected,
        setFirstFeedbackSelected,
        feedbackHover,
        setFeedbackHover,
        setFeedbacks,
        workspace,
        filteredNotes,
        needsFilters,
        setNeedsFilters,
        feedbacksFilters,
        setFeedbacksFilters,
        filteredFeedbacks,
        feedbacksOverviewFilters,
        setFeedbacksOverviewFilters,
        filteredFeedbacksOverview,
        problems,
        setProblems,
        problemsFilters,
        setProblemsFilters,
        filteredProblems,
        qasFilters,
        setQAsFilters,
        filteredQuotes,
        quotesFilters,
        setQuotesFilters,
        feedbacks,
        emptyFlag,
      }}
    >
      {children}
    </ProjectContext.Provider>
  )
}

ProjectContextProvider.propTypes = {
  children: PropTypes.node,
}
