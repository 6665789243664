import React from 'react'
import PropTypes from 'prop-types'

export function Validation ({ validations }) {
  return (
    <div className='w-full'>
      <div className='py-[8px] border-b-2 border-grey-200'>
        <p className="text-[#3f424a] text-4xl font-bold font-['DM_Sans'] leading-[41.40px]">Validation</p>
      </div>

      <div className='w-full flex flex-wrap gap-[15px] mt-[32px]'>
        {validations.map((v, index) => typeof v === 'object' && (
          <div key={index}>
            <div className={`w-[365px] p-[20px] bg-white rounded-sm shadow border ${v.is_prospect_to_use_it === 'yes' ? 'border-[#1FB911]' : v.is_prospect_to_use_it === 'maybe' ? 'border-[#FFC921]' : 'border-[#D40814]'}`}>
              <div className="w-[315px] h-14 pb-[11px] border-b border-grey-200 flex items-center gap-5">
                {v.is_prospect_to_use_it === 'yes'
                  ? (
                  <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="21.0277" cy="21.8128" r="17.973" fill="white"/>
                    <path d="M30.0142 6.24803C32.725 7.81322 34.98 10.0593 36.556 12.7638C38.132 15.4683 38.9744 18.5376 38.9996 21.6677C39.0249 24.7978 38.2323 27.8802 36.7002 30.6099C35.1681 33.3395 32.9496 35.6217 30.2644 37.2305C27.5793 38.8392 24.5205 39.7188 21.3909 39.7822C18.2613 39.8455 15.1695 39.0904 12.4214 37.5916C9.67335 36.0928 7.36438 33.9022 5.72308 31.2368C4.08178 28.5714 3.16506 25.5235 3.06367 22.395L3.05469 21.8126L3.06367 21.2303C3.16433 18.1264 4.06755 15.1015 5.68529 12.4505C7.30302 9.79954 9.58006 7.61299 12.2944 6.10404C15.0088 4.59508 18.0678 3.8152 21.1733 3.84044C24.2788 3.86567 27.3247 4.69516 30.0142 6.24803ZM27.6903 16.9474C27.3808 16.6379 26.969 16.452 26.5322 16.4245C26.0954 16.3971 25.6636 16.5299 25.3178 16.7982L25.1489 16.9474L19.2304 22.8641L16.9065 20.542L16.7375 20.3928C16.3917 20.1247 15.96 19.992 15.5233 20.0196C15.0866 20.0471 14.675 20.233 14.3656 20.5424C14.0562 20.8518 13.8703 21.2635 13.8427 21.7001C13.8152 22.1368 13.9479 22.5686 14.2159 22.9144L14.3651 23.0833L17.9597 26.6779L18.1286 26.8271C18.4438 27.0717 18.8314 27.2044 19.2304 27.2044C19.6293 27.2044 20.0169 27.0717 20.3321 26.8271L20.5011 26.6779L27.6903 19.4887L27.8394 19.3198C28.1077 18.974 28.2405 18.5422 28.2131 18.1054C28.1856 17.6686 27.9997 17.2568 27.6903 16.9474Z" fill="#1FB912"/>
                  </svg>
                    )
                  : v.is_prospect_to_use_it === 'maybe'
                    ? (
                  <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="19.5" cy="20.2852" r="16.25" fill="white"/>
                    <path d="M19.5 4.03516C28.4749 4.03516 35.75 11.3103 35.75 20.2852C35.7534 24.5515 34.0788 28.6481 31.0877 31.6905C28.0967 34.7328 24.0291 36.4768 19.7633 36.5459C15.4975 36.615 11.3756 35.0037 8.2875 32.0599C5.19944 29.1161 3.39298 25.0759 3.25813 20.8117L3.25 20.2852L3.2565 19.8302C3.497 11.0665 10.6763 4.03516 19.5 4.03516ZM19.5 25.1602C19.102 25.1602 18.7178 25.3063 18.4204 25.5708C18.123 25.8353 17.9329 26.1997 17.8864 26.595L17.875 26.7852L17.8864 26.9915C17.9334 27.3865 18.1236 27.7505 18.4209 28.0146C18.7183 28.2787 19.1023 28.4246 19.5 28.4246C19.8977 28.4246 20.2817 28.2787 20.5791 28.0146C20.8764 27.7505 21.0666 27.3865 21.1136 26.9915L21.125 26.8014L21.1136 26.595C21.0671 26.1997 20.877 25.8353 20.5796 25.5708C20.2822 25.3063 19.898 25.1602 19.5 25.1602ZM21.723 14.3165C20.7503 13.8192 19.6379 13.6655 18.5667 13.8802C17.4956 14.095 16.5285 14.6657 15.8226 15.4995C15.5535 15.8148 15.415 16.221 15.4355 16.635C15.456 17.049 15.634 17.4395 15.9329 17.7266C16.2319 18.0137 16.6292 18.1758 17.0437 18.1795C17.4582 18.1833 17.8585 18.0285 18.1626 17.7469L18.4405 17.4544C18.6802 17.2344 18.9816 17.093 19.3041 17.0494C19.6266 17.0059 19.9547 17.0621 20.2443 17.2107C20.5564 17.3686 20.8092 17.6231 20.9651 17.9363C21.1211 18.2494 21.1718 18.6046 21.1097 18.9489C21.0476 19.2931 20.8761 19.6082 20.6206 19.8472C20.3652 20.0862 20.0394 20.2364 19.6918 20.2754L19.3148 20.2949C18.9014 20.3404 18.5213 20.5426 18.2525 20.8598C17.9838 21.1771 17.8469 21.5853 17.87 22.0005C17.8931 22.4156 18.0745 22.8061 18.3768 23.0916C18.6791 23.3771 19.0793 23.5358 19.4951 23.5352C20.5914 23.5385 21.6568 23.1721 22.5192 22.4954C23.3817 21.8186 23.9908 20.871 24.2484 19.8054C24.5059 18.7398 24.3967 17.6185 23.9385 16.6226C23.4803 15.6266 22.6998 14.8142 21.723 14.3165Z" fill="#FFC921"/>
                  </svg>
                      )
                    : (
                  <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="19.5" cy="19.7852" r="16.25" fill="white"/>
                    <path d="M27.625 5.71248C30.0759 7.12762 32.1148 9.15834 33.5397 11.6036C34.9646 14.0489 35.7262 16.8239 35.7491 19.6539C35.7719 22.484 35.0553 25.2709 33.67 27.7389C32.2848 30.2068 30.279 32.2702 27.8513 33.7248C25.4235 35.1793 22.658 35.9746 19.8284 36.0318C16.9988 36.0891 14.2034 35.4064 11.7188 34.0513C9.23416 32.6961 7.14654 30.7156 5.66258 28.3057C4.17863 25.8958 3.34979 23.1401 3.25813 20.3115L3.25 19.785L3.25813 19.2585C3.34913 16.4521 4.16577 13.7172 5.62841 11.3203C7.09106 8.92352 9.14981 6.94659 11.6039 5.58229C14.0581 4.21799 16.8239 3.51288 19.6316 3.53569C22.4394 3.55851 25.1934 4.30848 27.625 5.71248ZM17.0804 15.1375C16.7399 14.9348 16.3371 14.8635 15.9478 14.937C15.5584 15.0105 15.2093 15.2237 14.9662 15.5366C14.723 15.8494 14.6025 16.2403 14.6274 16.6357C14.6522 17.0312 14.8207 17.4039 15.1011 17.6839L17.2006 19.785L15.1011 21.8861L14.9663 22.0389C14.7137 22.3655 14.5949 22.776 14.6341 23.187C14.6733 23.598 14.8674 23.9786 15.177 24.2517C15.4867 24.5248 15.8887 24.6697 16.3014 24.6571C16.714 24.6445 17.1064 24.4753 17.3989 24.1839L19.5 22.0844L21.6011 24.1839L21.7539 24.3187C22.0805 24.5713 22.491 24.69 22.902 24.6509C23.313 24.6117 23.6937 24.4176 23.9667 24.1079C24.2398 23.7983 24.3847 23.3963 24.3721 22.9836C24.3595 22.5709 24.1903 22.1785 23.8989 21.8861L21.7994 19.785L23.8989 17.6839L24.0338 17.5311C24.2863 17.2045 24.4051 16.794 24.3659 16.383C24.3267 15.972 24.1326 15.5913 23.823 15.3183C23.5133 15.0452 23.1113 14.9003 22.6986 14.9129C22.286 14.9255 21.8936 15.0947 21.6011 15.3861L19.5 17.4856L17.3989 15.3861L17.2461 15.2512L17.0804 15.1375Z" fill="#D40814"/>
                  </svg>
                      )}
                <p className="text-[#40424A] text-lg font-medium font-['DM_Sans'] leading-tight">{v.interviewee_name}</p>
              </div>

              <div className="mt-[8px] w-[315px]">
                <span className="text-[#40424A] text-base font-bold font-['Manrope'] leading-[23px]">{v.is_prospect_to_use_it === 'yes' ? 'Likely' : v.is_prospect_to_use_it === 'maybe' ? 'Maybe' : 'Unlikely'} a prospect. </span>
                {/* <span className="text-[#40424A] text-base font-normal font-['Manrope'] leading-[23px]">{v.reason.length < 340 ? v.reason : v.reason.slice(0, 340) + '...'}</span> */}
                <span className="text-[#40424A] text-base font-normal font-['Manrope'] leading-[23px]">{v.reason}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

Validation.propTypes = {
  validations: PropTypes.array
}
