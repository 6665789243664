import React from 'react'
import PropTypes from 'prop-types'
import { AddMeeting } from '../../../sidebar/addMeeting'

export function AddMeetingDialog ({ handleClose }) {

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-opacity-30 bg-black z-20" onClick={() => handleClose(true)}>
      <div className="absolute left-1/4 right-1/4 bg-white p-[28px] rounded-xl" style={{ top: '30%' }} onClick={(e) => e.stopPropagation()}>
        <p className="mb-[14px] text-[#3f424a] text-[22px] font-bold font-['DM_Sans'] leading-relaxed">Add Meeting</p>
        <AddMeeting />
      </div>
    </div>
  )
}

AddMeetingDialog.propTypes = {
  handleClose: PropTypes.func,
}
