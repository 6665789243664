import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { UserAvatarPlaceholder } from '../../../../icons/UserAvatarPlaceholder'

export function Facilitator({ facilitator, removeFacilitator }) {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <button
        key={facilitator.id}
        onClick={() => removeFacilitator(facilitator.id)}
        className="w-full h-[46px] pl-2 pr-4 py-2 bg-white rounded-lg shadow border border-grey-200 flex items-center justify-between"
      >
        <div className="flex items-center gap-3">
          {facilitator.picture ? (
            <img className="w-[38px] h-[38px] rounded-full border border-grey-200" src={facilitator.picture} />
          ) : (
            <UserAvatarPlaceholder />
          )}
          <p className="text-[#40424A] text-base font-semibold font-['Manrope'] leading-tight">
            {facilitator.name?.trim() || 'Unnamed'}
          </p>
        </div>
        {isHovered && (
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18.6673 9.33398L9.33398 18.6673"
              stroke="#40424A"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.33398 9.33398L18.6673 18.6673"
              stroke="#40424A"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </button>
    </div>
  )
}

Facilitator.propTypes = {
  facilitator: PropTypes.object,
  removeFacilitator: PropTypes.func,
}
