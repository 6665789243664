import React from 'react'
import PropTypes from 'prop-types'

export const ProjectIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1982_5425)">
        <path d="M20.25 5.25H15V11.25H20.25V5.25Z" fill="#E4E4E4" />
        <path d="M15 5.14258H9V14.1426H15V5.14258Z" fill="#FF4A8E" />
        <path
          opacity="0.2"
          d="M9 11.25H3.75V19.5C3.75 19.6989 3.82902 19.8897 3.96967 20.0303C4.11032 20.171 4.30109 20.25 4.5 20.25H8.25C8.44891 20.25 8.63968 20.171 8.78033 20.0303C8.92098 19.8897 9 19.6989 9 19.5V11.25Z"
          fill="#FF4A8E"
        />
        <path
          d="M3.75 5.25H20.25V16.5C20.25 16.6989 20.171 16.8897 20.0303 17.0303C19.8897 17.171 19.6989 17.25 19.5 17.25H15.75C15.5511 17.25 15.3603 17.171 15.2197 17.0303C15.079 16.8897 15 16.6989 15 16.5V14.25H9V19.5C9 19.6989 8.92098 19.8897 8.78033 20.0303C8.63968 20.171 8.44891 20.25 8.25 20.25H4.5C4.30109 20.25 4.11032 20.171 3.96967 20.0303C3.82902 19.8897 3.75 19.6989 3.75 19.5V5.25Z"
          stroke="#AAB0BA"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M9 11.25H3.75" stroke="#AAB0BA" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9 5.25V14.25" stroke="#AAB0BA" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15 11.25H20.25" stroke="#AAB0BA" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15 5.25V14.25" stroke="#AAB0BA" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1982_5425">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const ProjectSmallIcon = ({ disabled }) => {
  if (disabled) {
    return (
      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_6704_26687)">
        <path d="M16.875 5.16992H12.5V10.1699H16.875V5.16992Z" fill="white"/>
        <path d="M16.9814 10.0801H12.6064V15.0801H16.9814V10.0801Z" fill="white"/>
        <path d="M7.69629 5.08008H3.32129V10.0801H7.69629V5.08008Z" fill="white"/>
        <path d="M12.5 5.08008H7.5V12.5801H12.5V5.08008Z" fill="#AFBCCF"/>
        <path opacity="0.2" d="M7.5 10.1699H3.125V17.0449C3.125 17.2107 3.19085 17.3697 3.30806 17.4869C3.42527 17.6041 3.58424 17.6699 3.75 17.6699H6.875C7.04076 17.6699 7.19973 17.6041 7.31694 17.4869C7.43415 17.3697 7.5 17.2107 7.5 17.0449V10.1699Z" fill="#AFBCCF"/>
        <path d="M3.125 5.16992H16.875V14.5449C16.875 14.7107 16.8092 14.8697 16.6919 14.9869C16.5747 15.1041 16.4158 15.1699 16.25 15.1699H13.125C12.9592 15.1699 12.8003 15.1041 12.6831 14.9869C12.5658 14.8697 12.5 14.7107 12.5 14.5449V12.6699H7.5V17.0449C7.5 17.2107 7.43415 17.3697 7.31694 17.4869C7.19973 17.6041 7.04076 17.6699 6.875 17.6699H3.75C3.58424 17.6699 3.42527 17.6041 3.30806 17.4869C3.19085 17.3697 3.125 17.2107 3.125 17.0449V5.16992Z" stroke="#AAB0BA" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.5 10.1699H3.125" stroke="#AAB0BA" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.5 5.16992V12.6699" stroke="#AAB0BA" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.5 10.1699H16.875" stroke="#AAB0BA" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.5 5.16992V12.6699" stroke="#AAB0BA" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_6704_26687">
        <rect width="20" height="20" fill="white" transform="translate(0 0.794922)"/>
        </clipPath>
        </defs>
      </svg>
    )
  }
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6704_1467)">
      <path d="M16.875 5.16992H12.5V10.1699H16.875V5.16992Z" fill="white"/>
      <path d="M16.9814 10.0801H12.6064V15.0801H16.9814V10.0801Z" fill="white"/>
      <path d="M7.69629 5.08008H3.32129V10.0801H7.69629V5.08008Z" fill="white"/>
      <path d="M12.5 5.08008H7.5V12.5801H12.5V5.08008Z" fill="#FF4A8E"/>
      <path opacity="0.2" d="M7.5 10.1699H3.125V17.0449C3.125 17.2107 3.19085 17.3697 3.30806 17.4869C3.42527 17.6041 3.58424 17.6699 3.75 17.6699H6.875C7.04076 17.6699 7.19973 17.6041 7.31694 17.4869C7.43415 17.3697 7.5 17.2107 7.5 17.0449V10.1699Z" fill="#FF4A8E"/>
      <path d="M3.125 5.16992H16.875V14.5449C16.875 14.7107 16.8092 14.8697 16.6919 14.9869C16.5747 15.1041 16.4158 15.1699 16.25 15.1699H13.125C12.9592 15.1699 12.8003 15.1041 12.6831 14.9869C12.5658 14.8697 12.5 14.7107 12.5 14.5449V12.6699H7.5V17.0449C7.5 17.2107 7.43415 17.3697 7.31694 17.4869C7.19973 17.6041 7.04076 17.6699 6.875 17.6699H3.75C3.58424 17.6699 3.42527 17.6041 3.30806 17.4869C3.19085 17.3697 3.125 17.2107 3.125 17.0449V5.16992Z" stroke="#AAB0BA" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.5 10.1699H3.125" stroke="#AAB0BA" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.5 5.16992V12.6699" stroke="#AAB0BA" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.5 10.1699H16.875" stroke="#AAB0BA" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.5 5.16992V12.6699" stroke="#AAB0BA" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
      <clipPath id="clip0_6704_1467">
      <rect width="20" height="20" fill="white" transform="translate(0 0.794922)"/>
      </clipPath>
      </defs>
    </svg>
  )
}

ProjectSmallIcon.propTypes = {
  disabled: PropTypes.bool
}
