import React, { useContext, useEffect, useState } from 'react'
import { timeAgo } from '../../../utils'
import { useCustomFetch } from '../../../hooks/useCustomFetch'
import { AuthContext } from '../../../context/AuthContext'
import { createPortal } from 'react-dom'
import { AddMember } from './addMember'
import { UserAvatarPlaceholder } from '../../icons/UserAvatarPlaceholder'

export function Team() {
  const [isLoading, setIsLoading] = useState(true)
  const [users, setUsers] = useState([])
  const [showInviteMemberDialog, setShowInviteMemberDialog] = useState(false)
  const { user } = useContext(AuthContext)
  const customFetch = useCustomFetch()

  async function getUsersByWorkspace() {
    try {
      let response = await customFetch(`/getUsersByWorkspace/${user.active_workspace}`, 'GET', null)
      const usersRes = response.users.map((member) => {
        return {
          id: member.id,
          email: member.email,
          name: member.name,
          picture: member.picture,
          last_seen: member.last_seen,
          role: member.workspace.role,
          invited_at: member.workspace.invited_at,
          joined_at: member.workspace.joined_at,
        }
      })
      response = await customFetch(`/getInvitesByWorkspace/${user.active_workspace}`, 'GET', null)
      const invitesRes = response.users.map((invite) => {
        return {
          id: invite.id,
          email: invite.email,
          invited_at: invite.created_at,
        }
      })
      const members = [...usersRes, ...invitesRes]
      members.sort((a, b) => b.invited_at - a.invited_at)
      setUsers(members)
    } catch (error) {
      console.error('error searching users', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (typeof user.active_workspace === 'undefined') return
    getUsersByWorkspace()
  }, [user.active_workspace])

  return (
    <div className="bg-grey-100 min-h-screen pr-[102px] pt-[36px]">
      <div className="mb-[36px] flex justify-between items-center">
        <div>
          <p className="text-[#40424A] text-[22px] font-medium font-['DM_Sans'] leading-relaxed">Team</p>
          <p className="text-slate text-lg font-normal font-['DM_Sans'] leading-[23px]">
            Members can schedule & lead interviews, edit & share projects they’re on.
          </p>
        </div>
        <button
          className="w-[143px] h-9 px-3 py-2 mr-[3px] bg-[#FF4A8E] rounded shadow-inner justify-center items-center gap-[3px] inline-flex"
          onClick={() => setShowInviteMemberDialog(true)}
        >
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2862_9560)">
              <path
                d="M10 4.66602V16.3327"
                stroke="white"
                strokeWidth="2.75"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4.16699 10.5H15.8337"
                stroke="white"
                strokeWidth="2.75"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_2862_9560">
                <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
              </clipPath>
            </defs>
          </svg>
          <p className="text-white text-base font-semibold font-['Manrope'] leading-tight">Add Member</p>
        </button>
        {showInviteMemberDialog &&
          createPortal(
            <AddMember setUsers={setUsers} handleClose={() => setShowInviteMemberDialog(false)} />,
            document.body
          )}
      </div>
      <div className="bg-white rounded-2xl shadow pt-2 pb-8">
        {isLoading && (
          <div className="flex justify-center items-center">
            <svg className="w-40 h-40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
              <radialGradient id="a1" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)">
                <stop offset="0" stopColor="#FF156D"></stop>
                <stop offset=".3" stopColor="#FF156D" stopOpacity=".9"></stop>
                <stop offset=".6" stopColor="#FF156D" stopOpacity=".6"></stop>
                <stop offset=".8" stopColor="#FF156D" stopOpacity=".3"></stop>
                <stop offset="1" stopColor="#FF156D" stopOpacity="0"></stop>
              </radialGradient>
              <circle
                transform-origin="center"
                fill="none"
                stroke="url(#a1)"
                strokeWidth="15"
                strokeLinecap="round"
                strokeDasharray="200 1000"
                strokeDashoffset="0"
                cx="100"
                cy="100"
                r="70"
              >
                <animateTransform
                  type="rotate"
                  attributeName="transform"
                  calcMode="spline"
                  dur="2"
                  values="360;0"
                  keyTimes="0;1"
                  keySplines="0 0 1 1"
                  repeatCount="indefinite"
                ></animateTransform>
              </circle>
              <circle
                transform-origin="center"
                fill="none"
                opacity=".2"
                stroke="#FF156D"
                strokeWidth="15"
                strokeLinecap="round"
                cx="100"
                cy="100"
                r="70"
              ></circle>
            </svg>
          </div>
        )}
        {!isLoading &&
          users.map((member) => (
            <div key={member.id} className="flex items-center ml-[35px] mr-[60px] py-[20px] border-b border-grey-200">
              <div className="flex items-center w-4/12">
                {!member.last_seen ? (
                  <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="18" cy="18.7988" r="17.75" fill="#F9F9F9" stroke="#E4E4E4" strokeWidth="0.5" />
                    <g clipPath="url(#clip0_3134_9003)">
                      <path
                        d="M14 13.7988C14 14.8597 14.4214 15.8771 15.1716 16.6273C15.9217 17.3774 16.9391 17.7988 18 17.7988C19.0609 17.7988 20.0783 17.3774 20.8284 16.6273C21.5786 15.8771 22 14.8597 22 13.7988C22 12.738 21.5786 11.7205 20.8284 10.9704C20.0783 10.2203 19.0609 9.79883 18 9.79883C16.9391 9.79883 15.9217 10.2203 15.1716 10.9704C14.4214 11.7205 14 12.738 14 13.7988Z"
                        stroke="#FF4A8E"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 27.7988V25.7988C12 24.738 12.4214 23.7205 13.1716 22.9704C13.9217 22.2203 14.9391 21.7988 16 21.7988H19.5"
                        stroke="#FF4A8E"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M25 28.7988V28.8088"
                        stroke="#FF4A8E"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M25 25.7986C25.4483 25.7972 25.8832 25.6454 26.235 25.3675C26.5868 25.0897 26.8352 24.7019 26.9406 24.2661C27.0459 23.8304 27.0019 23.3719 26.8158 22.964C26.6297 22.5562 26.3122 22.2226 25.914 22.0166C25.5162 21.8128 25.0611 21.7496 24.6228 21.8373C24.1845 21.925 23.7888 22.1584 23.5 22.4996"
                        stroke="#FF4A8E"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3134_9003">
                        <rect width="24" height="24" fill="white" transform="translate(6 6.79883)" />
                      </clipPath>
                    </defs>
                  </svg>
                ) : member.picture ? (
                  <img className="rounded-full" width={36} height={37} src={member.picture} alt="user avatar" />
                ) : (
                  <UserAvatarPlaceholder />
                )}
                <div className="ml-[15px]">
                  <p className="text-[#40424A] text-base font-bold font-['Manrope'] leading-tight">
                    {member.name || member.email}
                  </p>
                  {!member.joined_at || member.joined_at === -1 ? (
                    <p className="w-[265px] text-[#FF4A8E] text-[13px] font-medium font-['Manrope'] leading-none">
                      Pending
                    </p>
                  ) : (
                    <p className="w-[265px] text-slate text-[13px] font-medium font-['Manrope'] leading-none">
                      Joined {timeAgo(member.joined_at)}
                    </p>
                  )}
                </div>
              </div>
              <div className=" w-3/12">
                <p className="text-[#101E9B] text-[13px] font-medium font-['Manrope'] leading-none">{member.email}</p>
              </div>
              <div className=" w-2/12">
                <p className="text-slate text-[13px] font-bold font-['Manrope'] leading-none capitalize">
                  {member.role || 'member'}
                </p>
              </div>
              <div className="w-2/12">
                <p className="text-slate text-[13px] font-medium font-['Manrope'] leading-none">
                  {!member.last_seen || member.joined_at === -1
                    ? `Invited ${timeAgo(member.invited_at)}`
                    : `Last seen ${timeAgo(member.last_seen)}`}
                </p>
              </div>
              <button className="w-1/12 flex justify-end">
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_3095_10873)">
                    <path
                      d="M3 12.7988C3 13.9807 3.23279 15.151 3.68508 16.243C4.13738 17.3349 4.80031 18.3271 5.63604 19.1628C6.47177 19.9985 7.46392 20.6615 8.55585 21.1137C9.64778 21.566 10.8181 21.7988 12 21.7988C13.1819 21.7988 14.3522 21.566 15.4442 21.1137C16.5361 20.6615 17.5282 19.9985 18.364 19.1628C19.1997 18.3271 19.8626 17.3349 20.3149 16.243C20.7672 15.151 21 13.9807 21 12.7988C21 11.6169 20.7672 10.4466 20.3149 9.35468C19.8626 8.26275 19.1997 7.27059 18.364 6.43487C17.5282 5.59914 16.5361 4.9362 15.4442 4.48391C14.3522 4.03162 13.1819 3.79883 12 3.79883C10.8181 3.79883 9.64778 4.03162 8.55585 4.48391C7.46392 4.9362 6.47177 5.59914 5.63604 6.43487C4.80031 7.27059 4.13738 8.26275 3.68508 9.35468C3.23279 10.4466 3 11.6169 3 12.7988Z"
                      stroke="#62718D"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8 12.7988V12.8088"
                      stroke="#62718D"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 12.7988V12.8088"
                      stroke="#62718D"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16 12.7988V12.8088"
                      stroke="#62718D"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3095_10873">
                      <rect width="24" height="24" fill="white" transform="translate(0 0.798828)" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
          ))}
      </div>
    </div>
  )
}
