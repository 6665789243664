import React from 'react'

export function ZoomIcon() {
  return (
    <svg width="79" height="79" viewBox="0 0 79 79" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4769_5830)">
        <path
          d="M66.761 0.289062H12.3298C5.88809 0.289062 0.666016 5.51113 0.666016 11.9529V66.3841C0.666016 72.8258 5.88809 78.0479 12.3298 78.0479H66.761C73.2028 78.0479 78.4248 72.8258 78.4248 66.3841V11.9529C78.4248 5.51113 73.2028 0.289062 66.761 0.289062Z"
          fill="#2D8CFF"
        />
        <path
          d="M65.6674 54.508C66.8824 54.8118 67.9455 54.2043 68.553 53.2931C68.8567 52.8374 68.8567 52.0781 68.8567 50.4075V27.4747C68.8567 25.8041 68.8567 25.1966 68.553 24.5891C68.0974 23.3741 66.8824 22.9185 65.6674 23.3741C62.4781 25.5003 55.4919 31.7271 55.3401 34.309C55.2186 34.7646 55.2186 35.5239 55.2186 36.5871V42.3582C55.2186 43.5732 55.2186 44.0288 55.3401 44.6363C55.4919 45.8513 55.9476 46.9144 56.5551 47.5219C58.3775 48.8887 64.4524 54.3562 65.8193 54.3562L65.6674 54.508ZM10.3857 28.6897C10.3857 26.4116 10.3857 25.1966 10.8414 24.5891C11.1451 23.9816 12.0563 23.3741 12.512 22.9185C13.1195 22.4629 14.1826 22.4629 16.6125 22.4629H36.2041C41.9753 22.4629 44.8609 22.4629 47.1389 23.6779C48.8095 24.8929 50.632 25.956 51.6951 28.2341C52.9101 30.5121 52.9101 33.3977 52.9101 39.1689V49.4962C52.9101 51.7743 52.9101 52.9893 52.4545 53.5968C52.1507 54.2043 51.2395 54.8118 50.7839 55.2674C50.1764 55.723 49.1133 55.723 46.6833 55.723H27.0917C21.3206 55.723 18.435 55.723 16.1569 54.508C14.4863 53.2931 12.6638 52.2299 11.6007 49.9519C10.3857 47.6738 10.3857 44.7882 10.3857 39.017V28.6897Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4769_5830">
          <rect width="77.7588" height="77.7588" fill="white" transform="translate(0.666016 0.289062)" />
        </clipPath>
      </defs>
    </svg>
  )
}