import React, { useContext } from 'react'
import { ProjectContext } from '../../../context/ProjectContext'
import Tippy from '@tippyjs/react'
import toast from 'react-hot-toast'

export function EmailInvite() {
  const { project } = useContext(ProjectContext)

  async function copyToClipboard() {
    try {
      await navigator.clipboard.writeText(`invite+${project.stem}@usermuse.ai`)
      toast.success('Copied!')
    } catch (err) {
      console.log(err)
      toast.error('Failed to copy!')
    }
  }

  return (
    <div className="flex items-center mx-2">
      <p className="mr-1 text-slate text-xs font-extrabold font-['Manrope']">EMAIL</p>
      <Tippy maxWidth={300} content="Use this unique email to invite Usermuse to additional meetings for this project.">
        <div className="ml-1 mr-6">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.7">
              <path
                d="M6.26367 11C9.0251 11 11.2637 8.76142 11.2637 6C11.2637 3.23858 9.0251 1 6.26367 1C3.50225 1 1.26367 3.23858 1.26367 6C1.26367 8.76142 3.50225 11 6.26367 11Z"
                stroke="#62718D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path d="M6.26367 8.77734V8.78257" stroke="#62718D" strokeLinecap="round" strokeLinejoin="round" />
              <path
                d="M6.26345 6.83397C6.25323 6.65362 6.30188 6.47483 6.40208 6.32453C6.50228 6.17423 6.6486 6.06056 6.81901 6.00063C7.02783 5.92078 7.21526 5.79354 7.36655 5.62894C7.51784 5.46434 7.62885 5.26687 7.69086 5.05207C7.75286 4.83728 7.76416 4.61102 7.72386 4.39112C7.68356 4.17121 7.59277 3.96366 7.45863 3.78481C7.32449 3.60596 7.15066 3.46068 6.95084 3.36042C6.75102 3.26016 6.53065 3.20766 6.30708 3.20704C6.08351 3.20642 5.86286 3.2577 5.66248 3.35685C5.4621 3.456 5.28747 3.60031 5.15234 3.77841"
                stroke="#62718D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>
        </div>
      </Tippy>
      <button
        className="h-[27px] opacity-50 bg-[#e5e5e5] rounded-[50px] flex items-center p-4"
        onClick={copyToClipboard}
      >
        <p className="text-zinc text-[13px] font-medium font-['Manrope'] mr-2">invite+{project.stem}@usermuse.ai</p>
        <svg xmlns="http://www.w3.org/2000/svg" width="12px" height="12px" viewBox="0 0 15 15" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 9.50006C1 10.3285 1.67157 11.0001 2.5 11.0001H4L4 10.0001H2.5C2.22386 10.0001 2 9.7762 2 9.50006L2 2.50006C2 2.22392 2.22386 2.00006 2.5 2.00006L9.5 2.00006C9.77614 2.00006 10 2.22392 10 2.50006V4.00002H5.5C4.67158 4.00002 4 4.67159 4 5.50002V12.5C4 13.3284 4.67158 14 5.5 14H12.5C13.3284 14 14 13.3284 14 12.5V5.50002C14 4.67159 13.3284 4.00002 12.5 4.00002H11V2.50006C11 1.67163 10.3284 1.00006 9.5 1.00006H2.5C1.67157 1.00006 1 1.67163 1 2.50006V9.50006ZM5 5.50002C5 5.22388 5.22386 5.00002 5.5 5.00002H12.5C12.7761 5.00002 13 5.22388 13 5.50002V12.5C13 12.7762 12.7761 13 12.5 13H5.5C5.22386 13 5 12.7762 5 12.5V5.50002Z"
            fill="#FF4A8E"
          />
        </svg>
      </button>
    </div>
  )
}
