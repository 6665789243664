import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import { useCustomFetch } from '../../../hooks/useCustomFetch'
import toast from 'react-hot-toast'
import { Card } from './card'
import { RequestCard } from './requestCard'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { SwitchCalendarDialog } from './switchCalendarDialog'
import { createPortal } from 'react-dom'
import { GoogleIcon } from '../../icons/Google'
import { MicrosoftIcon } from '../../icons/Microsoft'
import { ZoomIcon } from '../../icons/Zoom'
import { SlackIcon } from '../../icons/Slack'

const IntegrationKeys = {
  Z: 'zoom',
  G: 'googleCalendar',
  M: 'microsoftCalendar',
  S: 'slack',
}

export function Integrations() {
  const { user, setUser } = useContext(AuthContext)
  const [connected, setConnected] = useState([])
  const [disconnected, setDisconnected] = useState([])
  const [products, setProducts] = useState([])
  const [showSwitchDialog, setShowSwitchDialog] = useState('') // googleCalendar, microsoftCalendar
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const signInMethod = localStorage.getItem('signin_method')
  const customFetch = useCustomFetch()

  useEffect(() => {
    if (!user.integrations || !Object.keys(user.integrations).length) return
    const code = searchParams.get('code')
    const state = searchParams.get('state')

    const connected = []
    const disconnected = []
    for (const key of Object.keys(user.integrations)) {
      if (user.integrations[key]) connected.push(key)
      else disconnected.push(key)
      const isZoom = !state && key === IntegrationKeys.Z
      const isMicrosoft = state === IntegrationKeys.M && key === IntegrationKeys.M
      const isSlack = key === IntegrationKeys.S

      // If the user is not connected but has a code in the URL, connect them.
      if (code && (isMicrosoft || isZoom || isSlack)) {
        if (!user.integrations[key]) handleConnect(key, code)
        // If already connected, remove code from URL
        else navigate('/dashboard/integrations')
      }
    }
    setConnected(connected)
    setDisconnected(disconnected)
  }, [user.integrations])

  useEffect(() => {
    async function getProducts() {
      try {
        const response = await customFetch('/getProductRequests', 'GET', null)
        if (response.error) throw new Error(response.error)
        response.products.sort((a, b) => a.order - b.order)
        setProducts(response.products)
      } catch (error) {
        console.error('Error getting products:', error)
      }
    }

    getProducts()
  }, [])

  const handleRequest = async (id, name) => {
    try {
      const response = await customFetch('/createRequest', 'POST', { productId: id, productName: name })
      if (response.error) throw new Error(response.error)
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.id === id
            ? {
              ...product,
              requested: true,
            }
            : product,
        ),
      )
    } catch (error) {
      console.error('Error getting products:', error)
    }
  }

  function prepareHandleConnect(key, showDialog) {
    if (key === IntegrationKeys.Z) return zoomSignIn()
    if (key === IntegrationKeys.S) return slackSignIn()
    if (showDialog) {
      if (key === IntegrationKeys.G && connected.includes(IntegrationKeys.M)) return setShowSwitchDialog(key)
      if (key === IntegrationKeys.M && connected.includes(IntegrationKeys.G)) return setShowSwitchDialog(key)
    } else {
      setShowSwitchDialog('')
    }
    if (key === IntegrationKeys.G) {
      if (signInMethod === 'google') return handleConnect(IntegrationKeys.G, null)
      else return googleCalendarSignIn()
    }
    if (key === IntegrationKeys.M) {
      if (signInMethod === 'microsoft') return handleConnect(IntegrationKeys.M, null)
      else return microsoftCalendarSignIn()
    }
  }

  const integrationStrategies = {
    [IntegrationKeys.G]: async () => {
      setUser((prevUser) => ({
        ...prevUser,
        integrations: { ...prevUser.integrations, googleCalendar: true, microsoftCalendar: false },
      }))
    },
    [IntegrationKeys.M]: async () => {
      setUser((prevUser) => ({
        ...prevUser,
        integrations: { ...prevUser.integrations, googleCalendar: false, microsoftCalendar: true },
      }))
    },
    default: async (key) => {
      setUser((prevUser) => ({
        ...prevUser,
        integrations: { ...prevUser.integrations, [key]: true },
      }))
    },
  }

  async function handleConnect(key, code) {
    try {
      const response = await customFetch('/auth/connectIntegration', 'PUT', {
        key,
        code: code || searchParams.get('code'),
      })
      if (response.error) throw new Error(response.error)
      if (response.url) {
        window.location.href = response.url
        return
      }

      const strategy = integrationStrategies[key] || integrationStrategies.default
      await strategy(key)
    } catch (error) {
      console.error('Error connecting:', error, key)
      toast.error('Error connecting')
    }
  }

  async function handleDisconnect(key) {
    try {
      const response = await customFetch('/auth/disconnectIntegration', 'PUT', { key })
      if (response.error) throw new Error(response.error)
      setUser((prevUser) => ({ ...prevUser, integrations: { ...prevUser.integrations, [key]: false } }))
    } catch (error) {
      console.error('Error disconnecting:', error, key)
      toast.error('Error disconnecting')
    }
  }

  const zoomSignIn = async () => {
    try {
      const response = await customFetch('/auth/getZoomUrl', 'POST', {})
      if (response.error) throw new Error(response.error)
      if (response.url) window.location.href = response.url
    } catch (error) {
      console.error('Error searching zoom:', error)
      toast.error('Error connecting')
    }
  }

  const slackSignIn = async () => {
    try {
      const response = await customFetch('/auth/getSlackUrl', 'POST', {})
      if (response.error) throw new Error(response.error)
      if (response.url) window.location.href = response.url
    } catch (error) {
      console.error('Error searching slack:', error)
      toast.error('Error connecting')
    }
  }

  const googleCalendarSignIn = async () => {
    window.google.accounts.oauth2
      .initCodeClient({
        client_id: import.meta.env.VITE_OAUTH_CLIENT_ID,
        scope:
          'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/calendar.readonly',
        ux_mode: 'popup',
        callback: async (response) => handleConnect(IntegrationKeys.G, response.code),
      })
      .requestCode()
  }

  const microsoftCalendarSignIn = async () => {
    const clientId = import.meta.env.VITE_MICROSOFT_CLIENT_ID
    const redirectUri = encodeURIComponent(import.meta.env.VITE_CLIENT_URL + '/dashboard/integrations')
    const state = encodeURIComponent(IntegrationKeys.M)
    const scopes = encodeURIComponent('User.Read Calendars.Read offline_access')
    window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&scope=${scopes}&response_mode=query&state=${state}`
  }

  return (
    <>
      <div className="ml-[39px] mt-[31px] flex flex-col gap-[37px]">
        <p className="text-[#3f424a] text-[26px] font-semibold font-['Manrope'] leading-[29.90px]">Integrations</p>

        {!!connected.length && (
          <div>
            <p className="mb-[15px] text-[#62708d] text-lg font-bold font-['Manrope'] leading-tight tracking-wide">
              Already Connected
            </p>
            <div className="flex items-center gap-[19px]">
              {connected.map((key) => (
                <Card
                  key={key}
                  connected={true}
                  name={getName(key)}
                  description={getDescription(key)}
                  logo={getLogo(key)}
                  handleDisconnect={() => handleDisconnect(key)}
                />
              ))}
            </div>
          </div>
        )}
        <div>
          <p className="mb-[15px] text-[#62708d] text-lg font-bold font-['Manrope'] leading-tight tracking-wide">
            Other Integrations
          </p>
          <div className="flex items-center gap-[19px] flex-wrap">
            {disconnected.map((key) => (
              <Card
                key={key}
                connected={false}
                name={getName(key)}
                description={getDescription(key)}
                logo={getLogo(key)}
                handleConnect={() => prepareHandleConnect(key, true)}
              />
            ))}
            {products.map((product) => (
              <RequestCard
                key={product.id}
                requested={product.requested}
                logo={<div dangerouslySetInnerHTML={{ __html: product.icon }} />}
                name={product.name}
                description={product.description}
                handleRequest={() => handleRequest(product.id, product.name)}
              />
            ))}
          </div>
        </div>
      </div>
      {showSwitchDialog &&
        createPortal(
          <SwitchCalendarDialog
            handleClose={() => setShowSwitchDialog('')}
            handleSwitch={() => prepareHandleConnect(showSwitchDialog, false)}
          />,
          document.body,
        )}
    </>
  )
}

const strategies = {
  [IntegrationKeys.G]: {
    name: 'Google Calendar',
    description: 'Connecting to Google Calendar allows the Usermuse bot to join meetings without being manually added.',
    getLogo: () => <GoogleIcon />,
  },
  [IntegrationKeys.M]: {
    name: 'Microsoft Calendar',
    description:
      'Connecting to Microsoft Calendar allows the Usermuse bot to join meetings without being manually added.',
    getLogo: () => <MicrosoftIcon />,
  },
  [IntegrationKeys.Z]: {
    name: 'Zoom',
    description:
      'You must be connected to Zoom in order for the Usermuse bot to be able to join and analyze your meetings.',
    getLogo: () => <ZoomIcon />,
  },
  [IntegrationKeys.S]: {
    name: 'Slack',
    description:
      'You must be connected to Slack in order for the Usermuse bot to be able to join and analyze your channels.',
    getLogo: () => <SlackIcon />,
  },
}

function getName(key) {
  return strategies[key]?.name || ''
}

function getDescription(key) {
  return strategies[key]?.description || ''
}

function getLogo(key) {
  return strategies[key]?.getLogo() || null
}
