import React from 'react'
import { Interviews } from './interviews'
import { Facilitators } from './facilitators'
import { Method } from './method'

export function OverviewSidebar () {
  return (
    <div className='flex flex-col gap-[32px]'>
      <Interviews />

      <Facilitators />

      <Method />
    </div>
  )
}
