import React from 'react'
import clsx from 'clsx'

export const Title = ({ children, className = '', size = 'text-lg' }) => {
  return (
    <p className={clsx(size, "text-[#3f424a] font-bold font-['Manrope'] leading-tight tracking-wide", className)}>
      {children}
    </p>
  )
}
