export function UserAvatarPlaceholder({ width = 36, height = 37 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18" cy="18.7988" r="17.75" fill="#F9F9F9" stroke="#E4E4E4" strokeWidth="0.5" />
      <g clipPath="url(#clip0_3134_10973)">
        <path
          d="M18 8.79883C18.9889 8.79883 19.9556 9.09207 20.7779 9.64148C21.6001 10.1909 22.241 10.9718 22.6194 11.8854C22.9978 12.799 23.0969 13.8044 22.9039 14.7743C22.711 15.7442 22.2348 16.6351 21.5355 17.3344C20.8363 18.0336 19.9454 18.5098 18.9755 18.7028C18.0055 18.8957 17.0002 18.7967 16.0866 18.4182C15.173 18.0398 14.3921 17.3989 13.8427 16.5767C13.2932 15.7544 13 14.7877 13 13.7988L13.005 13.5818C13.0609 12.2946 13.6116 11.0786 14.5422 10.1875C15.4728 9.29637 16.7115 8.7989 18 8.79883Z"
          fill="#AFBCCF"
        />
        <path
          d="M20 20.7988C21.3261 20.7988 22.5979 21.3256 23.5355 22.2633C24.4732 23.201 25 24.4727 25 25.7988V26.7988C25 27.3293 24.7893 27.838 24.4142 28.213C24.0391 28.5881 23.5304 28.7988 23 28.7988H13C12.4696 28.7988 11.9609 28.5881 11.5858 28.213C11.2107 27.838 11 27.3293 11 26.7988V25.7988C11 24.4727 11.5268 23.201 12.4645 22.2633C13.4021 21.3256 14.6739 20.7988 16 20.7988H20Z"
          fill="#AFBCCF"
        />
      </g>
      <defs>
        <clipPath id="clip0_3134_10973">
          <rect width="24" height="24" fill="white" transform="translate(6 6.79883)" />
        </clipPath>
      </defs>
    </svg>
  )
}
