import React, { useContext } from 'react'
import ArrowDownIcon from '../../../icons/ArrowDown'
import ArrowUpIcon from '../../../icons/ArrowUp'
import PinIcon from '../../../icons/Pin'
import { useCollapse } from 'react-collapsed'
import PropTypes from 'prop-types'
import { ProjectContext } from '../../../../context/ProjectContext'
import { formatDate } from '../../../../utils/formatDate'

export function Feature({ feature, index, needsMax, painPointsMax }) {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({ defaultExpanded: false })
  const { participants } = useContext(ProjectContext)

  return (
    <div className="shadow bg-white rounded border border-[#e4e4e4]">
      <div className='h-[78px] pl-4 pr-8 pt-[20px] pb-[22px] flex gap-[17px]' {...getToggleProps()}>
        <div className="h-9 w-9 px-1.5 bg-[#f8f8f8] rounded-[3px] border border-[#e4e4e4] flex flex-col justify-center">
          <div className="text-center text-[#3f424a] text-lg font-bold font-['Manrope'] leading-tight tracking-wide">{index + 1}</div>
        </div>

        <div className="grow flex flex-col justify-center">
          <p className="text-[#3f424a] text-lg font-bold font-['Manrope'] leading-tight tracking-wide">{feature.feature_title}</p>
        </div>

        <div className='min-w-[143px] flex flex-col justify-center gap-[17px]'>
          <div className="w-full h-[5px] relative bg-[#E4E4E4] rounded-[30px]">
            <div style={{ width: `${Math.round(feature.pain_points.length * 100 / painPointsMax)}%` }} className="h-[5px] left-0 top-0 absolute bg-[#FF4A8E] rounded-[30px]" />
          </div>

          <div className="w-full h-[5px] relative bg-[#E4E4E4] rounded-[30px]">
            <div style={{ width: `${Math.round(feature.needs.length * 100 / needsMax)}%` }} className="h-[5px] left-0 top-0 absolute bg-[#DCAE1D] rounded-[30px]" />
          </div>
        </div>

        <div className="h-9 px-1.5 py-[3px] bg-[#f8f8f8] rounded border border-[#e4e4e4] justify-center items-center gap-1.5 inline-flex">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 3L3 6L9 9L15 6L9 3Z" stroke="#FF4A8E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3 9L9 12L15 9" stroke="#FF4A8E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3 12L9 15L15 12" stroke="#FF4A8E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          <div className="text-[#3f424a] text-base font-bold font-['Manrope'] leading-tight">{feature.needs.length + feature.pain_points.length}</div>
        </div>

        <button className='flex flex-col justify-center'>
          {isExpanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </button>
      </div>

      <div className='mx-[16px]' {...getCollapseProps()}>
        <div className='flex gap-[3px] py-[13px] border-y border-[#e4e4e4]'>
          {feature.feature_bullets?.map((bullet, bulletIndex) => (
            <div key={bulletIndex}>
              <div className='py-[6px] pl-[10px] pr-[16px] flex gap-[6px]'>
                <div className='-mt-[1px]'>
                  <PinIcon />
                </div>
                <p className="text-[#62708d] text-[15.10px] font-bold font-['Manrope'] leading-none tracking-wide">{bullet}</p>
              </div>
              {bulletIndex !== feature.feature_bullets.length - 1 && (
                <div className='flex flex-col justify-center'>
                  <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="2" cy="2" r="2" fill="#E4E4E4"/>
                  </svg>
                </div>
              )}
            </div>
          ))}
        </div>

        <div className='my-[23px] flex gap-[40px]'>
          <div className='flex flex-col gap-[6px]'>
            {feature.needs.map(need => (
              <div key={need.id} className="p-3.5 bg-white rounded shadow border border-[#e4e4e4] flex gap-[17px]">
                <div className="h-[21px] px-2 py-1 bg-[#ffc921] rounded border border-[#dcad1c] flex justify-center items-center">
                  <p className="text-[#44566c] text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">Need</p>
                </div>
                <div className="flex flex-col gap-2.5">
                  <p className="w-[200px] text-[#44566c] text-[13px] font-medium font-['Manrope'] leading-none">{need.content}</p>
                  <p className="text-[#62708d] text-[13px] font-medium font-['Manrope'] leading-none">{need.participant_id ? participants[need.participant_id] : need.who_said_it}・{formatDate(need.created_at)}</p>
                </div>
              </div>
            ))}
          </div>

          <div className='flex flex-col gap-[6px]'>
            {feature.pain_points.map(pain_point => (
              <div key={pain_point.id} className="p-3.5 bg-white rounded shadow border border-[#e4e4e4] flex gap-[17px]">
                <div className="h-[21px] px-2 py-1 bg-[#ff4a8e] rounded border border-[#cf3f75] flex justify-center items-center">
                  <p className="text-white text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">Pain</p>
                </div>
                <div className="flex flex-col gap-2.5">
                  <p className="w-[200px] text-[#44566c] text-[13px] font-medium font-['Manrope'] leading-none">{pain_point.content}</p>
                  <p className="text-[#62708d] text-[13px] font-medium font-['Manrope'] leading-none">{pain_point.participant_id ? participants[pain_point.participant_id] : pain_point.who_said_it}・{formatDate(pain_point.created_at)}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

      </div>
    </div>
  )
}

Feature.propTypes = {
  feature: PropTypes.object,
  index: PropTypes.number,
  needsMax: PropTypes.number,
  painPointsMax: PropTypes.number
}
