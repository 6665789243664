import React from 'react'
import PropTypes from 'prop-types'
import { formatFileDuration, getDayAndMonth, getHour } from '../../utils'
import CustomVideoPlayer from '../../utils/CustomVideoPlayer'

function Quote() {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5473_29836)">
        <path
          d="M14.1693 16.0508H8.5026C8.12688 16.0508 7.76655 15.9059 7.50087 15.6481C7.23519 15.3902 7.08594 15.0405 7.08594 14.6758V10.5508C7.08594 10.1861 7.23519 9.83637 7.50087 9.57851C7.76655 9.32065 8.12688 9.17578 8.5026 9.17578H12.7526C13.1283 9.17578 13.4887 9.32065 13.7543 9.57851C14.02 9.83637 14.1693 10.1861 14.1693 10.5508V18.8008C14.1693 22.4679 12.2809 24.7587 8.5026 25.6758"
          stroke="#FF4A8E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.9193 16.0508H21.2526C20.8769 16.0508 20.5165 15.9059 20.2509 15.6481C19.9852 15.3902 19.8359 15.0405 19.8359 14.6758V10.5508C19.8359 10.1861 19.9852 9.83637 20.2509 9.57851C20.5165 9.32065 20.8769 9.17578 21.2526 9.17578H25.5026C25.8783 9.17578 26.2387 9.32065 26.5043 9.57851C26.77 9.83637 26.9193 10.1861 26.9193 10.5508V18.8008C26.9193 22.4679 25.0309 24.7587 21.2526 25.6758"
          stroke="#FF4A8E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5473_29836">
          <rect width="34" height="33" fill="white" transform="translate(0 0.925781)" />
        </clipPath>
      </defs>
    </svg>
  )
}

function Loader() {
  return (
    <svg className="w-16 h-16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
      <radialGradient id="a1" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)">
        <stop offset="0" stopColor="#FF156D"></stop>
        <stop offset=".3" stopColor="#FF156D" stopOpacity=".9"></stop>
        <stop offset=".6" stopColor="#FF156D" stopOpacity=".6"></stop>
        <stop offset=".8" stopColor="#FF156D" stopOpacity=".3"></stop>
        <stop offset="1" stopColor="#FF156D" stopOpacity="0"></stop>
      </radialGradient>
      <circle
        transform-origin="center"
        fill="none"
        stroke="url(#a1)"
        strokeWidth="15"
        strokeLinecap="round"
        strokeDasharray="200 1000"
        strokeDashoffset="0"
        cx="100"
        cy="100"
        r="70"
      >
        <animateTransform
          type="rotate"
          attributeName="transform"
          calcMode="spline"
          dur="2"
          values="360;0"
          keyTimes="0;1"
          keySplines="0 0 1 1"
          repeatCount="indefinite"
        ></animateTransform>
      </circle>
      <circle
        transform-origin="center"
        fill="none"
        opacity=".2"
        stroke="#FF156D"
        strokeWidth="15"
        strokeLinecap="round"
        cx="100"
        cy="100"
        r="70"
      ></circle>
    </svg>
  )
}

function Chevron() {
  return (
    <svg width="24" height="23" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5473_29819)">
        <path
          d="M12 21.9258C16.9706 21.9258 21 17.8963 21 12.9258C21 7.95522 16.9706 3.92578 12 3.92578C7.02944 3.92578 3 7.95522 3 12.9258C3 17.8963 7.02944 21.9258 12 21.9258Z"
          stroke="#62718D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 7.92578V12.9258L15 15.9258"
          stroke="#62718D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5473_29819">
          <rect width="24" height="24" fill="white" transform="translate(0 0.925781)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export function OriginalTranscriptCard({ note, meeting, participants }) {
  return (
    <>
      <div className="mx-auto w-90 bg-white rounded-xl shadow-lg p-2">
        <div className="mt-2 mb-4 pl-5">
          <h3 className="font-medium text-medSlate">CITATIONS</h3>
        </div>
        <hr className="mx-5 mb-3" style={{ border: '1px solid #ececec' }} />
        <div className="flex flex-wrap mx-5 items-center">
          <div className="uppercase tracking-wide text-sm text-gray-500 font-medium me-6 text-slate">
            {meeting.name.split('.').slice(0, -1).join('.')} Interview
          </div>
          <div className="text-gray-500 text-sm flex items-center space-x-2 text-slate">
            <span>•</span>
            {!meeting.start ? (
              <>
                <span>{getDayAndMonth(new Date(meeting.created_at))}</span>
                <span>•</span>
                <span>{getHour(new Date(meeting.created_at))}</span>
              </>
            ) : (
              <>
                <span>{getDayAndMonth(new Date(meeting.start))}</span>
                <span>•</span>
                <span>{getHour(new Date(meeting.start))}</span>
              </>
            )}
            <span>•</span>
            <span className="flex items-center">
              <Chevron />
              <span className="ml-1">&nbsp;0:{formatFileDuration(Math.round(note.end_time - note.start_time))}s</span>
            </span>
          </div>
        </div>

        <div className="md:flex mt-1">
          <div className="md:flex-shrink-0 p-5">
            {meeting.url ? (
              <div
                className="relative w-[280px] h-[160px] bg-cover bg-center"
                style={{ backgroundImage: `url(${meeting.thumbnailUrl})` }}
              >
                <CustomVideoPlayer
                  key={note.id}
                  videoSrc={meeting.url}
                  startTime={note.start_time}
                  endTime={note.end_time <= note.start_time ? note.start_time + 1 : note.end_time}
                />
              </div>
            ) : (
              <div className="w-full flex items-center justify-center">
                <Loader />
              </div>
            )}
          </div>
          <div className="pr-8 pl-4 pt-2 overflow-y-scroll" style={{ maxHeight: '220px' }}>
            <p className="mt-4 text-gray-600 text-[16px] text-[#40424A] pr-10">“{note.original_transcript_segment}”</p>
            <hr className="mt-5 mb-1" style={{ border: '1px solid #ececec' }} />
            <div className="flex justify-between items-center">
              <p className="text-sm text-left text-[#40424A]">{getSpeaker(note, participants)}</p>
              <Quote />
            </div>
            <hr className="mt-1 mb-4" style={{ border: '1px solid #ececec' }} />
          </div>
        </div>
      </div>
    </>
  )
}

function getSpeaker(item, participants = {}) {
  const participant = participants?.[item?.participant_id]
  const name = typeof participant === 'string' ? participant : participant?.name
  return item.participant_id && participant ? name : item.who_said_it
}

OriginalTranscriptCard.propTypes = {
  note: PropTypes.object,
  meeting: PropTypes.object,
}
