export function getDayAndMonth(date) {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  // Get the day of the month (1-31)
  const day = date.getDate()

  // Get the month name
  const month = monthNames[date.getMonth()]

  // Determine the appropriate suffix
  let suffix
  if (day % 10 === 1 && day !== 11) {
    suffix = 'st'
  } else if (day % 10 === 2 && day !== 12) {
    suffix = 'nd'
  } else if (day % 10 === 3 && day !== 13) {
    suffix = 'rd'
  } else {
    suffix = 'th'
  }

  // Return the formatted date string
  return `${month} ${day}${suffix}`
}

export function getHour(date) {
  let hours = date.getHours()
  const minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'PM' : 'AM'

  hours = hours % 12
  hours = hours || 12 // the hour '0' should be '12'
  const minutesStr = minutes < 10 ? '0' + minutes : minutes

  return `${hours}.${minutesStr}${ampm}`
}

export function formatDate(timestamp) {
  const date = new Date(timestamp)
  const optionsDate = { month: '2-digit', day: '2-digit', year: 'numeric' }
  const optionsTime = { hour: 'numeric', minute: '2-digit', hour12: true }

  // Format the date and time separately and then combine them
  const formattedDate = date.toLocaleDateString('en-US', optionsDate)
  const formattedTime = date.toLocaleTimeString('en-US', optionsTime).toLowerCase()
  return `${formattedDate} @ ${formattedTime}`
}

export function formatFileDuration(secondsTotal) {
  const minutes = Math.floor(secondsTotal / 60)
  const seconds = Math.floor(secondsTotal % 60)

  // Pad the minutes and seconds with leading zeros if necessary
  const formattedMinutes = String(minutes).padStart(2, '0')
  const formattedSeconds = String(seconds).padStart(2, '0')

  return `${formattedMinutes}:${formattedSeconds}`
}

export function formatDateHMMSS(seconds) {
  // Ensure seconds is a float
  seconds = parseFloat(seconds)

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const secs = Math.floor(seconds % 60)

  // Format hours, minutes, and seconds
  const formattedHours = hours
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes
  const formattedSeconds = secs < 10 ? '0' + secs : secs

  // Return the formatted time
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
}

export function timeAgo(date) {
  const now = new Date()
  const past = new Date(date)
  const diff = now - past // difference in milliseconds

  const seconds = Math.floor(diff / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const weeks = Math.floor(days / 7)
  const months = Math.floor(days / 30) // approximate
  const years = Math.floor(days / 365) // approximate

  if (years > 0) {
    return years + ' year' + (years > 1 ? 's' : '') + ' ago'
  } else if (months > 0) {
    return months + ' month' + (months > 1 ? 's' : '') + ' ago'
  } else if (weeks > 0) {
    return weeks + ' week' + (weeks > 1 ? 's' : '') + ' ago'
  } else if (days > 0) {
    return days + ' day' + (days > 1 ? 's' : '') + ' ago'
  } else if (hours > 0) {
    return hours + ' hour' + (hours > 1 ? 's' : '') + ' ago'
  } else if (minutes > 0) {
    return minutes + ' minute' + (minutes > 1 ? 's' : '') + ' ago'
  } else {
    return seconds + ' second' + (seconds > 1 ? 's' : '') + ' ago'
  }
}

export const formatMeetingTime = (meeting) => {
  const startDate = meeting.start ? new Date(meeting.start) : new Date(meeting.created_at)
  const endDate = meeting.end ? new Date(meeting.end) : null

  return {
    dayAndMonth: getDayAndMonth(startDate), // E.g., "Apr 13"
    startTime: getHour(startDate), // E.g., "6:00 PM"
    endTime: endDate ? getHour(endDate) : null, // E.g., "8:00 PM" or null if no end time
  }
}
