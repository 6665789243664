import React, { useContext, useEffect, useRef, useState } from 'react'
import { RenameDelete } from '../../../utils/renameDelete'
import PropTypes from 'prop-types'
import { ProjectContext } from '../../../../context/ProjectContext'
import { Notes } from '../../needs/notes'

export function EachCategory({ category }) {
  const [openMenuCat, setOpenMenuCat] = useState('')
  const [prevScrollPos, setPrevScrollPos] = useState(0)
  const categoryRef = useRef(null)
  const categoryInputRef = useRef(null)
  const notesRef = useRef(null)
  const {
    handleDeleteCategory,
    handleRenameCategory,
    scrollToCategory,
    scrollTopContainer,
    notes,
    setSelectedCategory,
    scrollToEnd,
    setScrollToEnd,
  } = useContext(ProjectContext)
  const categoryNotes =
    category.content === 'Uncategorized'
      ? notes.filter((note) => !note.category_id)
      : notes.filter((note) => note.category_id === category.id)

  useEffect(() => {
    if (!categoryRef.current || !scrollTopContainer) return

    const checkIntersection = () => {
      if (!categoryRef.current || !scrollTopContainer) return

      const categoryRect = categoryRef.current.getBoundingClientRect()
      const containerRect = scrollTopContainer.getBoundingClientRect()

      const currentScrollPos = scrollTopContainer.scrollLeft
      let isIntersecting = false
      if (currentScrollPos > prevScrollPos) {
        // Scrolling right, check if intercepts the left of the container
        isIntersecting = categoryRect.left >= containerRect.left && categoryRect.left <= containerRect.left + 100
      } else {
        // Scrolling up, check if intercepts the right of the container
        isIntersecting = categoryRect.right <= containerRect.right && categoryRect.right >= containerRect.right - 100
      }

      if (isIntersecting) {
        setSelectedCategory(category.id)
      }
      setPrevScrollPos(currentScrollPos)
    }

    // Check intersection on scroll
    scrollTopContainer.addEventListener('scroll', checkIntersection)

    return () => {
      scrollTopContainer.removeEventListener('scroll', checkIntersection)
    }
  }, [scrollTopContainer, prevScrollPos])

  const scrollToRef = () => {
    let element = categoryInputRef.current
    let leftOffset = 0
    while (element) {
      leftOffset += element.offsetLeft
      element = element.offsetParent
    }

    // Scroll to the element
    scrollTopContainer.scrollTo({
      top: 0,
      left: leftOffset - window.innerWidth * 0.32,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    // scroll to end of uncategorized when new note is created
    if (notesRef.current && scrollToEnd && category.content === 'Uncategorized') {
      scrollTopContainer.scrollTo({
        top: notesRef.current.scrollHeight,
        left: 0,
        behavior: 'smooth',
      })
    }
    setScrollToEnd(null)
  }, [scrollToEnd])

  useEffect(() => {
    if (categoryInputRef.current && scrollToCategory === category.id) {
      scrollToRef()
    }
  }, [scrollToCategory])
  return (
    <div className="min-w-[510px] max-w-[510px] pr-5 border-r border-[#e5e5e5] mr-5" ref={categoryRef}>
      <div className="sticky top-0 bg-grey-100 py-2.5 border-b border-[#e5e5e5] flex items-center justify-between z-10">
        <input
          ref={categoryInputRef}
          type="text"
          className="w-full bg-grey-100 text-zinc text-xl font-bold font-['Manrope']"
          value={category.content}
          disabled={category.content === 'Uncategorized'}
          onChange={(e) => handleRenameCategory(e.target.value, category)}
          onKeyDown={(e) => e.key === 'Enter' && categoryInputRef.current.blur()}
        />
        <div className="ml-2 flex items-center justify-center">
          <div className="px-2 bg-zinc rounded-[30px] mr-2">
            <div className="text-center text-white text-xs font-extrabold font-['Manrope']">{categoryNotes.length}</div>
          </div>
          {category.content !== 'Uncategorized' && (
            <div className="flex">
              <button onClick={() => setOpenMenuCat(category.id)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_1287_5775)">
                    <path
                      d="M3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C10.8181 3 9.64778 3.23279 8.55585 3.68508C7.46392 4.13738 6.47177 4.80031 5.63604 5.63604C4.80031 6.47177 4.13738 7.46392 3.68508 8.55585C3.23279 9.64778 3 10.8181 3 12Z"
                      stroke="#62718D"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8 12V12.01"
                      stroke="#62718D"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 12V12.01"
                      stroke="#62718D"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16 12V12.01"
                      stroke="#62718D"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1287_5775">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
              {openMenuCat === category.id && (
                <RenameDelete
                  onClose={() => setOpenMenuCat('')}
                  handleRename={() => {
                    categoryInputRef.current.focus()
                    setOpenMenuCat('')
                  }}
                  handleDelete={() => handleDeleteCategory(category)}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div ref={notesRef}>
        <Notes notes={categoryNotes} key={category.id} />
      </div>
      {/* <div className="h-full" style={{
        width: '100%',
        padding: '20px 0',
        display: 'grid',
        placeItems: 'center',
        gridTemplateColumns: 'repeat(auto-fill, minmax(225px,1fr))',
        gridAutoRows: '150px'
      }}>
          {categoryNotes.map(note => (
              <div key={note.id} className="w-[225px] h-[134px] p-5 bg-yellow rounded shadow">
                <p className={"text-black font-semibold font-['Manrope'] " + (note.content.length > 125 ? 'text-xs' : 'text-sm') }>{note.content}</p>
              </div>
          ))}
      </div> */}
    </div>
  )
}

EachCategory.propTypes = {
  category: PropTypes.object.isRequired,
}
