import React, { useContext, useState, forwardRef } from 'react'
import { ProjectContext } from '../../context/ProjectContext'
import PropTypes from 'prop-types'
import cnt from '../../constants'
import MultiTag from '../tags/MultiTag'

export const SelectBar = forwardRef(({ note, noteOrFeedback }, ref) => {
  const {
    prepareDeleteNotes,
    prepareDeleteFeedbacks,
    categories,
    setNotes,
    handleChangeColorNotes,
    handleDuplicateNotes,
    handleChangeNoteCategory,
  } = useContext(ProjectContext)
  const [showColorSelector, setShowColorSelector] = useState(false)
  const [showCategorySelector, setShowCategorySelector] = useState(false)
  const [showTagSelector, setShowTagSelector] = useState(false)
  const horizontalAlign = noteOrFeedback === 'note' ? 150 : 40

  const closeAll = () => {
    setShowColorSelector(false)
    setShowCategorySelector(false)
    setShowTagSelector(false)
  }

  return (
    <>
      <div
        style={{ left: `calc(50% - ${horizontalAlign}px)`, zIndex: '9999' }}
        className="absolute bottom-[150px]"
        ref={ref}
      >
        <div className="w-auto h-11 px-[25px] py-2.5 bg-white rounded-[30px] shadow border-t border-grey-200 flex justify-center center gap-6">
          {noteOrFeedback === 'note' ? (
            <button
              onClick={(e) => {
                e.stopPropagation()
                closeAll()
                setShowColorSelector(!showColorSelector)
              }}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="10.5" fill={note.color} stroke="#62718D" strokeWidth="3" />
              </svg>
            </button>
          ) : null}
          {showColorSelector && (
            <div className="absolute" style={{ top: '100%', left: '50%', transform: 'translate(-50%, 0)' }}>
              <div className="w-[327.35px] h-11 px-[5px] py-2.5 bg-white rounded-[30px] shadow border-t border-[#E4E4E4] flex justify-center items-center gap-[5.67px]">
                {Object.values(cnt.colors).map((c) => (
                  <button
                    key={c}
                    onClick={(e) => {
                      e.stopPropagation()
                      handleChangeColorNotes(c)
                    }}
                    className="relative w-[24px] h-[24px]"
                  >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="12" cy="12" r="11.6453" fill={c} stroke="#A4ABB6" strokeWidth="0.709302" />
                    </svg>
                    {c === note.color && (
                      <div
                        style={{ left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
                        className="w-[32px] h-[32px] absolute rounded-full border-2 border-[#40424A]"
                      />
                    )}
                  </button>
                ))}
              </div>
            </div>
          )}

          {noteOrFeedback === 'note' ? (
            <svg width="1" height="24" viewBox="0 0 1 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="0.5" y1="2.18558e-08" x2="0.499999" y2="24" stroke="#E4E4E4" />
            </svg>
          ) : null}

          {noteOrFeedback === 'note' ? (
            <button onClick={handleDuplicateNotes}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1960_5182)">
                  <path
                    d="M7 9.667C7 8.95967 7.28099 8.28131 7.78115 7.78115C8.28131 7.28099 8.95967 7 9.667 7H18.333C18.6832 7 19.03 7.06898 19.3536 7.20301C19.6772 7.33704 19.9712 7.53349 20.2189 7.78115C20.4665 8.0288 20.663 8.32281 20.797 8.64638C20.931 8.96996 21 9.31676 21 9.667V18.333C21 18.6832 20.931 19.03 20.797 19.3536C20.663 19.6772 20.4665 19.9712 20.2189 20.2189C19.9712 20.4665 19.6772 20.663 19.3536 20.797C19.03 20.931 18.6832 21 18.333 21H9.667C9.31676 21 8.96996 20.931 8.64638 20.797C8.32281 20.663 8.0288 20.4665 7.78115 20.2189C7.53349 19.9712 7.33704 19.6772 7.20301 19.3536C7.06898 19.03 7 18.6832 7 18.333V9.667Z"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4.012 16.737C3.70534 16.5622 3.45027 16.3095 3.27258 16.0045C3.09488 15.6995 3.00085 15.353 3 15V5C3 3.9 3.9 3 5 3H15C15.75 3 16.158 3.385 16.5 4"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1960_5182">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          ) : null}

          {noteOrFeedback === 'note' ? (
            <svg width="1" height="24" viewBox="0 0 1 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="0.5" y1="2.18558e-08" x2="0.499999" y2="24" stroke="#E4E4E4" />
            </svg>
          ) : null}

          <button
            onClick={(e) => {
              e.stopPropagation()
              if (noteOrFeedback === 'note') prepareDeleteNotes()
              if (noteOrFeedback === 'feedback') prepareDeleteFeedbacks()
            }}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_2130_7676)">
                <path d="M4 7H20" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M10 11V17" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M14 11V17" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path
                  d="M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_2130_7676">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>

          {noteOrFeedback === 'note' ? (
            <svg width="1" height="24" viewBox="0 0 1 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="0.5" y1="2.18558e-08" x2="0.499999" y2="24" stroke="#E4E4E4" />
            </svg>
          ) : null}

          {noteOrFeedback === 'note' ? (
            <button
              onClick={(e) => {
                e.stopPropagation()
                closeAll()
                setShowCategorySelector(!showCategorySelector)
              }}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1960_5203)">
                  <path
                    d="M18 9L21 12L18 15"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M15 12H21" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path
                    d="M6 9L3 12L6 15"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M3 12H9" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path
                    d="M9 18L12 21L15 18"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M12 15V21" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path
                    d="M15 6L12 3L9 6"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M12 3V9" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_1960_5203">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          ) : null}
          {showCategorySelector ? (
            <select
              className="w-[100px] rounded text-lg font-bold font-['Manrope'] leading-tight tracking-wide cursor-pointer"
              onChange={(e) => handleChangeNoteCategory(e.target.value)}
              value={note.category_id}
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.content}
                </option>
              ))}
            </select>
          ) : null}
        </div>
      </div>

      {showTagSelector ? (
        <div style={{ left: 'calc(50% - 150px)' }} className="absolute bottom-[20px] z-30">
          <div className="w-[365px] h-32 py-2.5 bg-white rounded-[30px] shadow border-t border-grey-200 flex center overflow-y-auto">
            <div onClick={(e) => e.stopPropagation()}>
              <div className="h-full flex">
                <div className="pl-[34px] pb-5 rounded-2xl">
                  <p className="text-[#FF4A8E] text-2xl font-normal font-['DM_Sans'] leading-[41.40px] mb-1">
                    Add Tags
                  </p>
                  <MultiTag
                    entity={note}
                    entityName={'note'}
                    setEntities={setNotes}
                    project={project}
                    setProject={setProject}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
})

SelectBar.propTypes = {
  note: PropTypes.object.isRequired,
  noteOrFeedback: PropTypes.string.isRequired,
}
