import React from 'react'

export function MicrosoftIcon() {
  return (
    <svg width="79" height="79" viewBox="0 0 79 79" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M46.0254 21.3496H69.5685C70.8807 21.3496 71.945 22.4139 71.945 23.7261V54.6126C71.945 55.9248 70.8807 56.9891 69.5685 56.9891H46.0254V21.3496Z"
        fill="#1976D2"
      />
      <path d="M68.7051 29.3809H46.0254V54.5585H68.7051V29.3809Z" fill="white" />
      <path d="M44.4054 71.5685L7.146 64.2787V14.0594L44.4054 6.76953V71.5685Z" fill="#1976D2" />
      <path
        d="M25.3705 27.0195C20.2255 27.0195 16.0557 32.4594 16.0557 39.1693C16.0557 45.8793 20.2255 51.3192 25.3705 51.3192C30.5156 51.3192 34.6854 45.8793 34.6854 39.1693C34.6854 32.4594 30.5156 27.0195 25.3705 27.0195ZM24.9655 46.4592C22.2812 46.4592 20.1056 43.195 20.1056 39.1693C20.1056 35.1437 22.2812 31.8795 24.9655 31.8795C27.6498 31.8795 29.8255 35.1437 29.8255 39.1693C29.8255 43.195 27.6498 46.4592 24.9655 46.4592Z"
        fill="white"
      />
      <path d="M50.4755 48.4629H46.1016V53.1608H50.4755V48.4629Z" fill="#1976D2" />
      <path d="M55.9848 48.4629H51.6108V53.1608H55.9848V48.4629Z" fill="#1976D2" />
      <path d="M61.4945 48.4629H57.1206V53.1608H61.4945V48.4629Z" fill="#1976D2" />
      <path d="M50.4755 42.666H46.1016V47.3639H50.4755V42.666Z" fill="#1976D2" />
      <path d="M55.9848 42.666H51.6108V47.3639H55.9848V42.666Z" fill="#1976D2" />
      <path d="M61.4945 42.666H57.1206V47.3639H61.4945V42.666Z" fill="#1976D2" />
      <path d="M67.0038 42.666H62.6299V47.3639H67.0038V42.666Z" fill="#1976D2" />
      <path d="M50.4755 37.0723H46.1016V41.7702H50.4755V37.0723Z" fill="#1976D2" />
      <path d="M55.9848 37.0723H51.6108V41.7702H55.9848V37.0723Z" fill="#1976D2" />
      <path d="M61.4945 37.0723H57.1206V41.7702H61.4945V37.0723Z" fill="#1976D2" />
      <path d="M67.0038 37.0723H62.6299V41.7702H67.0038V37.0723Z" fill="#1976D2" />
      <path d="M55.9848 31.25H51.6108V35.9479H55.9848V31.25Z" fill="#1976D2" />
      <path d="M61.4945 31.25H57.1206V35.9479H61.4945V31.25Z" fill="#1976D2" />
      <path d="M67.0038 31.25H62.6299V35.9479H67.0038V31.25Z" fill="#1976D2" />
    </svg>
  )
}