import React, { useContext } from 'react'
import { MeetingsContext } from '../../../context/MeetingContext'
import { ParticipantNameMenu } from './participantNameMenu'
import PropTypes from 'prop-types'
import Tippy from '@tippyjs/react'
import { AssignParticipant } from './assignParticipant'

export function Transcript ({ transcript }) {
  const { participants, handleChooseParticipant } = useContext(MeetingsContext)

  return (
      <div key={transcript.id} className='mt-[12px] pb-[14px] border-b border-b-[#e4e4e4]'>
        {!transcript.participant_id ? (
          <div className='relative mb-[7px] flex items-center justify-between mr-[40px]'>
            <AssignParticipant
              onClose={() => setOpenAssignParticipantMenu(false)}
              handleChooseExistingParticipant={(participantId) => handleChooseParticipant(transcript.id, participantId, '')}
              handleChooseNewParticipant={(participantName) => handleChooseParticipant(transcript.id, '', participantName)}
            />
            <p className="text-[#62708d] text-[13px] font-normal font-['Manrope'] leading-none">{transcript.time}</p>
          </div>
        ) : (
          <div className={`relative mb-[7px] flex items-center justify-between ${participants[transcript.participant_id].role === 'facilitator' ? 'ml-[40px]' : 'mr-[40px]'}`}>
            <ParticipantNameMenu participant={participants[transcript.participant_id]} participantId={transcript.participant_id} />
            <p className="text-[#62708d] text-[13px] font-normal font-['Manrope'] leading-none">{transcript.time}</p>
          </div>
        )}

        <div className={`p-[10px] rounded-[5px] ${transcript.participant_id && participants[transcript.participant_id].role === 'facilitator' ? 'ml-[40px] bg-black text-white' : 'mr-[40px] bg-white text-[#3f424a]'}`}>
          {transcript.sentences.map(sentence =>
          <div key={sentence.text}>
            {sentence.highlight
              ? <Tippy
                interactive={true}
                placement='top'
                maxWidth={350}
                content={sentence.highlight.tooltip}
              >
                <p className="text-[13px] font-medium font-['Manrope']" style={{ backgroundColor: sentence.highlight.color, display: 'inline', lineHeight: 1.2 }}>{sentence.text}</p>
              </Tippy>
              : <p className="text-[13px] font-medium font-['Manrope'] leading-none">{sentence.text}</p>}
          </div>
          )}
        </div>
      </div>
  )
}

Transcript.propTypes = {
  transcript: PropTypes.object
}
