import React, { useContext, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import defaultAvatar from '../../../assets/default_avatar.png'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Input } from "@/components/ui/input"
import { MeetingsContext } from '../../../context/MeetingContext'

export const ParticipantNameMenu = ({ participant, participantId }) => {
  const [rename, setRename] = useState(false)
  const { handleRenameParticipant } = useContext(MeetingsContext)
  const participantRef = useRef(null)

  return (
    <>
      <Input
        ref={participantRef}
        type='text'
        className={`focus-visible:ring-0 w-full mr-2 bg-grey-100 text-zinc text-xl font-bold font-['Manrope'] ${!rename && 'hidden'}`}
        value={participant.name}
        onChange={e => handleRenameParticipant(e.target.value, participantId)}
        onKeyDown={e => e.key === 'Enter' && setRename(false)}
        onBlur={() => setRename(false)}
        autoFocus
      />
      <DropdownMenu>
        <DropdownMenuTrigger>
          <button className={`bg-white py-[4px] px-[6px] rounded-[5px] border border-[#e4e4e4] flex items-center gap-[6px] ${rename && 'hidden'}`}>
            <img className='h-[16px] w-[16px] rounded-full' src={defaultAvatar} alt="user avatar" />
            <p className="text-[#3f424a] text-[13px] font-bold font-['Manrope'] leading-none">{participant.name}</p>
            {participant.role === 'facilitator' && (
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 7.44979C8.02655 7.55694 8.5736 7.4916 9.06009 7.26343C9.54658 7.03527 9.94661 6.65643 10.2009 6.18307C10.4552 5.70971 10.5502 5.16702 10.4718 4.63543C10.3935 4.10384 10.1459 3.61164 9.76585 3.23178C9.3858 2.85193 8.89348 2.60463 8.36185 2.52654C7.83021 2.44846 7.28757 2.54373 6.81434 2.79826C6.34111 3.05278 5.96248 3.45301 5.73457 3.93961C5.50665 4.42622 5.44158 4.9733 5.549 5.49979" stroke="#62718D" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7.4998 7.4495L5.5488 5.5L1.7923 9.792C1.60466 9.97944 1.49916 10.2337 1.49902 10.499C1.49895 10.6303 1.52475 10.7603 1.57494 10.8817C1.62513 11.0031 1.69874 11.1133 1.79155 11.2062C1.88436 11.2992 1.99456 11.3729 2.11587 11.4232C2.23717 11.4735 2.36719 11.4995 2.49852 11.4995C2.76374 11.4997 3.01816 11.3944 3.2058 11.207L7.4998 7.4495Z" stroke="#62718D" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            )}
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="6" cy="6" r="6" transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 14 2)" fill="#EBEBEB"/>
              <path d="M6 7.33398L8 9.33398L10 7.33398" stroke="#40424A" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className='border-white p-0' onCloseAutoFocus={(e) => {
          e.preventDefault()
          participantRef.current.focus()
        }}>
          <DropdownMenuItem className='flex justify-center items-center'>
            <button className='flex justify-center items-center grow' onClick={() => setRename(true)}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1444_3385)">
                <path d="M7 7H6C5.46957 7 4.96086 7.21071 4.58579 7.58579C4.21071 7.96086 4 8.46957 4 9V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20H15C15.5304 20 16.0391 19.7893 16.4142 19.4142C16.7893 19.0391 17 18.5304 17 18V17" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M20.385 6.58511C20.7788 6.19126 21.0001 5.65709 21.0001 5.10011C21.0001 4.54312 20.7788 4.00895 20.385 3.61511C19.9912 3.22126 19.457 3 18.9 3C18.343 3 17.8088 3.22126 17.415 3.61511L9 12.0001V15.0001H12L20.385 6.58511Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M16 5L19 8" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_1444_3385">
                <rect width="24" height="24" fill="white"/>
                </clipPath>
                </defs>
              </svg>
              <p className="ml-2 text-zinc font-semibold font-['Manrope']">Rename</p>
            </button>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  )
}

ParticipantNameMenu.propTypes = {
  participant: PropTypes.object,
  participantId: PropTypes.string,
}
