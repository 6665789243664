import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { formatFileDuration, getDayAndMonth, getHour, getMeetingProvider } from '../../../../../utils'
import thumbnailDefault from '../../../../../assets/audio-thumbnail.png'
import { MeetingProviderSmall } from '../../../../utils/meetingProvider'
import { cn } from '@/lib/utils'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { Hourglass } from 'lucide-react'

export function Interview({ meet }) {
  const provider = useMemo(() => getMeetingProvider(meet.meeting_url), [meet.meeting_url])

  return (
    <div className={`flex ${meet.processing ? 'cursor-progress' : ''}`}>
      <div className="w-[70px] h-[45px] bg-black/30 rounded relative">
        <img
          className={`min-w-[70px] max-w-[70px] min-h-[45px] max-h-[45px] rounded ${meet.thumbnailUrl ? 'opacity-70' : ''}`}
          src={meet.thumbnailUrl || thumbnailDefault}
          alt={meet.name}
        />
        {meet.processing && (
          <div className="absolute inset-0 bg-black/50 rounded flex items-center justify-center z-10">
            <div className="bg-white rounded-full p-1">
              <Hourglass className="w-3 h-3 text-[#3F424A]" />
            </div>
          </div>
        )}
        <div
          className="absolute"
          style={{ bottom: provider === '' ? '-5.5px' : provider === 'Google' ? '-6.5px' : '-4.5px', right: '-3.75px' }}
        >
          <MeetingProviderSmall provider={provider} />
        </div>
        <div className="absolute" style={{ top: '4.5px', left: '17px' }}>
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_3828_6934)">
              <circle cx="17.622" cy="17.8779" r="7.5263" fill="white" />
              <path
                d="M20.9825 17.5546C21.1895 17.7219 21.1895 18.0376 20.9825 18.2049L16.421 21.8919C16.1476 22.1129 15.74 21.9183 15.74 21.5667L15.74 14.1928C15.74 13.8412 16.1476 13.6466 16.421 13.8676L20.9825 17.5546Z"
                fill="black"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_3828_6934"
                x="0.0606372"
                y="0.316497"
                width="35.1229"
                height="35.1229"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feMorphology
                  radius="3.34502"
                  operator="dilate"
                  in="SourceAlpha"
                  result="effect1_dropShadow_3828_6934"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="3.34502" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3828_6934" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3828_6934" result="shape" />
              </filter>
            </defs>
          </svg>
        </div>
      </div>
      <div className="flex flex-col items-start grow ml-3">
        <Tooltip>
          <TooltipTrigger
            className={cn(
              meet.processing ? 'cursor-progress' : 'cursor-pointer',
              'text-ellipsis',
              "text-[#40424A] text-start text-base font-semibold font-['Manrope'] leading-tight",
              'max-h-[44px]' // Enforce the maximum height for two lines
            )}
            style={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {meet.name}
          </TooltipTrigger>
          <TooltipContent style={{ zIndex: 9999 }}>{meet.name}</TooltipContent>
        </Tooltip>
        <div className="flex flex-wrap gap-y-1 mt-1 items-center text-slate text-[13px] font-medium font-['Manrope'] leading-none">
          {!meet.start ? (
            <>
              <p>{getDayAndMonth(new Date(meet.created_at))}・</p>
              <p>{getHour(new Date(meet.created_at))}</p>
              <p>{meet.end ? <span>&nbsp;- {getHour(new Date(meet.end))}</span> : ''}</p>
            </>
          ) : (
            <>
              <p>{getDayAndMonth(new Date(meet.start))}・</p>
              <p>{getHour(new Date(meet.start))}</p>
              <p>{meet.end ? <span>&nbsp;- {getHour(new Date(meet.end))}</span> : ''}</p>
            </>
          )}
          {!!meet.duration && <p>・&nbsp;{formatFileDuration(meet.duration)}</p>}
        </div>
      </div>
    </div>
  )
}

Interview.propTypes = {
  meet: PropTypes.object,
  thumbnail: PropTypes.string,
}
