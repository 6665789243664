import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '@/context/AuthContext'
import { useCustomFetch } from '@/hooks/useCustomFetch'
import { Interview } from './interview'
import { Event } from './event'
import { useNavigate } from 'react-router-dom'
import { ProjectContext } from '@/context/ProjectContext'
import AddPinkIcon from '../../../../icons/AddPink'
import { ImportFiles } from '../../../../dashboard/dialog/importFiles'
import { createPortal } from 'react-dom'
import Tippy from '@tippyjs/react'
import { cn } from '@/lib/utils'
import { AddMeetingDialog } from './addMeetingDialog'
import cnt from '../../../../../constants'

export function Interviews() {
  const [events, setEvents] = useState([])
  const [showImportFileDialog, setShowImportFileDialog] = useState(false)
  const [showAddMeetingDialog, setShowAddMeetingDialog] = useState(false)
  const { user } = useContext(AuthContext)
  const { project, sessions, workspace, setMeetings } = useContext(ProjectContext)
  const navigate = useNavigate()
  const customFetch = useCustomFetch()

  useEffect(() => {
    if (Object.keys(user || {}).length) return
    const getCalendarEvents = async () => {
      const data = await customFetch('/getCalendarEvents', 'GET', null)
      if (data.error) return
      if (Array.isArray(data.events) && data.events.length) {
        setEvents(data.events.filter((event) => event.projectId === project.id))
      }
    }

    getCalendarEvents()
  }, [user])

  return (
    <div className="w-full pb-[28px] border-b border-[#e4e4e4]">
      <p className="text-[#3f424a] text-[22px] font-bold font-['DM_Sans'] leading-relaxed">Meetings</p>
      <p className="mb-[16px] text-[#62708d] text-base font-medium font-['Manrope'] leading-[23px]">
        Upcoming and recent meetings.
      </p>

      {events.length > 0 && (
        <>
          <p className="text-[#62708d] text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">
            Upcoming
          </p>
          <div className="mt-[8px] mb-[14px] flex flex-col gap-[8px]">
            {events.map((event) => (
              <Event key={event.id} event={event} />
            ))}
          </div>
        </>
      )}

      <p className="text-[#62708d] text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">
        Recent
      </p>
      <div className="my-[8px] flex flex-col gap-[8px]">
        {sessions.map((meet) => (
          <button
            key={meet.id}
            onClick={() => (meet.processing ? null : navigate(`/dashboard/meetings/${meet.id}`))}
            className={cn(
              'w-[360px] h-[79.37px] p-4 bg-white rounded-lg shadow border border-grey-200 justify-start items-center gap-4 inline-flex',
              meet.processing && 'opacity-50 cursor-progress'
            )}
          >
            <Interview meet={meet} />
          </button>
        ))}
      </div>

      <div className="flex justify-between gap-[8px]">
        <Tippy
          disabled={
            workspace.status === 'active' ||
            workspace.additional_amount + workspace.sub_hours_left > cnt.min_time_needed
          }
          maxWidth={300}
          content="This feature is disabled since your account is out of free hours. Please upgrade to continue using our AI features."
        >
          <div>
            <button
              style={{
                boxShadow: '0px 0px 22.399999618530273px rgba(14.96, 21.41, 55.25, 0.05)',
                borderRadius: 8,
                border: '1px #FF4A8E dotted',
              }}
              className="w-[176px] h-[52px] pl-2 pr-4 py-2 bg-white flex justify-center items-center gap-3"
              disabled={
                workspace.status !== 'active' &&
                workspace.additional_amount + workspace.sub_hours_left <= cnt.min_time_needed
              }
              onClick={() => setShowAddMeetingDialog(true)}
            >
              <AddPinkIcon />
              <p className="text-[#3f424a] text-base font-semibold font-['Manrope'] leading-tight">Add Meeting</p>
            </button>
            {showAddMeetingDialog &&
              createPortal(<AddMeetingDialog handleClose={() => setShowAddMeetingDialog(false)} />, document.body)}
          </div>
        </Tippy>

        <Tippy
          disabled={
            workspace.status === 'active' ||
            workspace.additional_amount + workspace.sub_hours_left > cnt.min_time_needed
          }
          maxWidth={300}
          content="This feature is disabled since your account is out of free hours. Please upgrade to continue using our AI features."
        >
          <div>
            <button
              style={{
                boxShadow: '0px 0px 22.399999618530273px rgba(14.96, 21.41, 55.25, 0.05)',
                borderRadius: 8,
                border: '1px #FF4A8E dotted',
              }}
              className={`w-[176px] h-[52px] pl-2 pr-4 py-2 bg-white flex justify-center items-center gap-3 ${workspace.status !== 'active' && workspace.additional_amount + workspace.sub_hours_left <= cnt.min_time_needed && 'opacity-50'}`}
              disabled={
                workspace.status !== 'active' &&
                workspace.additional_amount + workspace.sub_hours_left <= cnt.min_time_needed
              }
              onClick={() => setShowImportFileDialog(true)}
            >
              <AddPinkIcon />
              <p className="text-[#3f424a] text-base font-semibold font-['Manrope'] leading-tight">Upload File</p>
            </button>
            {showImportFileDialog &&
              createPortal(
                <ImportFiles
                  projectId={project.id}
                  projectName={project.name}
                  handleClose={() => setShowImportFileDialog(false)}
                  setMeetings={setMeetings}
                />,
                document.body
              )}
          </div>
        </Tippy>
      </div>
    </div>
  )
}
