import React from 'react'
import RayIcon from '../../icons/Ray'
import PropTypes from 'prop-types'

export function Problems({ problems }) {
  return (
    <div className="w-full">
      <div className='py-[8px] border-b-2 border-grey-200'>
        <p className="text-[#3f424a] text-4xl font-bold font-['DM_Sans'] leading-[41.40px]">Pain Points</p>
      </div>

      <div className="mt-[36px] flex flex-wrap gap-[32px]">
        {problems.map((problem) => (
          <div
            key={problem.problem_number}
            className='w-[395px] px-[36px] pt-[24px] pb-[48px] bg-white rounded flex flex-col justify-center items-center gap-[16px]'
            style={{ boxShadow: '0px 0px 22.399999618530273px rgba(14.96, 21.41, 55.25, 0.05)' }}
          >
            <RayIcon />
            <p className="text-center text-black text-base font-extrabold font-['Manrope'] leading-normal tracking-wide">{problem.title}</p>
            <p className="text-center text-black text-sm font-semibold font-['Manrope'] leading-[19px] tracking-wide">{problem.problem}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

Problems.propTypes = {
  problems: PropTypes.array,
}
