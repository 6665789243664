import React, { useContext, useEffect, useRef, useState } from 'react'
import { ProjectContext } from '../../../../../context/ProjectContext'
import { timeAgo } from '../../../../../utils'
import { useCustomFetch } from '../../../../../hooks/useCustomFetch'
import AddPinkIcon from '../../../../icons/AddPink'
import Tippy from '@tippyjs/react'
import { useNavigate } from 'react-router-dom'
import { Facilitator } from './facilitator'

export function Facilitators ({ overviewDesign = true }) {
  const { project, setProject } = useContext(ProjectContext)
  const [facilitators, setFacilitators] = useState([])
  const [showUsers, setShowUsers] = useState(false)
  const [users, setUsers] = useState([])
  const addFacilitatorRef = useRef(null)
  const navigate = useNavigate()
  const customFetch = useCustomFetch()

  useEffect(() => {
    if (!addFacilitatorRef.current) return

    function handleClickOutside (event) {
      if (addFacilitatorRef.current && !addFacilitatorRef.current.contains(event.target)) {
        setShowUsers(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [addFacilitatorRef])

  useEffect(() => {
    const getUsers = async () => {
      const facilitatorsDb = []
      const response = await customFetch(`/getUsersByWorkspace/${project.workspace_id}`, 'GET', null)
      for (const facilitator of project.facilitators) {
        const userIndex = response.users.findIndex(user => user.id === facilitator)
        if (userIndex === -1) return console.log('Facilitator not found in users')
        facilitatorsDb.push(response.users[userIndex])
        response.users.splice(userIndex, 1) // 2nd parameter means remove one item only
      }
      setUsers(response.users)
      setFacilitators(facilitatorsDb)
    }
    getUsers()
  }, [])

  async function addFacilitator (userId) {
    const user = users.find(user => user.id === userId)
    setUsers(prevUsers => prevUsers.filter(user => user.id !== userId))
    setFacilitators(prevFacilitators => [...prevFacilitators, user])
    setProject(prevProject => ({ ...prevProject, facilitators: [...prevProject.facilitators, userId] }))
    await customFetch('/updateProjectFacilitators', 'PUT', { projectId: project.id, facilitators: [...facilitators.map(user => user.id), userId] })
  }

  async function removeFacilitator (userId) {
    const facilitator = facilitators.find(user => user.id === userId)
    setFacilitators(prevFacilitators => prevFacilitators.filter(user => user.id !== userId))
    setUsers(prevUsers => [...prevUsers, facilitator])
    setProject(prevProject => ({ ...prevProject, facilitators: prevProject.facilitators.filter(user => user !== userId) }))
    await customFetch('/updateProjectFacilitators', 'PUT', { projectId: project.id, facilitators: facilitators.filter(user => user.id !== userId).map(user => user.id) })
  }

  return (
    <div className={`w-full ${overviewDesign ? "pb-[28px] border-b border-[#e4e4e4]" : "px-[28px]"}`}>
      <p className="text-[#3f424a] text-[22px] font-bold font-['DM_Sans'] leading-relaxed">Facilitators</p>
      <p className="mb-[16px] text-[#62708d] text-base font-medium font-['Manrope'] leading-[23px]">Add facilitators to project.</p>

      <div className='mt-[8px] w-full flex flex-col gap-[8px]'>
        {Array.isArray(facilitators) && facilitators.map((facilitator) => <Facilitator key={facilitator.id} facilitator={facilitator} removeFacilitator={removeFacilitator} />)}
      </div>

      <div
        ref={addFacilitatorRef}
        style={{ boxShadow: '0px 0px 22.399999618530273px rgba(14.96, 21.41, 55.25, 0.05)', borderRadius: 8, border: '1px #FF4A8E dotted' }}
        className="relative mt-[8px] h-[52px] pl-2 pr-4 py-2 bg-white"
      >
        <Tippy
          disabled={users.length}
          interactive={true}
          maxWidth={350}
          content={
            <div className='p-2 w-[300px] text-left'>
              Please add team members to this workspace to add other facilitators.
              <button className='text-linkBlue font-semibold' onClick={() => navigate('/dashboard/team')}>&nbsp;Team Settings</button>
            </div>
          }
        >
          <div>
            <button onClick={() => setShowUsers(true)} className='w-full flex justify-center items-center gap-3'>
              <AddPinkIcon />
              <p className="text-[#3f424a] text-base font-semibold font-['Manrope'] leading-tight">Add Facilitator</p>
            </button>
          </div>
        </Tippy>
        {showUsers && !!users.length && (
          <div className={`w-full absolute ${facilitators.length > 0 ? 'right-0' : 'left-0'} top-[44px] p-2.5 bg-white shadow rounded flex-col justify-center items-start gap-2 inline-flex z-10`}>
            {users.map((user, index) => user.workspace.joined_at && (
              <button key={user.id} onClick={() => addFacilitator(user.id)} className={`w-full h-[62px] pb-2.5 bg-white justify-start items-center gap-[38px] inline-flex ${index < users.length - 1 && 'border-b border-[#e4e4e4]'}`}>
                <img className="w-[38px] h-[38px] rounded-full border border-grey-200" src={user.picture} />
                <div className="py-2 flex-col justify-start items-start inline-flex">
                  <p className="text-[#3f424a] text-base font-bold font-['Manrope'] leading-tight">{user.name}</p>
                  <p className="text-[#62708d] text-[13px] font-medium font-['Manrope'] leading-none">Joined {timeAgo(user.workspace.joined_at)}</p>
                </div>
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
