import React from 'react'

const QuotationMarkIcon = () => {
  return (
    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6456_11793)">
      <path d="M9.17626 10.6084H5.51142C5.26843 10.6084 5.03539 10.5147 4.86357 10.348C4.69174 10.1812 4.59521 9.955 4.59521 9.71915V7.05137C4.59521 6.81552 4.69174 6.58934 4.86357 6.42257C5.03539 6.2558 5.26843 6.16211 5.51142 6.16211H8.26005C8.50304 6.16211 8.73608 6.2558 8.9079 6.42257C9.07973 6.58934 9.17626 6.81552 9.17626 7.05137V12.3869C9.17626 14.7586 7.95495 16.2401 5.51142 16.8332" stroke="#62718D" strokeWidth="0.970103" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17.4204 10.6084H13.7556C13.5126 10.6084 13.2795 10.5147 13.1077 10.348C12.9359 10.1812 12.8394 9.955 12.8394 9.71915V7.05137C12.8394 6.81552 12.9359 6.58934 13.1077 6.42257C13.2795 6.2558 13.5126 6.16211 13.7556 6.16211H16.5042C16.7472 6.16211 16.9802 6.2558 17.152 6.42257C17.3239 6.58934 17.4204 6.81552 17.4204 7.05137V12.3869C17.4204 14.7586 16.1991 16.2401 13.7556 16.8332" stroke="#62718D" strokeWidth="0.970103" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
      <clipPath id="clip0_6456_11793">
      <rect width="21.989" height="21.3423" fill="white" transform="translate(0.0112305 0.828125)"/>
      </clipPath>
      </defs>
    </svg>
  )
}

export default QuotationMarkIcon
