import React, { useContext, useEffect, useRef, useState } from 'react'
import { DashboardContext } from '../../../context/DashboardContext'
import { MeetingsContext } from '../../../context/MeetingContext'
import { useCustomFetch } from '../../../hooks/useCustomFetch'

export function ChooseProject () {
  const { allProjects } = useContext(DashboardContext)
  const { project, meeting, changeProject } = useContext(MeetingsContext)
  const [showProjects, setShowProjects] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const changeProjectRef = useRef()
  const customFetch = useCustomFetch()

  async function handleAssignProject(newProjectId) {
    setIsLoading(true)
    const res = await customFetch('/assignProjectToMeeting', 'PUT', { projectId: newProjectId, meetingId: meeting.id })
    if (res.error) {
      console.error(res.error)
      toast.error('There was an error assigning the new project')
    } else {
      changeProject(newProjectId)
    }
    setIsLoading(false)
  }

  // Handle outside clicks
  useEffect(() => {
    function handleClickOutside(event) {
      if (changeProjectRef.current && !changeProjectRef.current.contains(event.target)) {
        setShowProjects(false)
      }
    }

    if (showProjects) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [showProjects])

  return (
    <div ref={changeProjectRef} className="relative ml-[9px] flex items-center gap-[9px] cursor-pointer" onClick={() => setShowProjects(!showProjects)}>
        {project.name ?
          <span className="text-black text-lg font-normal font-['Manrope'] leading-tight tracking-wide">{project.name}</span>
          :
          <span className="text-[#62708d] text-lg font-normal font-['Manrope'] leading-tight tracking-wide">Add to Project</span>
        }
        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.70711 6.79289C6.31658 7.18342 5.68342 7.18342 5.29289 6.79289L0.707105 2.2071C0.0771403 1.57714 0.523309 0.5 1.41421 0.5L10.5858 0.499999C11.4767 0.499999 11.9229 1.57714 11.2929 2.20711L6.70711 6.79289Z" fill="#62718D"/>
        </svg>
        {isLoading && (<svg className='w-8 h-8' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><radialGradient id="a1" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)"><stop offset="0" stopColor="#FF156D"></stop><stop offset=".3" stopColor="#FF156D" stopOpacity=".9"></stop><stop offset=".6" stopColor="#FF156D" stopOpacity=".6"></stop><stop offset=".8" stopColor="#FF156D" stopOpacity=".3"></stop><stop offset="1" stopColor="#FF156D" stopOpacity="0"></stop></radialGradient><circle transform-origin="center" fill="none" stroke="url(#a1)" strokeWidth="15" strokeLinecap="round" strokeDasharray="200 1000" strokeDashoffset="0" cx="100" cy="100" r="70"><animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="2" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform></circle><circle transform-origin="center" fill="none" opacity=".2" stroke="#FF156D" strokeWidth="15" strokeLinecap="round" cx="100" cy="100" r="70"></circle></svg>)}
        {showProjects && (
          <div className="absolute w-auto right-0 top-8 bg-white shadow rounded-b border-x border-grey-200 z-10 max-h-[260px] overflow-auto hideScrollbar">
            {allProjects.map((project) => (
              <div
                key={project.id}
                className='w-[180px] px-3 py-1 hover:bg-grey-100 cursor-pointer'
                onClick={() => handleAssignProject(project.id)}
              >
                {project.name}
              </div>
            ))}
          </div>
        )}
    </div>
  )
}
