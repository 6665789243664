import React, { useContext, useRef } from 'react'
import { Note } from './note'
import { ProjectContext } from '../../../context/ProjectContext'
import PropTypes from 'prop-types'

export const Notes = ({ notes }) => {
  const {
    selectedNotes,
    setSelectedNotes,
    setSelectedFeedbacks,
    firstNoteSelected,
    setFirstNoteSelected,
    noteHover,
    setNoteHover,
    showDeleteNotesDialog,
  } = useContext(ProjectContext)
  const notesRef = useRef(null)

  const closeAll = () => {
    if (!showDeleteNotesDialog) {
      setSelectedNotes([])
      setFirstNoteSelected(null)
      setNoteHover(null)
    }
  }

  const handleNoteSelect = (noteId, event) => {
    event.stopPropagation()
    setSelectedFeedbacks([])
    if (event.ctrlKey || event.metaKey) {
      // Ctrl (or Cmd) key for multiple select
      setSelectedNotes((prevSelectedNotes) =>
        prevSelectedNotes.includes(noteId)
          ? prevSelectedNotes.filter((id) => id !== noteId)
          : [...prevSelectedNotes, noteId]
      )
      if (selectedNotes.length === 0) {
        setFirstNoteSelected(noteId)
        setNoteHover(noteId)
      }
    } else if (event.shiftKey) {
      // Shift key for range select
      if (selectedNotes.length === 0) {
        setSelectedNotes([noteId])
        setFirstNoteSelected(noteId)
        setNoteHover(noteId)
        return
      }
      const currentNoteIndex = notes.findIndex((note) => note.id === noteId)
      const firstNoteIndex = notes.findIndex((note) => note.id === firstNoteSelected)

      if (currentNoteIndex < firstNoteIndex) {
        setSelectedNotes(notes.slice(currentNoteIndex, firstNoteIndex + 1).map((note) => note.id))
      } else if (currentNoteIndex > firstNoteIndex) {
        setSelectedNotes(notes.slice(firstNoteIndex, currentNoteIndex + 1).map((note) => note.id))
      }
    } else {
      // Single select
      setSelectedNotes([noteId])
      setFirstNoteSelected(noteId)
      setNoteHover(noteId)
    }
  }

  return (
    <div
      className="w-full flex flex-wrap pt-5 relative"
      ref={notesRef}
      onClick={() => {
        setSelectedNotes([])
        setFirstNoteSelected(null)
        setNoteHover(null)
      }}
    >
      {notes.map((note) => (
        <Note
          key={note.id}
          note={note}
          selected={selectedNotes.includes(note.id)}
          handleNoteSelect={handleNoteSelect}
          noteHover={noteHover}
          setNoteHover={setNoteHover}
          onDeselect={closeAll}
        />
      ))}
    </div>
  )
}

Notes.propTypes = {
  notes: PropTypes.array,
}
