import React, { useContext } from 'react'
import { MeetingsContext } from '@/context/MeetingContext'
import { Transcript } from './transcript'
import { Competitors } from './competitors'
import { Title } from '../../typography/Title'
import { Quotes } from './quotes'
import { ParticipantNameMenu } from './participantNameMenu'
import ExecutiveSummary from './executive_summary'
import Background from '@/components/meeting/sidebar/Background'

export function Sidebar() {
  const { meetingOverview, participants, transcripts, quotesRef, setSidebarContainerRef } = useContext(MeetingsContext)
  let intervieweeName = ''

  if (!!Object.keys(participants).length) {
    intervieweeName =
      participants[
        Object.keys(participants).find((participant) => participants[participant].role === 'interviewee')
      ]?.name?.toUpperCase()
  }

  return (
    <div className="w-[485px] h-full pb-[80px] overflow-auto hideScrollbar text-lg" ref={setSidebarContainerRef}>
      {meetingOverview?.executive_summary ? (
        <ExecutiveSummary meetingOverview={meetingOverview} intervieweeName={intervieweeName} />
      ) : meetingOverview?.background ? (
        <Background meetingOverview={meetingOverview} intervieweeName={intervieweeName} />
      ) : null}

      <div ref={quotesRef} className="mt-[30px] pb-[12px] border-b border-b-[#e4e4e4]">
        <Title>Key Quotes</Title>
        <p className={'text-slate font-normal mt-2 mb-4'}>Here are some key segments from the conversation.</p>
      </div>
      <Quotes />

      {meetingOverview?.competitors && import.meta.env.VITE_SHOW_COMPETITORS_FEATURE === 'true' && (
        <Competitors data={meetingOverview?.competitors} />
      )}

      <div className="mt-[30px] pb-[12px] border-b border-b-[#e4e4e4]">
        <Title>Transcript</Title>
      </div>

      <div className="mt-[17px] pb-[17px] border-b border-b-[#e4e4e4]">
        <p className="mb-[11px] text-[#62708d] text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">
          Participants
        </p>
        <div className="flex items-center gap-[7px] flex-wrap">
          {Object.keys(participants).map((participantId) => (
            <ParticipantNameMenu
              key={participantId}
              participant={participants[participantId]}
              participantId={participantId}
            />
          ))}
        </div>
      </div>
      {transcripts.map((transcript, index) => (
        <Transcript key={index} transcript={transcript} />
      ))}
    </div>
  )
}
