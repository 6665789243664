import React, { useContext, useState } from 'react'
import { DashboardContext } from '@/context/DashboardContext'
import { useCustomFetch } from '@/hooks/useCustomFetch'
import { ProjectSmallIcon } from '../../icons/Project'
import PropTypes from 'prop-types'
import toast from 'react-hot-toast'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'

export function ChooseProject({ meeting }) {
  const { allProjects, changeProject } = useContext(DashboardContext)
  const [isLoading, setIsLoading] = useState(false)
  const customFetch = useCustomFetch()

  async function handleAssignProject(newProjectId) {
    setIsLoading(true)
    const res = await customFetch('/assignProjectToMeeting', 'PUT', {
      projectId: newProjectId,
      meetingId: meeting.id,
    })
    if (res.error) {
      console.error(res.error)
      toast.error('There was an error assigning the new project')
    } else {
      changeProject(meeting.id, meeting.project_id, newProjectId)
    }
    setIsLoading(false)
  }

  return (
    <div className="relative ml-[9px] flex items-center gap-[9px] cursor-pointer max-w-32" onClick={(e) => e.stopPropagation()}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div className="py-[3px] pl-[8px] pr-[13px] shadow rounded-[30px] border border-[#E4E4E4] flex justify-start items-center gap-[9px] w-full">
            {!meeting.project_id ? (
              <>
                <ProjectSmallIcon disabled />
                <p className="text-[#62708d] text-[10px] font-bold font-['Manrope'] leading-3">Add to Project</p>
              </>
            ) : (
              <>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div className="flex items-center">
                      <ProjectSmallIcon />
                      <p className="text-[#3f424a] text-[10px] font-bold font-['Manrope'] leading-3 max-w-[108px]">
                        {truncateString(meeting.project_name, 10)}
                      </p>
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{meeting.project_name}</p>
                  </TooltipContent>
                </Tooltip>
              </>
            )}
            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.70711 6.79289C6.31658 7.18342 5.68342 7.18342 5.29289 6.79289L0.707105 2.2071C0.0771403 1.57714 0.523309 0.5 1.41421 0.5L10.5858 0.499999C11.4767 0.499999 11.9229 1.57714 11.2929 2.20711L6.70711 6.79289Z"
                fill="#62718D"
              />
            </svg>
            {isLoading && (
              <svg className="w-8 h-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
                <radialGradient id="a1" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)">
                  <stop offset="0" stopColor="#FF156D"></stop>
                  <stop offset=".3" stopColor="#FF156D" stopOpacity=".9"></stop>
                  <stop offset=".6" stopColor="#FF156D" stopOpacity=".6"></stop>
                  <stop offset=".8" stopColor="#FF156D" stopOpacity=".3"></stop>
                  <stop offset="1" stopColor="#FF156D" stopOpacity="0"></stop>
                </radialGradient>
                <circle
                  transform-origin="center"
                  fill="none"
                  stroke="url(#a1)"
                  strokeWidth="15"
                  strokeLinecap="round"
                  strokeDasharray="200 1000"
                  strokeDashoffset="0"
                  cx="100"
                  cy="100"
                  r="70"
                >
                  <animateTransform
                    type="rotate"
                    attributeName="transform"
                    calcMode="spline"
                    dur="2"
                    values="360;0"
                    keyTimes="0;1"
                    keySplines="0 0 1 1"
                    repeatCount="indefinite"
                  ></animateTransform>
                </circle>
                <circle
                  transform-origin="center"
                  fill="none"
                  opacity=".2"
                  stroke="#FF156D"
                  strokeWidth="15"
                  strokeLinecap="round"
                  cx="100"
                  cy="100"
                  r="70"
                ></circle>
              </svg>
            )}
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-[200px] max-h-[260px] overflow-auto hideScrollbar">
          {allProjects.map((project) => (
            <DropdownMenuItem
              key={project.id}
              onClick={() => handleAssignProject(project.id)}
              className="cursor-pointer"
            >
              {project.name}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

function truncateString(str, maxLength) {
  return str.length > maxLength ? str.slice(0, maxLength) + '...' : str
}

ChooseProject.propTypes = {
  meeting: PropTypes.object,
}
