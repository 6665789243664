import { json2csv } from './json2csv'
import { exportcsv } from './exportcsv'
import { getMeetingProvider } from './getMeetingProvider'
import { formatFileDuration, getDayAndMonth, getHour, timeAgo } from './formatDate'

const truncateText = (text, limit) => {
  if (text.length > limit) {
    return text.slice(0, limit) + '...'
  }
  return text
}

const showSlackFeature = import.meta.env.VITE_SHOW_SLACK_FEATURE === 'true'

const getPublicStorageUrl = (folder, filename) => {
  return `https://firebasestorage.googleapis.com/v0/b/${import.meta.env.VITE_FIREBASE_STORAGE_BUCKET}/o/${folder}%2F${filename}?alt=media`
}

export {
  json2csv,
  exportcsv,
  getMeetingProvider,
  getDayAndMonth,
  getHour,
  formatFileDuration,
  timeAgo,
  truncateText,
  showSlackFeature,
  getPublicStorageUrl,
}
