import React, { useCallback, useContext } from 'react'
import { Topbar } from './topbar'
import { Menu } from './menu'
import { Transcripts } from './transcripts'
import { Sidebar } from './sidebar'
import { ProjectContext } from '../../context/ProjectContext'
import { StickyGrid } from './stickyGrid'
import { NotesInput } from './notesInput'
import { Overview } from './overview'
import { OverviewSidebar } from './overview/sidebar'
import { WelcomeProjectDashboard } from './WelcomeProjectDashboard'
import { Meetings } from './meetings'
import { FeedbacksIndex } from './feedbacks/index'
import GroupNotesByCategoryIcon from '../icons/GroupNotesByCategory'
import GroupNotesByMeetingIcon from '../icons/GroupNotesByMeeting'
import QAsIndex from './qas'
import QuotesIndex from './quotes'
import ProblemsIndex from './problems'

export function Project() {
  const {
    isLoading,
    groupNotesBy,
    setGroupNotesBy,
    setScrollTopContainer,
    setSidebarScrollContainer,
    menu,
    project,
    emptyFlag,
  } = useContext(ProjectContext)

  const setContainerRef = useCallback(
    (node) => {
      if (node) setScrollTopContainer(node)
    },
    [setScrollTopContainer]
  )

  const setSidebarContainerRef = useCallback(
    (node) => {
      if (node) setSidebarScrollContainer(node)
    },
    [setSidebarScrollContainer]
  )

  if (isLoading) {
    return (
      <div className="min-h-screen bg-grey-100">
        <div className="w-full fixed top-0 bg-white">
          <Topbar />
        </div>
        <div className="h-full pt-40 flex justify-center items-center bg-grey-100">
          <LoadingSpinner />
        </div>
      </div>
    )
  }

  return (
    <div className="h-screen bg-grey-100">
      <div className="w-full fixed top-0 bg-white relative overflow-hidden">
        <Topbar />
      </div>
      {emptyFlag ? (
        <div className="w-full pb-12">
          <WelcomeProjectDashboard project={project} />
        </div>
      ) : (
        <div>
          <div className="w-full fixed -mt-[2px] z-10">
            <Menu />
          </div>
          {renderMenuContent(menu, setContainerRef, setSidebarContainerRef, groupNotesBy, setGroupNotesBy)}
        </div>
      )}
    </div>
  )
}

function LoadingSpinner() {
  return (
    <svg className="w-40 h-40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
      <radialGradient id="a1" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)">
        <stop offset="0" stopColor="#FF156D"></stop>
        <stop offset=".3" stopColor="#FF156D" stopOpacity=".9"></stop>
        <stop offset=".6" stopColor="#FF156D" stopOpacity=".6"></stop>
        <stop offset=".8" stopColor="#FF156D" stopOpacity=".3"></stop>
        <stop offset="1" stopColor="#FF156D" stopOpacity="0"></stop>
      </radialGradient>
      <circle
        transform-origin="center"
        fill="none"
        stroke="url(#a1)"
        strokeWidth="15"
        strokeLinecap="round"
        strokeDasharray="200 1000"
        strokeDashoffset="0"
        cx="100"
        cy="100"
        r="70"
      >
        <animateTransform
          type="rotate"
          attributeName="transform"
          calcMode="spline"
          dur="2"
          values="360;0"
          keyTimes="0;1"
          keySplines="0 0 1 1"
          repeatCount="indefinite"
        ></animateTransform>
      </circle>
      <circle
        transform-origin="center"
        fill="none"
        opacity=".2"
        stroke="#FF156D"
        strokeWidth="15"
        strokeLinecap="round"
        cx="100"
        cy="100"
        r="70"
      ></circle>
    </svg>
  )
}

function renderMenuContent(menu, setContainerRef, setSidebarContainerRef, groupNotesBy, setGroupNotesBy) {
  switch (menu) {
    case 'overview':
      return (
        <div className="pt-[40px] flex gap-[36px]">
          <div
            className="fixed mt-[50px] left-[50px] bg-white rounded-t-2xl shadow flex overflow-auto hideScrollbar"
            style={{ height: 'calc(100% - 188px)', width: 'calc(100% - 50px - 50px - 372px - 56px - 36px)' }}
          >
            <Overview />
          </div>
          <aside
            className="h-[331px] fixed mt-[50px] right-[50px] p-7 overflow-x-visible overflow-y-auto hideScrollbar"
            style={{ height: 'calc(100% - 188px)' }}
          >
            <OverviewSidebar />
          </aside>
        </div>
      )
    case 'meetings':
      return (
        <div
          className="fixed flex mt-[50px] w-full overflow-auto hideScrollbar"
          style={{ height: 'calc(100% - 80px - 48px)' }}
        >
          <Meetings />
        </div>
      )
    case 'feedbacks':
      return (
        <div
          className="fixed flex mt-[50px] w-full overflow-auto hideScrollbar"
          style={{ height: 'calc(100% - 80px - 48px)' }}
        >
          <FeedbacksIndex />
        </div>
      )
    case 'qas':
      return (
        <div
          className="fixed flex mt-[50px] w-full overflow-auto hideScrollbar"
          style={{ height: 'calc(100% - 80px - 48px)' }}
        >
          <QAsIndex />
        </div>
      )
    case 'quotes':
      return (
        <div
          className="fixed flex mt-[50px] w-full overflow-auto hideScrollbar"
          style={{ height: 'calc(100% - 80px - 48px)' }}
        >
          <QuotesIndex />
        </div>
      )
    case 'problems':
      return (
        <div
          className="fixed flex mt-[50px] w-full overflow-auto hideScrollbar"
          style={{ height: 'calc(100% - 80px - 48px)' }}
        >
          <ProblemsIndex />
        </div>
      )
    case 'notes':
      return (
        <NotesView
          setSidebarContainerRef={setSidebarContainerRef}
          setContainerRef={setContainerRef}
          groupNotesBy={groupNotesBy}
          setGroupNotesBy={setGroupNotesBy}
        />
      )
    case 'transcripts':
      return <TranscriptsView setSidebarContainerRef={setSidebarContainerRef} setContainerRef={setContainerRef} />
    default:
      return null
  }
}

const TranscriptsView = ({ setSidebarContainerRef, setContainerRef }) => (
  <div className="pt-[30px]">
    <aside
      ref={setSidebarContainerRef}
      className="w-[372px] fixed mx-10 p-6 bg-white rounded-t-2xl shadow overflow-auto hideScrollbar mt-[50px]"
      style={{ height: 'calc(100% - 188px)' }}
    >
      <Sidebar transcriptsView={true} />
    </aside>
    <div
      ref={setContainerRef}
      className="fixed overflow-auto hideScrollbar mt-[50px]"
      style={{ height: '80%', width: 'calc(100% - 492px)', right: '40px' }}
    >
      <Transcripts />
    </div>
  </div>
)

const NotesView = ({ setSidebarContainerRef, setContainerRef, groupNotesBy, setGroupNotesBy }) => (
  <div className="relative">
    <div className="absolute top-[80px] right-[43px] z-20">
      <div
        className="h-10 rounded-[5px] bg-white shadow border border-grey-200 flex items-center"
        style={{ width: '82px' }}
      >
        <div
          className={`mx-2 cursor-pointer ${groupNotesBy === 'meeting' && 'bg-lightPink2'}`}
          onClick={() => {
            setGroupNotesBy('meeting')
          }}
        >
          <GroupNotesByMeetingIcon groupNotesBy={groupNotesBy} />
        </div>
        <div
          className={`mr-2 cursor-pointer ${groupNotesBy === 'category' && 'bg-lightPink2'}`}
          onClick={() => {
            setGroupNotesBy('category')
          }}
        >
          <GroupNotesByCategoryIcon groupNotesBy={groupNotesBy} />
        </div>
      </div>
    </div>
    <aside
      ref={setSidebarContainerRef}
      className="w-[372px] fixed mx-10 p-6 bg-white rounded-t-2xl shadow overflow-auto hideScrollbar mt-[80px]"
      style={{ height: 'calc(100% - 188px)' }}
    >
      <Sidebar transcriptsView={false} />
    </aside>
    <div
      ref={setContainerRef}
      className="fixed overflow-auto hideScrollbar mt-[80px]"
      style={
        groupNotesBy === 'category'
          ? { height: 'calc(100% - 258px)', width: 'calc(100% - 412px)', right: 0, paddingBottom: '5px' }
          : { height: 'calc(100% - 258px)', width: 'calc(100% - 412px)', right: '40px', paddingLeft: '80px' }
      }
    >
      <StickyGrid groupNotesBy={groupNotesBy} />
    </div>
    <div className="h-24 fixed bottom-0 right-0" style={{ width: 'calc(100% - 372px - 40px)' }}>
      <NotesInput />
    </div>
  </div>
)
