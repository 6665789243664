/* eslint-disable react/no-unknown-property */
import React, { useContext, useState } from 'react'
import { createPortal } from 'react-dom'
import { QuoteDialog } from './quoteDialog'
import { ProjectContext } from '../../../../context/ProjectContext'
import thumbnailDefault from '../../../../assets/audio-thumbnail.png'
import PropTypes from 'prop-types'
import { Quote } from './quote'

export function Quotes({ label, quotes }) {
  const [currentQuote, setCurrentQuote] = useState(-1)
  const { sessions } = useContext(ProjectContext)

  if (!quotes.length) {
    return (
      <div className="w-full">
        {label && (
          <div className='py-[8px] border-b-2 border-grey-200'>
            <p className="text-[#3f424a] text-4xl font-bold font-['DM_Sans'] leading-[41.40px]">{label}</p>
          </div>
        )}
        <div className="flex justify-center">
          <svg className="w-16 h-16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
            <radialGradient id="a1" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)">
              <stop offset="0" stopColor="#FF156D"></stop>
              <stop offset=".3" stopColor="#FF156D" stopOpacity=".9"></stop>
              <stop offset=".6" stopColor="#FF156D" stopOpacity=".6"></stop>
              <stop offset=".8" stopColor="#FF156D" stopOpacity=".3"></stop>
              <stop offset="1" stopColor="#FF156D" stopOpacity="0"></stop>
            </radialGradient>
            <circle
              transform-origin="center"
              fill="none"
              stroke="url(#a1)"
              strokeWidth="15"
              strokeLinecap="round"
              strokeDasharray="200 1000"
              strokeDashoffset="0"
              cx="100"
              cy="100"
              r="70"
            >
              <animateTransform
                type="rotate"
                attributeName="transform"
                calcMode="spline"
                dur="2"
                values="360;0"
                keyTimes="0;1"
                keySplines="0 0 1 1"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
            <circle
              transform-origin="center"
              fill="none"
              opacity=".2"
              stroke="#FF156D"
              strokeWidth="15"
              strokeLinecap="round"
              cx="100"
              cy="100"
              r="70"
            ></circle>
          </svg>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="w-full">
        {label && (
          <div className='mb-[36px] py-[8px] border-b-2 border-grey-200'>
            <p className="text-[#3f424a] text-4xl font-bold font-['DM_Sans'] leading-[41.40px]">{label}</p>
          </div>
        )}
        <div className={`flex gap-2.5 -mr-7 pr-7 ${!label ? 'flex-wrap' : 'overflow-x-auto hideScrollbar'}`}>
          {Array.isArray(quotes) &&
            quotes.map((quote, index) => <Quote key={quote.id} quote={quote} index={index} setCurrentQuote={setCurrentQuote} label={label ? 'best Quote on' : 'Quote'} />)}
        </div>
      </div>

      {currentQuote >= 0 &&
        createPortal(
          <QuoteDialog
            meet={sessions.find((session) => session.id === quotes[currentQuote]?.meetingId)}
            quote={quotes[currentQuote]}
            currentQuote={currentQuote}
            totalQuotes={quotes.length}
            thumbnail={
              quotes[currentQuote]?.thumbnailUrl ||
              sessions.find((session) => session.id === quotes[currentQuote]?.meetingId)?.thumbnailUrl ||
              thumbnailDefault
            }
            next={currentQuote > 0 ? () => setCurrentQuote((prev) => prev - 1) : null}
            prev={currentQuote < quotes.length - 1 ? () => setCurrentQuote((prev) => prev + 1) : null}
            closeDialog={() => setCurrentQuote(-1)}
          />,
          document.body
        )}
    </>
  )
}

Quotes.propTypes = {
  label: PropTypes.string,
  quotes: PropTypes.array,
}
