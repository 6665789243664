import { useNavigate } from 'react-router-dom'
import { useContext, useRef, useState } from 'react'
import { DashboardContext } from '../context/DashboardContext'
import { useCustomFetch } from './useCustomFetch'
import toast from 'react-hot-toast'
import { getMeetingProvider } from '../utils'

export default function useProjectSelection(link, importFile) {
  const navigate = useNavigate()
  const { setProjects, allProjects } = useContext(DashboardContext)
  const [isNewProject, setIsNewProject] = useState(!allProjects.length || (!link && !importFile))
  const [showImportDialog, setShowImportDialog] = useState(false)
  const [projectId, setProjectId] = useState('')
  const [projectName, setProjectName] = useState('')
  const createProjectClicked = useRef(false)
  const customFetch = useCustomFetch()

  async function handleCreateProject(projectName, projectDescription, method) {
    try {
      if (!projectName) return toast.error('Project name is required.')
      if (!projectDescription) return toast.error('Project description is required.')
      if (!method) return toast.error('Method is required.')
      if (createProjectClicked.current) return
      createProjectClicked.current = true

      const data = await customFetch('/createProject', 'POST', { projectName, projectDescription, method })
      if (getMeetingProvider(link) !== '') {
        await customFetch('/createBot', 'POST', { link, projectId: data.id, projectName, projectDescription })
        alert('Great! Go accept the bot into the meeting.')
      }
      if (importFile) {
        setShowImportDialog(true)
        setProjectId(data.id)
        setProjectName(projectName)
      } else {
        navigate(`/project/${data.id}`)
      }
      setProjects()
    } catch (error) {
      console.error('Error sending bot:', error)
    } finally {
      await new Promise((resolve) => setTimeout(resolve, 6000))
      createProjectClicked.current = false
    }
  }

  async function handleChooseExistingProject(project) {
    try {
      if (getMeetingProvider(link)) {
        await customFetch('/createBot', 'POST', {
          link,
          projectId: project.id,
          projectName: project.name,
          projectDescription: project.description,
        })
        alert('Great! Go accept the bot into the meeting.')
      }
      if (importFile) {
        setShowImportDialog(true)
        setProjectId(project.id)
        setProjectName(project.name)
      } else {
        navigate(`/project/${project.id}`)
      }
    } catch (error) {
      console.error('Error sending bot:', error)
    }
  }

  function handleGoBack() {
    setShowImportDialog(false)
    setProjectId('')
    setProjectName('')
  }

  return {
    isNewProject,
    setIsNewProject,
    showImportDialog,
    projectId,
    projectName,
    handleGoBack,
    handleCreateProject,
    handleChooseExistingProject,
  }
}
