import React from 'react'

const AIIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_6641_40911)">
        <path
          d="M18.6667 21.0013C19.2855 21.0013 19.879 21.2471 20.3166 21.6847C20.7542 22.1223 21 22.7158 21 23.3346C21 22.7158 21.2458 22.1223 21.6834 21.6847C22.121 21.2471 22.7145 21.0013 23.3333 21.0013C22.7145 21.0013 22.121 20.7555 21.6834 20.3179C21.2458 19.8803 21 19.2868 21 18.668C21 19.2868 20.7542 19.8803 20.3166 20.3179C19.879 20.7555 19.2855 21.0013 18.6667 21.0013ZM18.6667 7.0013C19.2855 7.0013 19.879 7.24713 20.3166 7.68472C20.7542 8.1223 21 8.7158 21 9.33463C21 8.7158 21.2458 8.1223 21.6834 7.68472C22.121 7.24713 22.7145 7.0013 23.3333 7.0013C22.7145 7.0013 22.121 6.75547 21.6834 6.31788C21.2458 5.8803 21 5.28681 21 4.66797C21 5.28681 20.7542 5.8803 20.3166 6.31788C19.879 6.75547 19.2855 7.0013 18.6667 7.0013ZM10.5 21.0013C10.5 19.1448 11.2375 17.3643 12.5503 16.0516C13.863 14.7388 15.6435 14.0013 17.5 14.0013C15.6435 14.0013 13.863 13.2638 12.5503 11.951C11.2375 10.6383 10.5 8.85782 10.5 7.0013C10.5 8.85782 9.7625 10.6383 8.44975 11.951C7.13699 13.2638 5.35652 14.0013 3.5 14.0013C5.35652 14.0013 7.13699 14.7388 8.44975 16.0516C9.7625 17.3643 10.5 19.1448 10.5 21.0013Z"
          stroke="#FF4A8E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6641_40911">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AIIcon
