import { useEffect } from 'react'

function useOutsideClick(ref, callback) {
  useEffect(() => {
    function handleClick(event) {
      if (!ref || !callback) return

      // For array of refs
      if (ref instanceof Array) {
        const isInside = ref.some((refItem) => {
          if (!refItem?.current) return false
          return refItem.current === event.target || refItem.current.contains(event.target)
        })

        if (!isInside) {
          callback()
        }
      }
      // For single ref
      else {
        if (ref.current && ref.current !== event.target && !ref.current.contains(event.target)) {
          callback()
        }
      }
    }

    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [ref, callback])
}

export default useOutsideClick
