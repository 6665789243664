import React from 'react'
import PropTypes from 'prop-types'
import { formatFileDuration, getMeetingProvider } from '../../../utils'
import thumbnailDefault from '../../../assets/audio-thumbnail.png'
import { MeetingProviderSmall } from '../../utils/meetingProvider'
import { useNavigate } from 'react-router-dom'
import { formatMeetingTime } from '../../../utils/formatDate'
import { ChooseProject } from './chooseProject'
import { Hourglass } from 'lucide-react'

export function Meeting({ meeting, validation, fromProject = false }) {
  const navigate = useNavigate()
  const meetingBackground = meeting.background?.bullet_1
    ? meeting.background.bullet_1.length > 110
      ? meeting.background.bullet_1.slice(0, 110) + '...'
      : meeting.background.bullet_2
        ? meeting.background.bullet_1.length + meeting.background.bullet_2.length > 110
          ? (meeting.background.bullet_1 + meeting.background.bullet_2).slice(0, 110) + '...'
          : meeting.background.bullet_1 + meeting.background.bullet_2
        : meeting.background.bullet_1
    : "This meeting doesn't have a summary yet..."

  return (
    <button
      key={meeting.id}
      className={`p-[20px] h-[357px] w-[287px] rounded bg-white border border-[#e4e4e4] rounded-[8px] flex flex-col justify-start gap-[16px] ${
        meeting.processing ? 'cursor-progress opacity-70' : 'cursor-pointer'
      }`}
      onClick={() => !meeting.processing && navigate(`/dashboard/meetings/${meeting.id}`)}
      disabled={meeting.processing}
    >
      <div className="w-full h-[151px] bg-black/30 rounded relative">
        <img
          className="w-full h-full rounded"
          src={fromProject ? meeting.thumbnailUrl || thumbnailDefault : meeting.thumbnail || thumbnailDefault}
          alt={meeting.name}
        />
        {meeting.processing ? (
          <div className="absolute inset-0 bg-black/50 rounded flex items-center justify-center">
            <div className="bg-white rounded-full p-3">
              <Hourglass className="w-6 h-6 text-[#3F424A]" />
            </div>
          </div>
        ) : (
          <div
            className="absolute bottom-[8px] left-[8px] flex items-center justify-between w-full"
            style={{ width: 'calc(100% - 17px)' }}
          >
            <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_4038_17134)">
                <circle cx="22.0889" cy="22.9316" r="12" fill="white" />
                <path
                  d="M27.6866 22.608C27.8936 22.7754 27.8936 23.091 27.6866 23.2584L19.7701 29.6574C19.4967 29.8784 19.0891 29.6838 19.0891 29.3322L19.0891 16.5342C19.0891 16.1826 19.4967 15.988 19.7701 16.209L27.6866 22.608Z"
                  fill="black"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_4038_17134"
                  x="0.0538013"
                  y="0.896575"
                  width="44.0701"
                  height="44.0701"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feMorphology
                    radius="3.34502"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_4038_17134"
                  />
                  <feOffset />
                  <feGaussianBlur stdDeviation="3.34502" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4038_17134" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4038_17134" result="shape" />
                </filter>
              </defs>
            </svg>
            <div className="h-[19px] px-2 py-[3px] bg-black/60 rounded-[30px] justify-center items-center gap-2.5 inline-flex">
              <div className="text-white text-[10px] font-bold font-['Manrope'] leading-3">
                {formatFileDuration(meeting.duration)}
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        <p className="text-start text-[#3f424a] text-base font-semibold font-['Manrope'] leading-tight">
          {meeting.name?.length ? (meeting.name.length > 28 ? meeting.name.slice(0, 25) + '...' : meeting.name) : ''}
        </p>
        <div className="mt-[3px] flex flex-wrap gap-y-1 items-center text-slate text-[13px] font-medium font-['Manrope'] leading-none">
          <MeetingProviderSmall provider={getMeetingProvider(meeting.meeting_url)} />
          <p>・</p>
          <p>{formatMeetingTime(meeting).date} ・</p>
          <p>{formatMeetingTime(meeting).startTime}</p>
          <p> {formatMeetingTime(meeting).endTime && <p>&nbsp;- {formatMeetingTime(meeting).endTime}</p>}</p>
        </div>
      </div>
      <p className="text-start h-[48px] text-[#62708d] text-[13px] font-medium font-['Manrope'] leading-none">
        {meetingBackground}
      </p>
      <div className="w-full h-[26px] flex justify-between items-center gap-2">
        <Validation validation={validation} />
        <ChooseProject meeting={meeting} />
      </div>
      {/* <p>PROJECT NAME: {allProjects.find(project => project.id === meeting.project_id).name}</p>
      <p>MEETING NAME: {meeting.name}</p> */}
    </button>
  )
}

function Validation(props) {
  return (
    <div className="py-[3px] pl-[3px] pr-[7px] shadow rounded-[30px] border border-[#E4E4E4] flex justify-center items-center gap-[9px]">
      {props.validation === 'yes' ? (
        <>
          <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="11.2845" cy="11.6204" r="9.21611" fill="white" />
            <path
              d="M15.8925 3.63916C17.2825 4.44175 18.4389 5.59345 19.247 6.98027C20.0551 8.36709 20.4871 9.94092 20.5 11.546C20.513 13.151 20.1066 14.7316 19.3209 16.1313C18.5353 17.531 17.3977 18.7012 16.0208 19.5262C14.644 20.3511 13.0755 20.8021 11.4707 20.8346C9.86595 20.8671 8.28052 20.4799 6.87138 19.7113C5.46224 18.9428 4.27826 17.8195 3.43664 16.4528C2.59502 15.086 2.12495 13.5232 2.07297 11.9189L2.06836 11.6203L2.07297 11.3217C2.12458 9.73007 2.58773 8.17897 3.41726 6.81963C4.2468 5.46028 5.41441 4.33907 6.80626 3.56532C8.19811 2.79156 9.76671 2.39166 11.3591 2.4046C12.9515 2.41754 14.5134 2.84288 15.8925 3.63916ZM14.7009 9.1255C14.5422 8.96682 14.331 8.8715 14.1071 8.85742C13.8831 8.84333 13.6617 8.91146 13.4843 9.04901L13.3977 9.1255L10.3629 12.1594L9.17121 10.9687L9.08458 10.8922C8.90725 10.7548 8.68586 10.6867 8.46194 10.7009C8.23802 10.715 8.02695 10.8103 7.8683 10.969C7.70965 11.1276 7.61432 11.3387 7.60019 11.5626C7.58606 11.7865 7.65411 12.0079 7.79156 12.1852L7.86805 12.2719L9.71127 14.1151L9.79791 14.1916C9.95953 14.317 10.1583 14.3851 10.3629 14.3851C10.5674 14.3851 10.7662 14.317 10.9278 14.1916L11.0144 14.1151L14.7009 10.4287L14.7774 10.342C14.9149 10.1647 14.983 9.94329 14.969 9.71931C14.9549 9.49534 14.8596 9.2842 14.7009 9.1255Z"
              fill="#1FB912"
            />
          </svg>
          <p className="text-[#3f424a] text-[10px] font-bold font-['Manrope'] leading-3">Likely User</p>
        </>
      ) : props.validation === 'no' ? (
        <>
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10.0003" cy="10.8353" r="8.33333" fill="white" />
            <path
              d="M14.167 3.61853C15.4239 4.34424 16.4694 5.38564 17.2002 6.63961C17.9309 7.89359 18.3215 9.31667 18.3332 10.768C18.3449 12.2193 17.9774 13.6485 17.267 14.9141C16.5566 16.1797 15.528 17.2379 14.283 17.9838C13.038 18.7297 11.6198 19.1376 10.1687 19.1669C8.71768 19.1963 7.28412 18.8462 6.00995 18.1512C4.73579 17.4563 3.66522 16.4406 2.90421 15.2048C2.14321 13.969 1.71817 12.5558 1.67116 11.1052L1.66699 10.8352L1.67116 10.5652C1.71783 9.12602 2.13662 7.7235 2.88669 6.49436C3.63677 5.26522 4.69254 4.25141 5.95107 3.55177C7.2096 2.85212 8.62795 2.49053 10.0678 2.50223C11.5077 2.51393 12.92 2.89853 14.167 3.61853ZM8.75949 8.45186C8.58489 8.34793 8.37833 8.31137 8.17867 8.34906C7.979 8.38674 7.79998 8.49609 7.67528 8.65652C7.55058 8.81695 7.4888 9.01741 7.50154 9.2202C7.51429 9.42299 7.60069 9.61414 7.74449 9.7577L8.82116 10.8352L7.74449 11.9127L7.67533 11.991C7.54582 12.1585 7.48492 12.369 7.50499 12.5798C7.52507 12.7906 7.62462 12.9858 7.78343 13.1258C7.94223 13.2659 8.14838 13.3402 8.36001 13.3337C8.57163 13.3273 8.77286 13.2405 8.92283 13.091L10.0003 12.0144L11.0778 13.091L11.1562 13.1602C11.3237 13.2897 11.5342 13.3506 11.7449 13.3305C11.9557 13.3105 12.1509 13.2109 12.291 13.0521C12.431 12.8933 12.5053 12.6871 12.4988 12.4755C12.4924 12.2639 12.4056 12.0627 12.2562 11.9127L11.1795 10.8352L12.2562 9.7577L12.3253 9.67936C12.4548 9.51187 12.5157 9.30136 12.4957 9.09059C12.4756 8.87982 12.376 8.6846 12.2172 8.54457C12.0584 8.40454 11.8523 8.33021 11.6406 8.33667C11.429 8.34314 11.2278 8.42991 11.0778 8.57936L10.0003 9.65603L8.92283 8.57936L8.84449 8.5102L8.75949 8.45186Z"
              fill="#D40814"
            />
          </svg>
          <p className="text-[#3f424a] text-[10px] font-bold font-['Manrope'] leading-3">Unlikely User</p>
        </>
      ) : props.validation === 'maybe' ? (
        <>
          <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10.5003" cy="10.8353" r="8.33333" fill="white" />
            <path
              d="M10.5003 2.50195C15.1028 2.50195 18.8337 6.23279 18.8337 10.8353C18.8354 13.0232 17.9766 15.124 16.4427 16.6842C14.9089 18.2443 12.823 19.1387 10.6353 19.1741C8.44774 19.2096 6.33394 18.3833 4.75032 16.8736C3.16671 15.364 2.24032 13.2921 2.17116 11.1053L2.16699 10.8353L2.17033 10.602C2.29366 6.10779 5.97533 2.50195 10.5003 2.50195ZM10.5003 13.3353C10.2962 13.3353 10.0992 13.4102 9.94668 13.5459C9.79415 13.6815 9.69671 13.8684 9.67283 14.0711L9.66699 14.1686L9.67283 14.2745C9.69692 14.477 9.79446 14.6637 9.94696 14.7991C10.0995 14.9345 10.2964 15.0094 10.5003 15.0094C10.7043 15.0094 10.9012 14.9345 11.0537 14.7991C11.2062 14.6637 11.3037 14.477 11.3278 14.2745L11.3337 14.177L11.3278 14.0711C11.3039 13.8684 11.2065 13.6815 11.054 13.5459C10.9014 13.4102 10.7044 13.3353 10.5003 13.3353ZM11.6403 7.77445C11.1415 7.51942 10.5711 7.44057 10.0217 7.55071C9.47241 7.66085 8.97647 7.9535 8.61449 8.38112C8.47648 8.54279 8.40546 8.75109 8.41598 8.9634C8.42649 9.1757 8.51774 9.37597 8.67105 9.52321C8.82436 9.67045 9.02814 9.75354 9.2407 9.75548C9.45326 9.75741 9.65853 9.67805 9.81449 9.53362L9.95699 9.38362C10.0799 9.27077 10.2345 9.19829 10.3999 9.17594C10.5653 9.1536 10.7335 9.18245 10.882 9.25862C11.0421 9.33963 11.1717 9.47013 11.2517 9.63073C11.3316 9.79133 11.3576 9.97344 11.3258 10.15C11.294 10.3266 11.206 10.4881 11.075 10.6107C10.944 10.7333 10.7769 10.8103 10.5987 10.8303L10.4053 10.8403C10.1934 10.8636 9.99843 10.9673 9.8606 11.13C9.72278 11.2927 9.65257 11.502 9.66442 11.7149C9.67627 11.9278 9.76929 12.1281 9.92431 12.2745C10.0793 12.4209 10.2846 12.5023 10.4978 12.502C11.06 12.5036 11.6064 12.3158 12.0486 11.9687C12.4909 11.6217 12.8033 11.1357 12.9354 10.5892C13.0674 10.0428 13.0115 9.46775 12.7765 8.95703C12.5415 8.4463 12.1412 8.02968 11.6403 7.77445Z"
              fill="#FFC921"
            />
          </svg>
          <p className="text-[#3f424a] text-[10px] font-bold font-['Manrope'] leading-3">Maybe User</p>
        </>
      ) : (
        <>
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 50 50">
            <path
              d="M25,2C12.319,2,2,12.319,2,25s10.319,23,23,23s23-10.319,23-23S37.681,2,25,2z M33.71,32.29c0.39,0.39,0.39,1.03,0,1.42	C33.51,33.9,33.26,34,33,34s-0.51-0.1-0.71-0.29L25,26.42l-7.29,7.29C17.51,33.9,17.26,34,17,34s-0.51-0.1-0.71-0.29	c-0.39-0.39-0.39-1.03,0-1.42L23.58,25l-7.29-7.29c-0.39-0.39-0.39-1.03,0-1.42c0.39-0.39,1.03-0.39,1.42,0L25,23.58l7.29-7.29	c0.39-0.39,1.03-0.39,1.42,0c0.39,0.39,0.39,1.03,0,1.42L26.42,25L33.71,32.29z"
              fill="#a4abb6"
            ></path>
          </svg>
          <p className="text-[#3f424a] text-[10px] font-bold font-['Manrope'] leading-3">No Validation</p>
        </>
      )}
    </div>
  )
}

Validation.propTypes = { validation: PropTypes.any }

Meeting.propTypes = {
  meeting: PropTypes.object,
  validation: PropTypes.string,
  fromProject: PropTypes.bool,
}
