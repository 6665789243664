/* eslint-disable react/no-unknown-property */
import React, { useState } from 'react'
import { createPortal } from 'react-dom'
import { ProjectLayout } from './dialog/projectLayout'
import Introduction from '../../assets/welcomedashboard/introduction.png'
import LeftText from '../../assets/welcomedashboard/notes/left-text.png'
import RightText from '../../assets/welcomedashboard/notes/right-text.png'
import LeftArrow from '../../assets/welcomedashboard/notes/left-arrow.png'
import RightArrow from '../../assets/welcomedashboard/notes/right-arrow.png'
import { DynamicItems } from './dynamic-items/DynamicItems'

export function WelcomeDashboard() {
  const [showCreateProjectDialog, setShowCreateProjectDialog] = useState(false)
  const isFullWidth = false

  return (
    <>
      <div style={{ padding: '40px 2.5rem 40px 2.5rem' }}>
        <div className="bg-[#FFEFF5] rounded-xl">
          <div className="p-6 flex justify-between flex-wrap items-center">
            <div>
              <h1 className="text-[34px] text-[#40424A] font-bold">Welcome to Usermuse! 👋</h1>
              <p className=" text-[18px] text-slate">We turn meetings into objective user research.</p>
            </div>
            <button
              className="bg-pink text-white px-5 py-3 rounded-lg"
              onClick={() => setShowCreateProjectDialog(true)}
            >
              + Create Your First Project
            </button>
            {showCreateProjectDialog &&
              createPortal(
                <ProjectLayout link="" importFile={false} handleClose={() => setShowCreateProjectDialog(false)} />,
                document.body
              )}
          </div>

          <div className="grid grid-cols-3 justify-center items-center" style={{ gridTemplateColumns: '10% 70% 10%' }}>
            <div className="">
              <img src={LeftText} alt="" />
              <div className="flex justify-end">
                <img src={RightArrow} className="w-[70px]" alt="" />
              </div>
            </div>
            <div className="">
              <img src={Introduction} alt="" />
            </div>
            <div className="">
              <img src={RightText} className="w-[90px]" alt="" />
              <div className="flex justify-start">
                <img src={LeftArrow} className="w-[70px]" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <DynamicItems vertical={isFullWidth} />
    </>
  )
}
