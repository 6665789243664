import React from 'react'

export function GoogleIcon() {
  return (
    <svg width="79" height="79" viewBox="0 0 79 79" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M60.0084 18.7083L41.592 16.6621L19.0827 18.7083L17.0361 39.1713L19.0824 59.6344L39.5454 62.1922L60.0084 59.6344L62.0546 38.6601L60.0084 18.7083Z"
        fill="white"
      />
      <path
        d="M27.4778 50.4556C25.9482 49.4222 24.8892 47.9133 24.311 45.918L27.8615 44.455C28.1838 45.6828 28.7464 46.6342 29.5496 47.3095C30.3478 47.9848 31.3198 48.3173 32.4555 48.3173C33.6168 48.3173 34.6145 47.9642 35.448 47.2582C36.2816 46.5521 36.7015 45.6517 36.7015 44.5623C36.7015 43.4472 36.2614 42.5363 35.3816 41.8306C34.5017 41.125 33.3968 40.7715 32.0768 40.7715H30.0255V37.2572H31.8669C33.0025 37.2572 33.9594 36.9505 34.7369 36.3366C35.5145 35.7227 35.9033 34.8836 35.9033 33.8145C35.9033 32.8631 35.5554 32.1057 34.8598 31.5381C34.1642 30.9704 33.284 30.6839 32.2148 30.6839C31.1713 30.6839 30.3424 30.9603 29.7285 31.5179C29.1146 32.0754 28.6694 32.7608 28.3883 33.5691L24.874 32.1061C25.3394 30.7861 26.194 29.6198 27.4471 28.612C28.7005 27.6043 30.3016 27.0977 32.2455 27.0977C33.6829 27.0977 34.9772 27.3741 36.1234 27.9316C37.2692 28.4892 38.1696 29.2617 38.8193 30.2438C39.469 31.2309 39.7913 32.3363 39.7913 33.5637C39.7913 34.8172 39.4896 35.8759 38.8858 36.7456C38.282 37.6153 37.5401 38.2802 36.6603 38.7459V38.9555C37.8216 39.4415 38.768 40.1833 39.5148 41.1809C40.2567 42.1786 40.6299 43.3706 40.6299 44.7621C40.6299 46.1536 40.2769 47.3966 39.5708 48.4864C38.8648 49.5762 37.8877 50.4354 36.6498 51.0594C35.4068 51.6834 34.0103 52.0007 32.4602 52.0007C30.6647 52.0058 29.0073 51.489 27.4778 50.4556Z"
        fill="#1A73E8"
      />
      <path
        d="M49.2648 32.8385L45.387 35.6572L43.438 32.7005L50.4312 27.6562H53.112V51.4493H49.2648V32.8385Z"
        fill="#1A73E8"
      />
      <path
        d="M60.008 78.0499L78.4244 59.6335L69.2162 55.541L60.008 59.6335L55.9155 68.8417L60.008 78.0499Z"
        fill="#EA4335"
      />
      <path d="M14.9902 68.841L19.0827 78.0492H60.0083V59.6328H19.0827L14.9902 68.841Z" fill="#34A853" />
      <path
        d="M6.80469 0.291016C3.41323 0.291016 0.666016 3.03823 0.666016 6.42969V59.633L9.87422 63.7255L19.0824 59.633V18.7074H60.008L64.1005 9.49922L60.0084 0.291016H6.80469Z"
        fill="#4285F4"
      />
      <path
        d="M0.666016 59.6328V71.9105C0.666016 75.3024 3.41324 78.0492 6.80469 78.0492H19.0824V59.6328H0.666016Z"
        fill="#188038"
      />
      <path d="M60.0083 18.7077V59.6333H78.4247V18.7077L69.2165 14.6152L60.0083 18.7077Z" fill="#FBBC04" />
      <path
        d="M78.4247 18.7074V6.42969C78.4247 3.03785 75.6775 0.291016 72.286 0.291016H60.0083V18.7074H78.4247Z"
        fill="#1967D2"
      />
    </svg>
  )
}