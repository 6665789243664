import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { MeetingsContext } from '../../../../context/MeetingContext'
import thumbnailDefault from '../../../../assets/audio-thumbnail.png'
import { intervalToDuration } from 'date-fns'
import Quotes from '../../../icons/Quotes'
import { Title } from '../../../typography/Title'
import { useScrollTo } from '../../../../hooks/useScrollTo'

export function Quote({ quote, index, setCurrentQuote }) {
  const {
    scrollToQuote,
    setScrollToQuote,
    scrollQuotesContainer,
    scrollTopContainer,
    thumbnail,
  } = useContext(MeetingsContext)

  const quoteRef = useScrollTo({
    id: quote.id,
    entityToScrollTo: scrollToQuote,
    setScrollTo: setScrollToQuote,
    scrollInnerContainer: scrollQuotesContainer,
    scrollTopContainer: scrollTopContainer,
  })

  return (
    <div
      key={quote.id}
      ref={quoteRef}
      className="relative flex bg-white rounded-lg shadow p-4 hover:shadow-md transition-shadow h-28 cursor-pointer"
      onClick={() => setCurrentQuote(index)}
    >
      <div className="absolute top-4 right-4">
        <Quotes />
      </div>
      <div className="w-20 flex-shrink-0 overflow-hidden rounded-md items-center ">
        <div className="relative w-full h-full bg-black/60 rounded-sm border border-[#3f424a]">
          <img
            className={`w-full h-full rounded ${quote.thumbnailUrl || thumbnail ? 'opacity-70' : ''}`}
            src={quote.thumbnailUrl || thumbnail || thumbnailDefault}
            alt="quote"
          />
          <div className="absolute" style={{ top: 'calc(50% - 10px)', left: 'calc(50% - 11px)' }}>
            <svg width="22" height="20" viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
              <ellipse cx="18.7314" cy="18.8159" rx="18.1982" ry="18.2905" fill="white" />
              <path
                d="M27.2122 18.3131C27.5308 18.5719 27.5308 19.0582 27.2122 19.317L15.236 29.0464C14.8133 29.3897 14.1814 29.089 14.1814 28.5444L14.1814 9.0857C14.1814 8.54114 14.8133 8.24037 15.236 8.58373L27.2122 18.3131Z"
                fill="black"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className={'relative pl-4'}>
        <Title size={'text-md'} className={'w-11/12'}>
          {quote.content}
        </Title>
        <p className="absolute bottom-0 text-[13px] font-medium font-['Manrope'] leading-none">
          {formatTranscriptTime(quote)}
        </p>
      </div>
    </div>
  )
}

function formatTranscriptTime(data) {
  const startTime = data.start_time // e.g., 366.075

  // Helper function to format time in mm:ss
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time % 60)
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds
    return `${minutes}:${formattedSeconds}`
  }

  // Helper function to format seconds into "X minutes Y seconds"
  const formatDetailedTime = (time) => {
    const duration = intervalToDuration({ start: 0, end: time * 1000 }) // convert seconds to milliseconds
    return `${duration.minutes} minutes ${duration.seconds} seconds`
  }

  // Format the start and end times
  const startFormatted = formatTime(startTime)
  const endFormatted = formatDetailedTime(startTime)

  // Return the formatted string with the "Starts at" part
  return `${startFormatted} ・Starts at ${endFormatted}`
}

Quote.propTypes = {
  quote: PropTypes.object,
  index: PropTypes.number,
  setCurrentQuote: PropTypes.func,
}
