import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import toast from 'react-hot-toast'
import Uppy from '@uppy/core'
import RemoteSources from '@uppy/remote-sources'
import AwsS3 from '@uppy/aws-s3'

import { DashboardModal } from '@uppy/react'
import FirebaseStorageUpload from '../../../utils/FirebaseStorageUpload'
import { useCustomFetch } from '@/hooks/useCustomFetch'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import './importFiles.css'
import { useNavigate } from 'react-router-dom'
import { RemoteConfigContext } from '@/context/RemoteConfigContext'
import { createPortal } from 'react-dom'

const MAX_FILE_SIZE_IN_BYTES = 2 * 1024 * 1024 * 1024 // 2gb

const ALLOWED_FILE_TYPES = [
  'audio/mpeg',
  'audio/mp4',
  'audio/mp2',
  'audio/wav',
  'audio/flac',
  'audio/x-flac',
  'audio/l16',
  'audio/x-m4a',
  'audio/ogg',
  'audio/opus',
  'audio/webm',
  'video/mp4',
  'video/webm',
]

export function ImportFiles({
  projectId,
  projectName,
  handleClose,
  setMeetings,
  limitFiles = 10,
  meetingId,
  handleGoBack,
}) {
  const navigate = useNavigate()
  const customFetch = useCustomFetch()
  const { config, isLoading: remoteConfigLoading } = useContext(RemoteConfigContext)

  const [dashboardNode, setDashboardNode] = useState(null)

  const remoteSources = []
  if (!remoteConfigLoading) {
    if (config.googleDrive) remoteSources.push('GoogleDrive')
    if (config.oneDrive) remoteSources.push('OneDrive')
    if (config.zoom) remoteSources.push('Zoom')
    if (config.dropbox) remoteSources.push('Dropbox')
    if (config.box) remoteSources.push('Box')
  }

  const [uppy] = useState(() => {
    const newUppy = new Uppy({
      autoProceed: false,
      debug: true,
      restrictions: {
        maxFileSize: MAX_FILE_SIZE_IN_BYTES,
        maxNumberOfFiles: limitFiles || 10,
        allowedFileTypes: ALLOWED_FILE_TYPES,
      },
    })
      .use(RemoteSources, {
        companionUrl: import.meta.env.VITE_COMPANION_URL,
        sources: remoteSources,
      })
      .use(FirebaseStorageUpload)

    newUppy.on('file-added', (file) => {
      if (file.source && ['GoogleDrive', 'Zoom', 'Dropbox', 'OneDrive'].includes(file.source)) {
        newUppy.use(AwsS3, {
          endpoint: import.meta.env.VITE_COMPANION_URL,
        })
      } else {
        newUppy.removePlugin(AwsS3)
      }
    })

    return newUppy
  })

  useEffect(() => {
    uppy.on('complete', async (result) => {
      try {
        // console.log('Successful uploads:', result.successful)
        if (result.successful.length) {
          const files = []
          for (const file of result.successful) {
            if (file.isRemote) {
              let url = ''
              try {
                const res = await customFetch(`/getMeetUrl/${param.meetingId}`, 'GET', null)
                if (res.error) throw res
                url = res.url
              } catch (error) {
                console.error('Error getting file URL:', error)
                continue
              }
              const duration = await new Promise((resolve, reject) => {
                const mediaElement = document.createElement(file.type.startsWith('audio') ? 'audio' : 'video')
                mediaElement.src = url
                mediaElement.addEventListener('loadedmetadata', () => {
                  resolve(mediaElement.duration) // duration in seconds
                })
                mediaElement.addEventListener('error', reject)
              })
              const fileLocation = file?.response?.body?.Key
              // removing folder meets/ to get only the file name
              const storedFileName = fileLocation.substring(6)

              files.push({ url, fileName: file.name, size: file.size, storedFileName, duration, type: file.type })
            } else {
              files.push({
                url: file.uploadURL,
                fileName: file.name,
                size: file.size,
                storedFileName: file.storedFileName,
                duration: file.duration,
                type: file.type,
              })
            }
          }
          if (!files.length) return

          const start = Date.now()
          const res = await customFetch('/createNotesFromFiles', 'POST', {
            projectId,
            projectName,
            files: JSON.stringify(files),
            meetingId,
          })
          if (res.error) {
            toast.error(res.error.error.message)
            handleClose()
            return
          }
          if (!res.meetings.length) {
            toast.error('Whoops! Something went wrong. Please try again.')
            handleClose()
            return
          }

          toast.success('Files uploaded! We’ll let you know when the notes are ready. You may close your browser.', {
            duration: 3000,
          })
          // the sorting is added just in case there is an event with a future meet that should go first
          if (setMeetings && !meetingId) {
            setMeetings((prevMeetings) =>
              [...res.meetings, ...prevMeetings].sort((a, b) => {
                let timeA = a.created_at
                let timeB = b.created_at
                if (a.start) {
                  timeA = new Date(a.start).getTime()
                }
                if (b.start) {
                  timeB = new Date(b.start).getTime()
                }
                return timeA < timeB ? 1 : -1
              })
            )
          }
          if (setMeetings && meetingId) {
            setMeetings((prevMeetings) =>
              prevMeetings
                .map((meet) => (meet.id === meetingId ? res.meetings[0] : meet))
                .sort((a, b) => {
                  let timeA = a.created_at
                  let timeB = b.created_at
                  if (a.start) {
                    timeA = new Date(a.start).getTime()
                  }
                  if (b.start) {
                    timeB = new Date(b.start).getTime()
                  }
                  return timeA < timeB ? 1 : -1
                })
            )
          }
          const elapsed = Date.now() - start
          if (elapsed < 3000) {
            await new Promise((resolve) => setTimeout(resolve, 3000 - elapsed))
          }
          navigate(`/project/${projectId}`)
          handleClose()
        }
      } catch (error) {
        console.error('Error uploading files', error)
        toast.error('Whoops! Something went wrong. Please try again.')
        handleClose()
      }
    })

    return () => uppy.destroy()
  }, [])

  useEffect(() => {
    // Dynamically find the node with the class `uppy-Dashboard-AddFiles`
    const node = document.querySelector('.uppy-Dashboard-AddFiles')
    if (node) {
      setDashboardNode(node)
    }
  }, [])

  const isOnlyOne = limitFiles === 1

  const text = isOnlyOne ? 'Upload one file at a time up to 2GB' : `Upload up to ${limitFiles} files, each up to 2GB`

  return (
    <>
      {handleGoBack &&
        dashboardNode &&
        createPortal(
          <button
            className="absolute top-4 right-4 mr-[18px] px-4 py-2.5 rounded-[100px] shadow-inner border border-pink justify-center items-center gap-3 inline-flex"
            onClick={handleGoBack}
          >
            <p className="text-pink text-base font-semibold font-['Manrope'] leading-tight">Go back</p>
          </button>,
          dashboardNode
        )}
      <DashboardModal
        uppy={uppy}
        open={true}
        target={document.body} // Ensure this matches the portal target
        note={`${text}. Acceptable file types: MP3, MP4, MP2, WAV, FLAC, PCM, M4A, Ogg, Opus, WebM.`}
        plugins={remoteSources}
        onRequestClose={() => handleClose(false)}
      />
    </>
  )
}

ImportFiles.propTypes = {
  projectId: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  setMeetings: PropTypes.func,
  limitFiles: PropTypes.number,
  meetingId: PropTypes.string,
}
