/* eslint-disable react/no-unknown-property */
import React, { useContext, useState } from 'react'
import { ProjectContext } from '../../../../context/ProjectContext'
import QuotationMarkIcon from '../../../icons/QuotationMark'
import thumbnailDefault from '../../../../assets/audio-thumbnail.png'
import PropTypes from 'prop-types'

export function Quote({ quote, index, setCurrentQuote, label }) {
  const { participants, sessions } = useContext(ProjectContext)

  return (
      <div
        className="relative w-[280px] p-[14px] bg-white rounded-sm shadow border border-[#e4e4e4] cursor-pointer flex flex-col justify-between"
        style={{ boxShadow: '0px 0px 22.399999618530273px rgba(14.96, 21.41, 55.25, 0.05)' }}
        onClick={() => setCurrentQuote(index)}
      >
        <div>
          <p className="text-[#62708d] text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">{label}</p>
          <p className="mt-[8px] text-[#3f424a] text-lg font-bold font-['Manrope'] leading-tight tracking-wide">{quote.content}</p>

          <div className="mt-[17px] relative w-[206.96px] h-[117.06px] bg-black/60 rounded-sm border border-[#3f424a]">
            <img
              className={`w-full h-full rounded ${sessions.find((session) => session.id === quote.meetingId)?.thumbnailUrl ? 'opacity-70' : ''}`}
              src={
                quote.thumbnailUrl ||
                sessions.find((session) => session.id === quote.meetingId)?.thumbnailUrl ||
                thumbnailDefault
              }
              alt="quote"
            />
            <div className="absolute" style={{ top: 'calc(50% - 19px)', left: 'calc(50% - 19px)' }}>
              <svg width="37" height="38" viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ellipse cx="18.7314" cy="18.8159" rx="18.1982" ry="18.2905" fill="white" />
                <path
                  d="M27.2122 18.3131C27.5308 18.5719 27.5308 19.0582 27.2122 19.317L15.236 29.0464C14.8133 29.3897 14.1814 29.089 14.1814 28.5444L14.1814 9.0857C14.1814 8.54114 14.8133 8.24037 15.236 8.58373L27.2122 18.3131Z"
                  fill="black"
                />
              </svg>
            </div>
            <div className="absolute bottom-0 right-0 w-[42px] h-[23px] px-1.5 py-[5px] bg-black/60 rounded-[3px] justify-center items-center gap-2.5 inline-flex">
              <div className="text-white text-[10px] font-bold font-['Manrope'] leading-3">
                {quote.end_time <= quote.start_time ? '1.00' : (quote.end_time - quote.start_time).toFixed(2)}
              </div>
            </div>
          </div>

          <p className="mt-[10px] text-[#3f424a] text-xs font-medium font-['Manrope'] leading-[13.80px]">
            “
            {quote.original_transcript_segment.length > 380
              ? quote.original_transcript_segment.slice(0, 380) + '...'
              : quote.original_transcript_segment}
            ”
          </p>

        </div>

        <div className="mt-[17px] flex justify-between items-center">
          <p className="text-[#3f424a] text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">
            {quote.participant_id && participants[quote.participant_id]
              ? participants[quote.participant_id]
              : quote.who_said_it}
          </p>
          <QuotationMarkIcon />
        </div>
      </div>
  )
}

Quote.propTypes = {
  quote: PropTypes.object,
  index: PropTypes.number,
  setCurrentQuote: PropTypes.func,
  label: PropTypes.string,
}
