import React, { useEffect, useState } from 'react'
import NoProjects from '../../../assets/no_projects_yet.png'
import { useCustomFetch } from '../../../hooks/useCustomFetch'
import { format } from 'date-fns'

export function Chats() {
  const [conversations, setConversations] = useState([])
  const [loadingChat, setLoadingChat] = useState(false)
  const customFetch = useCustomFetch()

  useEffect(() => {
    const fetchChannels = async () => {
      try {
        setLoadingChat(true)
        const result = await customFetch('/slackChats', 'GET')
        console.log(result)
        setConversations(result)
        setLoadingChat(false)
      } catch (error) {
        console.error(error)
      }
    }
    fetchChannels()
  }, [])

  return (
    <div className="ml-4 mt-[31px] flex flex-col gap-[37px]">
      <p className="text-[#3f424a] text-[26px] font-semibold font-['Manrope'] leading-[29.90px]">Chats</p>
      {!loadingChat && conversations.length === 0 && (
        <div className="flex justify-center items-center my-11">
          <div className="flex items-center gap-[36px]">
            <div className="w-[441px]">
              <p className="text-[#676d7f] text-xl font-bold font-['Manrope'] leading-[23px]">No Chats Yet</p>
              <p className="w-[420px] mt-6 text-[#676d7f] text-base font-normal font-['Manrope'] leading-[23px]">
                Add one now by integrating Slack in the in Integrations page
              </p>
            </div>
            <img className="w-[134px] h-[84px] mt-5 mx-auto" src={NoProjects} alt="No upcoming projects" />
          </div>
        </div>
      )}
      {!loadingChat &&
        (conversations || []).map(({ date, channelName, channelId, participants, messageCount, startTime, endTime }) => (
          <div
            key={`${date}-${channelId}`}
            className="mx-8 my-2 p-4 flex items-center overflow-x-auto border-b-2 border-grey-200"
          >
            {/* Logo and Date Section */}
            <div className="flex items-center w-3/5 cursor-pointer">
              <div className="w-1/6">
                <SlackIcon />
              </div>
              <div className="ml-4 flex-1">
                <p className="text-zinc text-base font-semibold font-['Manrope'] truncate">
                  {date} - {channelName.length > 18 ? channelName.substring(0, 18) + '...' : channelName}
                </p>
                <p className="text-slate text-base font-normal font-['Manrope']">
                  {formatDateWithTime(new Date(startTime), new Date(endTime))}
                </p>
              </div>
            </div>

            {/* Slack Channel Name */}
            <div className="w-1/5 flex items-center justify-center">
              <Chip className="py-1 px-2">
                <MiniSlackIcon />
                <span className="ml-1 text-zinc text-base font-semibold font-['Manrope'] truncate">{channelName}</span>
              </Chip>
            </div>

            {/* Participants Count */}
            <div className="w-1/6 flex items-center justify-center">
              <Chip className="py-1 px-2">
                <span className="text-zinc text-base font-semibold font-['Manrope']">{participants || 0}</span>
                <PersonIcon className="ml-1" />
              </Chip>
            </div>

            {/* Messages Count */}
            <div className="w-1/6 flex items-center justify-center">
              <Chip className="py-1 px-2">
                <span className="text-zinc text-base font-semibold font-['Manrope']">{messageCount || 0}</span>
                <ChatIcon className="ml-1" />
              </Chip>
            </div>
          </div>
        ))}
    </div>
  )
}

function formatDateWithTime(startDate, endDate) {
  const ordinalSuffix = (day) => {
    if (day > 3 && day < 21) return 'th' // Special case for 11-19
    switch (day % 10) {
      case 1:
        return 'st'
      case 2:
        return 'nd'
      case 3:
        return 'rd'
      default:
        return 'th'
    }
  }

  const formattedDate = format(startDate, 'MMMM do, yyyy').replace(
    /(\d{1,2})/,
    (day) => `${day}${ordinalSuffix(Number(day))}`,
  )

  const formattedStartTime = format(startDate, 'h:mm a').toLowerCase()
  const formattedEndTime = format(endDate, 'h:mm a').toLowerCase()

  return `${formattedDate}・${formattedStartTime} -- ${formattedEndTime}`
}

const Chip = ({ children }) => {
  return (
    <div
      className="flex flex-row justify-center items-center p-[7px_10px] gap-[3px] border border-[#E4E4E4] rounded-full">
      {children}
    </div>
  )
}

function MiniSlackIcon() {
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.29996 2.64996L6.64996 3.22496L6.29996 2.14996C6.14996 1.69996 6.37496 1.19996 6.84996 1.04996C7.29996 0.89996 7.79996 1.12496 7.94996 1.59996L8.29996 2.64996ZM7.79996 6.64996L9.44996 6.07496L8.87496 4.29996L7.22496 4.87496L7.79996 6.64996ZM8.64996 9.19996C8.77496 9.54996 9.12496 9.79996 9.47496 9.79996C9.57496 9.79996 9.67496 9.77496 9.74996 9.74996C10.2 9.59996 10.45 9.09996 10.3 8.64996L9.99996 7.74996L8.34996 8.32496L8.64996 9.19996Z"
        fill="#FFB300"
      />
      <path
        d="M4.79996 3.87496L3.14996 4.44996L2.79996 3.39996C2.64996 2.94996 2.87496 2.44996 3.34996 2.29996C3.79996 2.14996 4.29996 2.37496 4.44996 2.84996L4.79996 3.87496ZM5.14996 10.45C5.27496 10.8 5.62496 11.05 5.97496 11.05C6.07496 11.05 6.17496 11.025 6.24996 11C6.69996 10.85 6.94996 10.35 6.79996 9.89996L6.49996 8.97496L4.84996 9.54996L5.14996 10.45ZM5.34996 5.54996L3.69996 6.12496L4.27496 7.89996L5.92496 7.32496L5.34996 5.54996Z"
        fill="#00BFA5"
      />
      <path
        d="M8.84996 4.32535L8.29996 2.67535L9.32496 2.32535C9.77496 2.17535 10.275 2.40035 10.425 2.87535C10.575 3.32535 10.35 3.82535 9.87496 3.97535L8.84996 4.32535ZM7.19996 4.90035L6.64996 3.25035L4.79996 3.90035L5.34996 5.55035L7.19996 4.90035ZM2.09996 4.82535C1.64996 4.97535 1.39996 5.47535 1.54996 5.92535C1.67496 6.30035 2.02496 6.52535 2.37496 6.52535C2.47496 6.52535 2.57496 6.50035 2.64996 6.47535L3.67496 6.12535L3.12496 4.47535L2.09996 4.82535Z"
        fill="#00BCD4"
      />
      <path
        d="M4.27496 7.87408L4.82496 9.52408L3.64996 9.92408C3.54996 9.94908 3.44996 9.97408 3.37496 9.97408C2.99996 9.97408 2.67496 9.74908 2.54996 9.37408C2.39996 8.92408 2.62496 8.42408 3.09996 8.27408L4.27496 7.87408ZM11.425 6.32408C11.275 5.87408 10.775 5.62408 10.325 5.77408L9.44996 6.07408L9.99996 7.74908L10.9 7.44908C11.35 7.27408 11.6 6.77408 11.425 6.32408ZM5.92496 7.29908L6.47496 8.94908L8.32496 8.29908L7.77496 6.64908L5.92496 7.29908Z"
        fill="#E91E63"
      />
      <path d="M8.8499 4.32539L8.2999 2.65039L6.6499 3.22539L7.1999 4.90039L8.8499 4.32539Z" fill="#388E3C" />
      <path d="M4.80039 3.875L3.15039 4.45L3.70039 6.125L5.35039 5.55L4.80039 3.875Z" fill="#00897B" />
      <path d="M7.7998 6.64922L8.3498 8.32422L9.9998 7.74922L9.4498 6.07422L7.7998 6.64922Z" fill="#BF360C" />
      <path d="M4.2749 7.87383L4.8249 9.54883L6.4749 8.97383L5.9249 7.29883L4.2749 7.87383Z" fill="#4E342E" />
    </svg>
  )
}

function ChatIcon() {
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.49951 4.5H8.49951" stroke="#40424A" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M4.49951 6.5H7.49951" stroke="#40424A" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M9.5 2C9.89782 2 10.2794 2.15804 10.5607 2.43934C10.842 2.72064 11 3.10218 11 3.5V7.5C11 7.89782 10.842 8.27936 10.5607 8.56066C10.2794 8.84196 9.89782 9 9.5 9H7L4.5 10.5V9H3.5C3.10218 9 2.72064 8.84196 2.43934 8.56066C2.15804 8.27936 2 7.89782 2 7.5V3.5C2 3.10218 2.15804 2.72064 2.43934 2.43934C2.72064 2.15804 3.10218 2 3.5 2H9.5Z"
        stroke="#40424A"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

function SlackIcon() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.9999 8.83255L20.4999 10.7492L19.3332 7.16588C18.8332 5.66588 19.5832 3.99922 21.1665 3.49922C22.6665 2.99922 24.3332 3.74922 24.8332 5.33255L25.9999 8.83255ZM24.3332 22.1659L29.8332 20.2492L27.9165 14.3325L22.4165 16.2492L24.3332 22.1659ZM27.1665 30.6659C27.5832 31.8325 28.7499 32.6659 29.9165 32.6659C30.2499 32.6659 30.5832 32.5825 30.8332 32.4992C32.3332 31.9992 33.1665 30.3325 32.6665 28.8325L31.6665 25.8325L26.1665 27.7492L27.1665 30.6659Z"
        fill="#FFB300"
      />
      <path
        d="M14.3334 12.9172L8.83336 14.8338L7.6667 11.3339C7.1667 9.83385 7.9167 8.16718 9.50003 7.66718C11 7.16718 12.6667 7.91718 13.1667 9.50052L14.3334 12.9172ZM15.5 34.8338C15.9167 36.0005 17.0834 36.8338 18.25 36.8338C18.5834 36.8338 18.9167 36.7505 19.1667 36.6672C20.6667 36.1672 21.5 34.5005 21 33.0005L20 29.9172L14.5 31.8338L15.5 34.8338ZM16.1667 18.5005L10.6667 20.4172L12.5834 26.3338L18.0834 24.4172L16.1667 18.5005Z"
        fill="#00BFA5"
      />
      <path
        d="M27.8337 14.4178L26.0003 8.91783L29.417 7.75117C30.917 7.25117 32.5837 8.00117 33.0837 9.5845C33.5837 11.0845 32.8337 12.7512 31.2503 13.2512L27.8337 14.4178ZM22.3337 16.3345L20.5003 10.8345L14.3337 13.0012L16.167 18.5012L22.3337 16.3345ZM5.33368 16.0845C3.83368 16.5845 3.00034 18.2512 3.50034 19.7512C3.91701 21.0012 5.08367 21.7512 6.25034 21.7512C6.58367 21.7512 6.91701 21.6678 7.16701 21.5845L10.5837 20.4178L8.75034 14.9178L5.33368 16.0845Z"
        fill="#00BCD4"
      />
      <path
        d="M12.5837 26.2489L14.417 31.7489L10.5004 33.0822C10.167 33.1655 9.83369 33.2489 9.58369 33.2489C8.33369 33.2489 7.25035 32.4989 6.83369 31.2489C6.33369 29.7489 7.08369 28.0822 8.66702 27.5822L12.5837 26.2489ZM36.417 21.0822C35.917 19.5822 34.2504 18.7489 32.7504 19.2489L29.8337 20.2489L31.667 25.8322L34.667 24.8322C36.167 24.2489 37.0004 22.5822 36.417 21.0822ZM18.0837 24.3322L19.917 29.8322L26.0837 27.6655L24.2504 22.1655L18.0837 24.3322Z"
        fill="#E91E63"
      />
      <path d="M27.8333 14.4154L26 8.83203L20.5 10.7487L22.3333 16.332L27.8333 14.4154Z" fill="#388E3C" />
      <path d="M14.3335 12.918L8.8335 14.8346L10.6668 20.418L16.1668 18.5013L14.3335 12.918Z" fill="#00897B" />
      <path d="M24.333 22.1667L26.1663 27.75L31.6663 25.8333L29.833 20.25L24.333 22.1667Z" fill="#BF360C" />
      <path d="M12.5835 26.2507L14.4168 31.834L19.9168 29.9173L18.0835 24.334L12.5835 26.2507Z" fill="#4E342E" />
    </svg>
  )
}

function PersonIcon() {
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_7229_53658)">
        <path
          d="M4.5 3.5C4.5 4.03043 4.71071 4.53914 5.08579 4.91421C5.46086 5.28929 5.96957 5.5 6.5 5.5C7.03043 5.5 7.53914 5.28929 7.91421 4.91421C8.28929 4.53914 8.5 4.03043 8.5 3.5C8.5 2.96957 8.28929 2.46086 7.91421 2.08579C7.53914 1.71071 7.03043 1.5 6.5 1.5C5.96957 1.5 5.46086 1.71071 5.08579 2.08579C4.71071 2.46086 4.5 2.96957 4.5 3.5Z"
          stroke="black"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.5 10.5V9.5C3.5 8.96957 3.71071 8.46086 4.08579 8.08579C4.46086 7.71071 4.96957 7.5 5.5 7.5H7.5C8.03043 7.5 8.53914 7.71071 8.91421 8.08579C9.28929 8.46086 9.5 8.96957 9.5 9.5V10.5"
          stroke="black"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7229_53658">
          <rect width="12" height="12" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
