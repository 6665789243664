import React, { useContext, useRef, useState } from 'react'
import { ProjectContext } from '../../../../context/ProjectContext'
import useOutsideClick from '../../../../hooks/useOutsideClick'
import cnt from '../../../../constants'
import PropTypes from 'prop-types'
import { useCustomFetch } from '../../../../hooks/useCustomFetch'

export function Method () {
  const { project, setProject } = useContext(ProjectContext)
  const [openMenu, setOpenMenu] = useState(false)
  const methodSelectorRef = useRef(null)
  const customFetch = useCustomFetch()

  useOutsideClick(methodSelectorRef, () => {
    if (methodSelectorRef) {
      setOpenMenu(false)
    }
  })

  async function handleChangeMethod (method) {
    setProject(prevProject => ({ ...prevProject, method }))
    setOpenMenu(false)
    await customFetch('/updateProjectMethod', 'PUT', { projectId: project.id, method })
  }

  return (
    <div className='pb-[20px] border-b-2 border-grey-200'>
      <p className="text-[#3f424a] text-[22px] font-bold font-['DM_Sans'] leading-relaxed">Method</p>
      <p className="mb-[16px] text-[#62708d] text-base font-medium font-['Manrope'] leading-[23px]">Select a research method.</p>

      <div className='mt-[8px] w-[316px] h-9 py-1 bg-white rounded shadow border border-grey-200 relative' ref={methodSelectorRef}>
        <button className='mx-2 flex items-center w-full justify-between' onClick={() => setOpenMenu(!openMenu)}>
          <div className='flex items-center'>
            <MethodIcon method={project.method} />
            <p className="ml-[8px] text-[#40424A] text-base font-semibold font-['Manrope'] leading-tight">{cnt.methods[project.method]}</p>
          </div>
          <div className='mr-[8px]'>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_3800_1553)">
              <circle cx="14" cy="14" r="10.5" fill="#EBEBEB"/>
              <path d="M17.5 12.834L14 16.334L10.5 12.834" stroke="#40424A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </g>
              <defs>
              <clipPath id="clip0_3800_1553">
              <rect width="28" height="28" fill="white"/>
              </clipPath>
              </defs>
            </svg>
          </div>
        </button>
        {openMenu && (
          <div className="absolute w-[316px] left-[-0.5px] top-8 p-2 bg-white shadow rounded-b border-x border-grey-200 z-10">
            {Object.keys(cnt.methods).map(method => (
              <button key={method} className="py-1 flex items-center gap-[8px] my-[5px]" onClick={() => handleChangeMethod(method)}>
                <MethodIcon method={method} />
                {cnt.methods[method]}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

function MethodIcon ({ method }) {
  if (method === 'user_interviews') {
    return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.66797 5.83333C6.66797 6.71739 7.01916 7.56523 7.64428 8.19036C8.2694 8.81548 9.11725 9.16667 10.0013 9.16667C10.8854 9.16667 11.7332 8.81548 12.3583 8.19036C12.9834 7.56523 13.3346 6.71739 13.3346 5.83333C13.3346 4.94928 12.9834 4.10143 12.3583 3.47631C11.7332 2.85119 10.8854 2.5 10.0013 2.5C9.11725 2.5 8.2694 2.85119 7.64428 3.47631C7.01916 4.10143 6.66797 4.94928 6.66797 5.83333Z" stroke="#FFC921" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5 17.5V15.8333C5 14.9493 5.35119 14.1014 5.97631 13.4763C6.60143 12.8512 7.44928 12.5 8.33333 12.5H11.6667C12.5507 12.5 13.3986 12.8512 14.0237 13.4763C14.6488 14.1014 15 14.9493 15 15.8333V17.5" stroke="#FFC921" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    )
  }
  if (method === 'user_testing') {
    return (
      <svg width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2449_10287)">
        <path d="M25.999 18C25.999 17.4696 26.2097 16.9609 26.5848 16.5858C26.9599 16.2107 27.4686 16 27.999 16H39.999C40.5295 16 41.0382 16.2107 41.4132 16.5858C41.7883 16.9609 41.999 17.4696 41.999 18V38C41.999 38.5304 41.7883 39.0391 41.4132 39.4142C41.0382 39.7893 40.5295 40 39.999 40H27.999C27.4686 40 26.9599 39.7893 26.5848 39.4142C26.2097 39.0391 25.999 38.5304 25.999 38V18Z" stroke="#FF4A8E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M35.999 16V10C35.999 9.46957 35.7883 8.96086 35.4132 8.58579C35.0382 8.21071 34.5295 8 33.999 8H7.99902C7.46859 8 6.95988 8.21071 6.58481 8.58579C6.20974 8.96086 5.99902 9.46957 5.99902 10V34C5.99902 34.5304 6.20974 35.0391 6.58481 35.4142C6.95988 35.7893 7.46859 36 7.99902 36H25.999" stroke="#FF4A8E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M31.999 18H35.999" stroke="#FF4A8E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_2449_10287">
        <rect width="48" height="48" fill="white" transform="translate(-0.000976562)"/>
        </clipPath>
        </defs>
      </svg>
    )
  }
  if (method === 'focus_group') {
    return (
      <svg width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2449_10279)">
        <path d="M20.001 26C20.001 27.0609 20.4224 28.0783 21.1726 28.8284C21.9227 29.5786 22.9401 30 24.001 30C25.0618 30 26.0793 29.5786 26.8294 28.8284C27.5795 28.0783 28.001 27.0609 28.001 26C28.001 24.9391 27.5795 23.9217 26.8294 23.1716C26.0793 22.4214 25.0618 22 24.001 22C22.9401 22 21.9227 22.4214 21.1726 23.1716C20.4224 23.9217 20.001 24.9391 20.001 26Z" stroke="#34AED7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16.001 42V40C16.001 38.9391 16.4224 37.9217 17.1726 37.1716C17.9227 36.4214 18.9401 36 20.001 36H28.001C29.0618 36 30.0793 36.4214 30.8294 37.1716C31.5795 37.9217 32.001 38.9391 32.001 40V42" stroke="#34AED7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M30.001 10C30.001 11.0609 30.4224 12.0783 31.1726 12.8284C31.9227 13.5786 32.9401 14 34.001 14C35.0618 14 36.0793 13.5786 36.8294 12.8284C37.5795 12.0783 38.001 11.0609 38.001 10C38.001 8.93913 37.5795 7.92172 36.8294 7.17157C36.0793 6.42143 35.0618 6 34.001 6C32.9401 6 31.9227 6.42143 31.1726 7.17157C30.4224 7.92172 30.001 8.93913 30.001 10Z" stroke="#34AED7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M34.001 20H38.001C39.0618 20 40.0793 20.4214 40.8294 21.1716C41.5795 21.9217 42.001 22.9391 42.001 24V26" stroke="#34AED7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.001 10C10.001 11.0609 10.4224 12.0783 11.1725 12.8284C11.9227 13.5786 12.9401 14 14.001 14C15.0618 14 16.0793 13.5786 16.8294 12.8284C17.5795 12.0783 18.001 11.0609 18.001 10C18.001 8.93913 17.5795 7.92172 16.8294 7.17157C16.0793 6.42143 15.0618 6 14.001 6C12.9401 6 11.9227 6.42143 11.1725 7.17157C10.4224 7.92172 10.001 8.93913 10.001 10Z" stroke="#34AED7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.00098 26V24C6.00098 22.9391 6.4224 21.9217 7.17255 21.1716C7.92269 20.4214 8.94011 20 10.001 20H14.001" stroke="#34AED7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_2449_10279">
        <rect width="48" height="48" fill="white" transform="translate(0.000976562)"/>
        </clipPath>
        </defs>
      </svg>
    )
  }
  if (method === 'mixed_methods') {
    return (
      <svg width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2449_10296)">
        <path d="M36 8L42 14L36 20" stroke="#D1EC26" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M36 40L42 34L36 28" stroke="#D1EC26" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6 14H12C14.6522 14 17.1957 15.0536 19.0711 16.9289C20.9464 18.8043 22 21.3478 22 24C22 26.6522 23.0536 29.1957 24.9289 31.0711C26.8043 32.9464 29.3478 34 32 34H42" stroke="#D1EC26" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M42 14H32C29.8358 13.9968 27.7295 14.6989 26 16M18 32C16.2701 33.3003 14.1641 34.0023 12 34H6" stroke="#D1EC26" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_2449_10296">
        <rect width="48" height="48" fill="white"/>
        </clipPath>
        </defs>
      </svg>
    )
  }
}

MethodIcon.propTypes = {
  method: PropTypes.string
}
