import React, { useContext, useEffect } from 'react'
import { Categories } from './categories'
import { Sessions } from './sessions'
import { ProjectContext } from '../../../context/ProjectContext'

export function Sidebar({ transcriptsView = false }) {
  const { groupNotesBy, sidebarScrollContainer } = useContext(ProjectContext)

  useEffect(() => {
    if (!sidebarScrollContainer) return

    sidebarScrollContainer.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [groupNotesBy, sidebarScrollContainer])

  if (transcriptsView) {
    return <Sessions />
  }

  return groupNotesBy === 'meeting' ? <Sessions /> : <Categories />
}
