import { BasePlugin } from '@uppy/core'
import { storage } from '../firebase'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { v4 as uuidv4 } from 'uuid'
import {
  filterNonFailedFiles,
  filterFilesToEmitUploadStarted
} from '@uppy/utils/lib/fileFilters'

export default class FirebaseStorageUpload extends BasePlugin {
  constructor (uppy, opts) {
    super(uppy, opts)
    this.id = opts?.id || 'FirebaseStorageUpload'
    this.type = 'uploader'
    this.handleUpload = this.handleUpload.bind(this)
  }

  async handleUpload (fileIDs) {
    const files = this.uppy.getFiles(fileIDs)
    const filesFiltered = filterNonFailedFiles(files)
    const filesToEmit = filterFilesToEmitUploadStarted(filesFiltered)
    this.uppy.emit('upload-start', filesToEmit)

    const promises = files.filter(file => !file.isRemote).map(file => {
      return new Promise(resolve => {
        // Create a URL for the file
        const audioUrl = URL.createObjectURL(file.data)

        // Create a temporary audio element
        const audioElement = document.createElement('audio')
        audioElement.src = audioUrl

        // Extract duration
        audioElement.addEventListener('loadedmetadata', () => {
          const duration = audioElement.duration
          URL.revokeObjectURL(audioUrl)
          resolve(duration) // Resolve the duration for further use
        })
      }).then(duration => {
        const storedFileName = uuidv4() + '.' + file.extension
        console.log('Stored file name:', storedFileName)
        const storageRef = ref(storage, `meets/${storedFileName}`)
        const uploadTask = uploadBytesResumable(storageRef, file.data)

        return new Promise((resolve, reject) => {
          uploadTask.on('state_changed',
            (snapshot) => {
              // Observe state change events such as progress
              // const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              // Update Uppy's file progress for the current file
              this.uppy.emit('upload-progress', file, {
                uploader: this,
                bytesUploaded: snapshot.bytesTransferred,
                bytesTotal: snapshot.totalBytes
              })
              // console.log('Upload is ' + progress + '% done')
            },
            (error) => {
              // Handle unsuccessful uploads
              this.uppy.emit('upload-error', file, error)
              console.error(error)
              reject(error)
            },
            async () => {
              // Handle successful uploads on complete
              const response = await fetchMeetUrl(`/getMeetUrlByStoredName/${storedFileName}`)
              console.log('File available at', response)
              this.uppy.emit('upload-success', file, response.url)
              // Update Uppy's file state with the download URL
              this.uppy.setFileState(file.id, {
                uploadURL: response.url,
                storedFileName,
                duration
              })
              resolve(file)
            }
          )
        })
      })
    })

    return Promise.all(promises)
  }

  install () {
    this.uppy.addUploader(this.handleUpload)
  }

  uninstall () {
    this.uppy.removeUploader(this.handleUpload)
  }
}

async function fetchMeetUrl (urlPath) {
  try {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('access_token'),
        signinmethod: localStorage.getItem('signin_method'),
      },
    }

    const response = await fetch(`${import.meta.env.VITE_SERVER_URL}/api${urlPath}`, options)

    if (response.status === 401) {
      console.error('Error fetching: unauthorized')
      return
    }
    if (!response.ok) throw await response.json()

    return await response.json()
  } catch (error) {
    console.error('Error fetching:', error)
    return { error }
  }
}
