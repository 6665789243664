import React, { useRef, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { MeetingsContext } from '../../../context/MeetingContext'
import defaultAvatar from '../../../assets/default_avatar.png'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
  DropdownMenuGroup,
} from '@/components/ui/dropdown-menu'
import { Input } from "@/components/ui/input"

export const AssignParticipant = ({ handleChooseExistingParticipant, handleChooseNewParticipant }) => {
  const { participants } = useContext(MeetingsContext)
  const [newParticipant, setNewParticipant] = useState(false)
  const [participantName, setParticipantName] = useState('')
  const participantRef = useRef(null)

  function setNewParticipantName() {
    setNewParticipant(false)
    if (participantName) handleChooseNewParticipant(participantName)
  }

  return (
    <>
      <Input
        ref={participantRef}
        type='text'
        className={`focus-visible:ring-0 w-full mr-2 bg-grey-100 text-zinc text-xl font-bold font-['Manrope'] ${!newParticipant && 'hidden'}`}
        value={participantName}
        onChange={e => setParticipantName(e.target.value)}
        onKeyDown={e => e.key === 'Enter' && setNewParticipantName()}
        onBlur={() => setNewParticipantName()}
        autoFocus
      />
      <DropdownMenu>
        <DropdownMenuTrigger>
          <button className={`bg-white py-[4px] px-[6px] rounded-[5px] border border-[#e4e4e4] flex items-center gap-[6px] ${newParticipant && 'hidden'}`}>
            <img className='h-[16px] w-[16px] rounded-full' src={defaultAvatar} alt="user avatar" />
            <p className="text-[#3f424a] text-[13px] font-bold font-['Manrope'] leading-none">Unassigned</p>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="6" cy="6" r="6" transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 14 2)" fill="#EBEBEB"/>
              <path d="M6 7.33398L8 9.33398L10 7.33398" stroke="#40424A" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className='border-white p-0' onCloseAutoFocus={(e) => {
          e.preventDefault()
          participantRef.current.focus()
        }}>
          <DropdownMenuGroup>
            {Object.keys(participants).map((participantId) => (
              <DropdownMenuItem key={participantId}>
                <button className='flex' onClick={() => handleChooseExistingParticipant(participantId)}>
                  <p className="text-zinc font-semibold font-['Manrope']">{participants[participantId].name}</p>
                </button>
              </DropdownMenuItem>
            ))}
          </DropdownMenuGroup>
          <DropdownMenuSeparator className='bg-grey-200' />
          <DropdownMenuItem>
            <button className='flex' onClick={() => setNewParticipant(true)}>
              <p className="text-zinc font-semibold font-['Manrope']">New participant</p>
            </button>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  )
}

AssignParticipant.propTypes = {
  handleChooseExistingParticipant: PropTypes.func.isRequired,
  handleChooseNewParticipant: PropTypes.func.isRequired,
}
