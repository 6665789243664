import React, { useContext } from 'react'
import { MeetingsContext } from '@/context/MeetingContext'

export function Menu() {
  const { scrollToMenu, setScrollToMenu, feedbacks, problems, quotes, needs, qas } = useContext(MeetingsContext)

  return (
    <div className="bg-white pt-[35px] w-[75px] h-full flex-col justify-start items-start inline-flex border-r border-r-[#e4e4e4] relative">
      <button
        className="w-full h-[75px] flex flex-col justify-center items-center gap-[9px]"
        onClick={() => setScrollToMenu('overview')}
      >
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_4179_19193)">
            <path
              d="M10.4985 13C10.4985 13.5304 10.7092 14.0391 11.0843 14.4142C11.4594 14.7893 11.9681 15 12.4985 15C13.029 15 13.5377 14.7893 13.9127 14.4142C14.2878 14.0391 14.4985 13.5304 14.4985 13C14.4985 12.4696 14.2878 11.9609 13.9127 11.5858C13.5377 11.2107 13.029 11 12.4985 11C11.9681 11 11.4594 11.2107 11.0843 11.5858C10.7092 11.9609 10.4985 12.4696 10.4985 13Z"
              stroke={scrollToMenu === 'overview' ? '#FF4A8E' : '#40424A'}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.9482 11.55L15.9982 9.5"
              stroke={scrollToMenu === 'overview' ? '#FF4A8E' : '#40424A'}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.9 19.9987C5.43815 18.8368 4.37391 17.2489 3.85478 15.4552C3.33564 13.6614 3.38732 11.7506 4.00265 9.9875C4.61797 8.22442 5.76647 6.6964 7.28899 5.61519C8.8115 4.53398 10.6326 3.95313 12.5 3.95312C14.3674 3.95313 16.1885 4.53398 17.711 5.61519C19.2335 6.6964 20.382 8.22442 20.9974 9.9875C21.6127 11.7506 21.6644 13.6614 21.1452 15.4552C20.6261 17.2489 19.5619 18.8368 18.1 19.9987H6.9Z"
              stroke={scrollToMenu === 'overview' ? '#FF4A8E' : '#40424A'}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_4179_19193">
              <rect width="24" height="24" fill="white" transform="translate(0.5)" />
            </clipPath>
          </defs>
        </svg>
        <p className="text-#3f424a] text-[11px] font-semibold font-['Manrope'] leading-3 tracking-wide">Overview</p>
      </button>

      {!!needs.length && (
        <button
          className="w-full h-[75px] flex flex-col justify-center items-center gap-[9px]"
          onClick={() => setScrollToMenu('needs')}
        >
          <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4179_19210)">
              <path
                d="M6.50098 4H18.501C19.0314 4 19.5401 4.21071 19.9152 4.58579C20.2903 4.96086 20.501 5.46957 20.501 6V13H15.501C14.9705 13 14.4618 13.2107 14.0868 13.5858C13.7117 13.9609 13.501 14.4696 13.501 15V20H6.50098C5.97054 20 5.46184 19.7893 5.08676 19.4142C4.71169 19.0391 4.50098 18.5304 4.50098 18V6C4.50098 5.46957 4.71169 4.96086 5.08676 4.58579C5.46184 4.21071 5.97054 4 6.50098 4Z"
                stroke={scrollToMenu === 'needs' ? '#FF4A8E' : '#40424A'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M20.501 13V13.172C20.5009 13.7024 20.2901 14.211 19.915 14.586L15.087 19.414C14.712 19.7891 14.2034 19.9999 13.673 20H13.501"
                stroke={scrollToMenu === 'needs' ? '#FF4A8E' : '#40424A'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_4179_19210">
                <rect width="24" height="24" fill="white" transform="translate(0.5)" />
              </clipPath>
            </defs>
          </svg>
          <p className="text-#3f424a] text-[11px] font-semibold font-['Manrope'] leading-3 tracking-wide">Needs</p>
        </button>
      )}

      {!!problems?.length && (
        <button
          className="w-full h-[75px] flex flex-col justify-center items-center gap-[9px]"
          onClick={() => setScrollToMenu('problems')}
        >
          <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.49996 18.4992C6.25223 18.4992 5.05561 18.0251 4.17334 17.1812C3.29107 16.3373 2.79541 15.1927 2.79541 13.9992C2.79541 12.8057 3.29107 11.6612 4.17334 10.8172C5.05561 9.97333 6.25223 9.49922 7.49996 9.49922C7.79464 8.1864 8.65672 7.0327 9.89654 6.29193C10.5104 5.92514 11.1986 5.67077 11.9217 5.54333C12.6449 5.4159 13.3888 5.4179 14.1111 5.54922C14.8333 5.68054 15.5198 5.93861 16.1312 6.3087C16.7427 6.67878 17.2672 7.15364 17.6747 7.70615C18.0823 8.25866 18.3649 8.878 18.5065 9.52882C18.6481 10.1796 18.6459 10.8492 18.5 11.4992H19.5C20.4282 11.4992 21.3185 11.868 21.9748 12.5243C22.6312 13.1807 23 14.071 23 14.9992C23 15.9275 22.6312 16.8177 21.9748 17.4741C21.3185 18.1305 20.4282 18.4992 19.5 18.4992H18.5"
              stroke={scrollToMenu === 'problems' ? '#FF4A8E' : '#40424A'}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.5 14.5L11.5 18.5H14.5L12.5 22.5"
              stroke={scrollToMenu === 'problems' ? '#FF4A8E' : '#40424A'}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="text-#3f424a] text-[11px] font-semibold font-['Manrope'] leading-3 tracking-wide">
            Pain Points
          </p>
        </button>
      )}

      {!!qas.length && (
        <button
          className="w-full h-[75px] flex flex-col justify-center items-center gap-[9px]"
          onClick={() => setScrollToMenu('qas')}
        >
          <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4179_19216)">
              <path
                d="M20.375 6.27154C21.075 6.66954 21.505 7.41454 21.5 8.21954V15.5035C21.5 16.3125 21.057 17.0585 20.342 17.4515L13.592 21.7215C13.2574 21.9053 12.8818 22.0016 12.5 22.0016C12.1182 22.0016 11.7426 21.9053 11.408 21.7215L4.658 17.4515C4.30817 17.2604 4.01612 16.9787 3.81241 16.636C3.6087 16.2933 3.5008 15.9022 3.5 15.5035V8.21854C3.5 7.40954 3.943 6.66454 4.658 6.27154L11.408 2.29154C11.7525 2.10158 12.1396 2.00195 12.533 2.00195C12.9264 2.00195 13.3135 2.10158 13.658 2.29154L20.408 6.27154H20.375Z"
                stroke={scrollToMenu === 'qas' ? '#FF4A8E' : '#40424A'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12.5 16V16.01"
                stroke={scrollToMenu === 'qas' ? '#FF4A8E' : '#40424A'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12.5 12.9998C12.9498 13.0011 13.3868 12.8508 13.7407 12.5732C14.0945 12.2956 14.3444 11.9068 14.45 11.4696C14.5557 11.0324 14.5109 10.5724 14.3229 10.1638C14.1349 9.75524 13.8147 9.42195 13.414 9.21776C13.0162 9.01397 12.5611 8.95079 12.1228 9.03848C11.6845 9.12618 11.2888 9.3596 11 9.70076"
                stroke={scrollToMenu === 'qas' ? '#FF4A8E' : '#40424A'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_4179_19216">
                <rect width="24" height="24" fill="white" transform="translate(0.5)" />
              </clipPath>
            </defs>
          </svg>
          <p className="text-#3f424a] text-[11px] font-semibold font-['Manrope'] leading-3 tracking-wide">Interview</p>
        </button>
      )}

      {!!feedbacks?.length && (
        <button
          className="w-full h-[75px] flex flex-col justify-center items-center gap-[9px]"
          onClick={() => setScrollToMenu('feedbacks')}
        >
          <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.5 11V19C7.5 19.2652 7.39464 19.5196 7.20711 19.7071C7.01957 19.8946 6.76522 20 6.5 20H4.5C4.23478 20 3.98043 19.8946 3.79289 19.7071C3.60536 19.5196 3.5 19.2652 3.5 19V12C3.5 11.7348 3.60536 11.4804 3.79289 11.2929C3.98043 11.1054 4.23478 11 4.5 11H7.5ZM7.5 11C8.56087 11 9.57828 10.5786 10.3284 9.82843C11.0786 9.07828 11.5 8.06087 11.5 7V6C11.5 5.46957 11.7107 4.96086 12.0858 4.58579C12.4609 4.21071 12.9696 4 13.5 4C14.0304 4 14.5391 4.21071 14.9142 4.58579C15.2893 4.96086 15.5 5.46957 15.5 6V11H18.5C19.0304 11 19.5391 11.2107 19.9142 11.5858C20.2893 11.9609 20.5 12.4696 20.5 13L19.5 18C19.3562 18.6135 19.0834 19.1402 18.7227 19.501C18.3619 19.8617 17.9328 20.0368 17.5 20H10.5C9.70435 20 8.94129 19.6839 8.37868 19.1213C7.81607 18.5587 7.5 17.7957 7.5 17"
              stroke={scrollToMenu === 'feedbacks' ? '#FF4A8E' : '#40424A'}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="text-#3f424a] text-[11px] font-semibold font-['Manrope'] leading-3 tracking-wide">Feedback</p>
        </button>
      )}

      {!!quotes?.length && (
        <button
          className="w-full h-[75px] flex flex-col justify-center items-center gap-[9px]"
          onClick={() => setScrollToMenu('quotes')}
        >
          <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.502 11H6.50195C6.23674 11 5.98238 10.8946 5.79485 10.7071C5.60731 10.5196 5.50195 10.2652 5.50195 10V7C5.50195 6.73478 5.60731 6.48043 5.79485 6.29289C5.98238 6.10536 6.23674 6 6.50195 6H9.50195C9.76717 6 10.0215 6.10536 10.2091 6.29289C10.3966 6.48043 10.502 6.73478 10.502 7V13C10.502 15.667 9.16895 17.333 6.50195 18"
              stroke={scrollToMenu === 'quotes' ? '#FF4A8E' : '#40424A'}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.502 11H15.502C15.2367 11 14.9824 10.8946 14.7948 10.7071C14.6073 10.5196 14.502 10.2652 14.502 10V7C14.502 6.73478 14.6073 6.48043 14.7948 6.29289C14.9824 6.10536 15.2367 6 15.502 6H18.502C18.7672 6 19.0215 6.10536 19.2091 6.29289C19.3966 6.48043 19.502 6.73478 19.502 7V13C19.502 15.667 18.169 17.333 15.502 18"
              stroke={scrollToMenu === 'quotes' ? '#FF4A8E' : '#40424A'}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="text-#3f424a] text-[11px] font-semibold font-['Manrope'] leading-3 tracking-wide">Quotes</p>
        </button>
      )}
    </div>
  )
}
