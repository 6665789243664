import React from 'react'

const RayIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6195_3940)">
    <path d="M13.5 2L13.518 2.001L13.534 2.002L13.617 2.007L13.628 2.009H13.639L13.677 2.018L13.729 2.026L13.745 2.032L13.756 2.033L13.785 2.044L13.837 2.058L13.856 2.067L13.871 2.071L13.899 2.085L13.939 2.102L13.96 2.114L13.982 2.124L14.005 2.139L14.036 2.156L14.07 2.18L14.088 2.191L14.101 2.203L14.125 2.22L14.163 2.254L14.185 2.271L14.193 2.281L14.207 2.293L14.243 2.334L14.269 2.361L14.275 2.37C14.395 2.517 14.471 2.692 14.493 2.883L14.494 2.895L14.496 2.936L14.5 3V9H19.5C19.6753 8.99996 19.8475 9.04598 19.9993 9.13345C20.1512 9.22092 20.2774 9.34677 20.3654 9.49839C20.4533 9.65001 20.4998 9.82208 20.5003 9.99734C20.5007 10.1726 20.4551 10.3449 20.368 10.497L20.308 10.588L12.308 21.588C11.74 22.371 10.5 21.968 10.5 21V15H5.50001C5.32474 15 5.15254 14.954 5.00067 14.8666C4.84879 14.7791 4.72257 14.6532 4.63466 14.5016C4.54675 14.35 4.50023 14.1779 4.49976 14.0027C4.49929 13.8274 4.5449 13.6551 4.63201 13.503L4.69201 13.412L12.692 2.412L12.702 2.399L12.72 2.375L12.753 2.337L12.771 2.315L12.78 2.307L12.793 2.293L12.833 2.257L12.861 2.231L12.869 2.225C12.9862 2.12908 13.1237 2.06104 13.271 2.026L13.282 2.025L13.309 2.02L13.383 2.007L13.394 2.006L13.435 2.004L13.5 2Z" fill="#FF4A8E"/>
    </g>
    <defs>
    <clipPath id="clip0_6195_3940">
    <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
    </clipPath>
    </defs>
    </svg>
  )
}

export default RayIcon
