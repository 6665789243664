import React, { useContext } from 'react'
import StarsIcon from '../../../icons/Stars'
import { Feature } from './feature'
import PropTypes from 'prop-types'
import { ProjectContext } from '../../../../context/ProjectContext'

export function Roadmap({ features }) {
  const { meetings, notes, problems } = useContext(ProjectContext)

  const painPointsMax = features.reduce((acc, feature) => Math.max(acc, feature.pain_points?.length), 0)
  const needsMax = features.reduce((acc, feature) => Math.max(acc, feature.needs?.length), 0)

  return (
    <div className="w-full">
      <div className="pt-[24px] pb-[36px] px-[32px]">
        <div className="flex justify-between items-center">
          <p className="text-[#3f424a] text-4xl font-bold font-['DM_Sans'] leading-[41.40px]">Roadmap Suggestions</p>
          <StarsIcon />
        </div>
        <div>
          <span className="text-[#62708d] text-base font-normal font-['Manrope'] leading-[23px]">Based on </span>
          <span className="text-[#62708d] text-base font-bold font-['Manrope'] leading-[23px]">{meetings.length}</span>
          <span className="text-[#62708d] text-base font-normal font-['Manrope'] leading-[23px]"> Meetings・</span>
          <span className="text-[#62708d] text-base font-bold font-['Manrope'] leading-[23px]">{notes.length}</span>
          <span className="text-[#62708d] text-base font-normal font-['Manrope'] leading-[23px]"> Needs・</span>
          <span className="text-[#62708d] text-base font-bold font-['Manrope'] leading-[23px]">{problems.length}</span>
          <span className="text-[#62708d] text-base font-normal font-['Manrope'] leading-[23px]"> Pain-Points</span>
        </div>
      </div>

      {features.map((feature, index) => (
        <Feature key={index} feature={feature} index={index} needsMax={needsMax} painPointsMax={painPointsMax} />
      ))}
    </div>
  )
}

Roadmap.propTypes = {
  features: PropTypes.array,
}
