import React from 'react'

export function SlackIcon() {
  return (
    <svg width="79" height="79" viewBox="0 0 79 79" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M51.392 17.4612L40.7002 21.1871L38.4322 14.2212C37.4602 11.3053 38.9182 8.06533 41.9962 7.09334C44.9121 6.12136 48.1521 7.57934 49.124 10.6573L51.392 17.4612ZM48.1521 43.3808L58.8439 39.6549L55.118 28.153L44.4261 31.879L48.1521 43.3808ZM53.66 59.9045C54.47 62.1725 56.7379 63.7925 59.0059 63.7925C59.6539 63.7925 60.3019 63.6305 60.7879 63.4685C63.7038 62.4965 65.3238 59.2566 64.3518 56.3406L62.4078 50.5087L51.716 54.2346L53.66 59.9045Z"
        fill="#FFB300"
      />
      <path
        d="M28.7123 25.3988L18.0205 29.1247L15.7525 22.3208C14.7805 19.4049 16.2385 16.1649 19.3165 15.193C22.2324 14.221 25.4724 15.6789 26.4444 18.7569L28.7123 25.3988ZM30.9803 68.0042C31.7903 70.2721 34.0582 71.8921 36.3262 71.8921C36.9742 71.8921 37.6222 71.7301 38.1082 71.5681C41.0241 70.5961 42.6441 67.3562 41.6721 64.4402L39.7282 58.4463L29.0363 62.1722L30.9803 68.0042ZM32.2763 36.2526L21.5844 39.9786L25.3104 51.4804L36.0022 47.7545L32.2763 36.2526Z"
        fill="#00BFA5"
      />
      <path
        d="M54.956 28.3149L51.392 17.623L58.0339 15.3551C60.9499 14.3831 64.1898 15.8411 65.1618 18.919C66.1338 21.835 64.6758 25.0749 61.5979 26.0469L54.956 28.3149ZM44.2641 32.0408L40.7002 21.349L28.7124 25.5609L32.2763 36.2527L44.2641 32.0408ZM11.2166 31.5548C8.30069 32.5268 6.68071 35.7668 7.65269 38.6827C8.46268 41.1127 10.7306 42.5707 12.9986 42.5707C13.6466 42.5707 14.2946 42.4087 14.7806 42.2467L21.4225 39.9787L17.8585 29.2869L11.2166 31.5548Z"
        fill="#00BCD4"
      />
      <path
        d="M25.3102 51.3181L28.8742 62.0099L21.2603 64.6019C20.6123 64.7639 19.9643 64.9259 19.4783 64.9259C17.0484 64.9259 14.9424 63.4679 14.1324 61.038C13.1604 58.122 14.6184 54.8821 17.6964 53.9101L25.3102 51.3181ZM71.6415 41.2743C70.6696 38.3583 67.4296 36.7383 64.5136 37.7103L58.8437 39.6543L62.4077 50.5081L68.2396 48.5641C71.1555 47.4302 72.7755 44.1902 71.6415 41.2743ZM36.0021 47.5922L39.566 58.284L51.5538 54.0721L47.9899 43.3802L36.0021 47.5922Z"
        fill="#E91E63"
      />
      <path d="M54.956 28.3148L51.392 17.4609L40.7002 21.1869L44.2641 32.0407L54.956 28.3148Z" fill="#388E3C" />
      <path d="M28.7123 25.3984L18.0205 29.1244L21.5845 39.9782L32.2763 36.2523L28.7123 25.3984Z" fill="#00897B" />
      <path d="M48.1519 43.3802L51.7158 54.2341L62.4076 50.5081L58.8437 39.6543L48.1519 43.3802Z" fill="#BF360C" />
      <path d="M25.3101 51.3177L28.874 62.1716L39.5658 58.4456L36.0019 47.5918L25.3101 51.3177Z" fill="#4E342E" />
    </svg>
  )
}