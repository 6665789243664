import React, { useState } from 'react'
import RayIcon from '../../icons/Ray'
import { EditDialog } from '../../dialogs/editDialog'
import { createPortal } from 'react-dom'
// import { customFetch } from '../../../utils'
// import toast from 'react-hot-toast'
// import { useDebounce } from '../../../hooks/useDebounce'

const Problems = ({ project, problems, setProblems, meetings, setProject, participants, useTwoColumns = false }) => {
  const [showEditDialog, setShowEditDialog] = useState(false)
  const [noteHover, setNoteHover] = useState(null)
  const [noteContent, setNoteContent] = useState('')

  // const debouncedNoteContent = useDebounce(noteContent, 1000)
  //
  // useEffect(() => {
  //   if (!noteContent) return
  //   const handleChangeNoteContent = async (content) => {
  //     try {
  //       setProblems((prevNotes) => prevNotes.map((n) => (n.id === noteHover?.id ? { ...n, content } : n)))
  //       await customFetch('/updateProblemContent', 'PUT', { problemId: noteHover?.id, content })
  //     } catch (error) {
  //       console.log(error)
  //       toast.error('Whoops! Something went wrong. Please try again.')
  //     }
  //   }
  //   handleChangeNoteContent(noteContent)
  // }, [debouncedNoteContent])

  return (
    <div className="container mx-auto px-4">
      <div className={`grid grid-cols-1 md:grid-cols-2 ${useTwoColumns ? '' : 'lg:grid-cols-3 xl:grid-cols-4'} gap-4`}>
        {problems.map((item) => (
          <div
            className="max-w-sm p-6 bg-white rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 text-center"
            onDoubleClick={() => setShowEditDialog(true)}
            key={item.id}
            onMouseEnter={() => setNoteHover(item)}
          >
            <div className="flex justify-center">
              <RayIcon />
            </div>
            <h3 className="font-semibold text-lg mb-2 text-red-600 mt-2 capitalize">{item.title || item.referent}</h3>
            <p className="text-gray-700">{item.content}</p>
          </div>
        ))}
      </div>
      {showEditDialog &&
        createPortal(
          <EditDialog
            entity={noteHover}
            entityName={'problem'}
            project={project}
            participants={participants}
            rating={false}
            tagging={false}
            readOnly={true}
            setProject={setProject}
            setEntities={setProblems}
            meeting={meetings.find((meet) => meet.id === noteHover.meetingId) || {}}
            close={() => setShowEditDialog(false)}
            changeContent={(content) => setNoteContent(content)}
          />,
          document.body
        )}
    </div>
  )
}

export default Problems
