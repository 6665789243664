import React from 'react'
import { CloseIcon } from '@/components/icons/closeIcon'
import PauseIcon from '@/components/icons/Pause'
import PlayIcon from '@/components/icons/Play'
import * as PropTypes from 'prop-types'

export default function Validation(props) {
  const { validation } = props.meetingOverview
  return (
    <div className="flex gap-[6px]">
      {validation?.is_prospect_to_use_it === 'yes' ? (
        <>
          <div className="w-[18px] h-[18px] mt-1">
            <PlayIcon />
          </div>
          <p>
            <span className="text-[#3f424a] text-base font-bold font-['Manrope'] leading-[23px]">
              {props?.intervieweeName} is a Likely User.{' '}
            </span>
            <span className="text-[#3f424a] text-base font-normal font-['Manrope'] leading-[23px]">
              {validation?.reason}
            </span>
          </p>
        </>
      ) : validation?.is_prospect_to_use_it === 'no' ? (
        <>
          <div className="w-[18px] h-[18px] mt-1">
            <CloseIcon />
          </div>
          <p>
            <span className="text-[#3f424a] text-base font-bold font-['Manrope'] leading-[23px]">
              {props.intervieweeName} is an Unlikely User.{' '}
            </span>
            <span className="text-[#3f424a] text-base font-normal font-['Manrope'] leading-[23px]">
              {validation?.reason}
            </span>
          </p>
        </>
      ) : (
        <>
          <div className="w-[18px] h-[18px] mt-1">
            <PauseIcon />
          </div>
          <p>
            <span className="text-[#3f424a] text-base font-bold font-['Manrope'] leading-[23px]">
              {props.intervieweeName} is a Maybe User.{' '}
            </span>
            <span className="text-[#3f424a] text-base font-normal font-['Manrope'] leading-[23px]">
              {validation?.reason}
            </span>
          </p>
        </>
      )}
    </div>
  )
}

Validation.propTypes = {
  meetingOverview: PropTypes.any,
  intervieweeName: PropTypes.string,
}
