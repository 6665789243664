import React, { useContext } from 'react'
import { ProjectContext } from '../../../../context/ProjectContext'
import { StarsFilter } from '../filters/stars'
// import { TagsFilter } from './filters/tags'
import { MeetingsFilter } from '../filters/meetings'
import { Feedbacks } from '../../feedbacks/feedbacks'

export function OverviewFeedbacks() {
  const { filteredFeedbacksOverview, feedbacksOverviewFilters, setFeedbacksOverviewFilters } = useContext(ProjectContext)

  return (
    <div className="w-full">
      <div className='py-[8px] border-b-2 border-grey-200'>
        <p className="text-[#3f424a] text-4xl font-bold font-['DM_Sans'] leading-[41.40px]">Feedback</p>
      </div>

      <div
        className="w-full h-12 mt-[20px] mb-[6px] bg-white rounded-[10px] flex items-center"
        style={{ boxShadow: '0px 0px 16px 8px rgba(0, 0, 0, 0.15)' }}
      >
        <StarsFilter filters={feedbacksOverviewFilters} setFilters={setFeedbacksOverviewFilters} label={'Feedback'} />
        <MeetingsFilter filters={feedbacksOverviewFilters} setFilters={setFeedbacksOverviewFilters} />
        {/* <TagsFilter /> */}
      </div>

      <div className="w-full flex flex-wrap relative"> 
        <Feedbacks feedbacks={filteredFeedbacksOverview} overviewFlag={true} />
        {/* {filteredFeedbacks.map(feedback => <Feedback key={feedback.id} feedback={feedback} />)} */}
      </div>
    </div>
  )
}
