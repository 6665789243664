import React, { useContext } from 'react'
import { DashboardContext } from '../../context/DashboardContext'
import thumbnailDefault from '../../assets/audio-thumbnail.png'
import { formatFileDuration, formatMeetingTime } from '../../utils/formatDate'
import { useNavigate } from 'react-router-dom'
import { Hourglass } from 'lucide-react'

export const RecentMeetings = ({ setMenu }) => {
  const { sessions } = useContext(DashboardContext)
  const navigate = useNavigate()

  function handleSeeAll() {
    setMenu('meetings')
  }

  if (!sessions.length) return null

  return (
    <div className="bg-white rounded-2xl shadow pb-6 mt-12 w-[98%]">
      <div className="flex justify-between items-center px-6 py-5">
        <p className="text-zinc text-[26px] font-semibold font-manrope">Recent Meetings</p>
        <button
          className="w-10 h-10 rounded-full text-zinc-400 hover:text-zinc-800 bg-[#EBEBEB] flex items-center justify-center"
          onClick={handleSeeAll}
        >
          <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.999999 13L7 7L1 1"
              stroke="#40424A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>

      <div className="grid grid-cols-2 gap-4 px-6">
        {sessions
          .slice(0, 4)
          .filter((notNull) => notNull)
          .map((meeting, index) => (
            <div
              key={index}
              onClick={() => !meeting.processing && navigate(`/dashboard/meetings/${meeting.id}`)}
              className={`flex items-center bg-white rounded-lg shadow p-4 hover:shadow-md transition-shadow ${
                meeting.processing ? 'cursor-progress opacity-70' : 'cursor-pointer'
              }`}
            >
              <div className="w-16 h-12 flex-shrink-0 overflow-hidden rounded-md relative">
                <img
                  src={meeting.thumbnailUrl || meeting.thumbnail || thumbnailDefault}
                  alt={meeting.name}
                  className="w-full h-full object-cover"
                />
                {meeting.processing && (
                  <div className="absolute inset-0 bg-black/50 rounded flex items-center justify-center">
                    <div className="bg-white rounded-full p-1.5">
                      <Hourglass className="w-4 h-4 text-[#3F424A]" />
                    </div>
                  </div>
                )}
              </div>
              <div className="ml-4 overflow-hidden">
                <p className="text-zinc font-semibold overflow-hidden text-ellipsis">{meeting.name}</p>
                <p>
                  {formatMeetingTime(meeting).dayAndMonth} ・{formatMeetingTime(meeting).startTime}{' '}
                  {formatMeetingTime(meeting).endTime && ` - ${formatMeetingTime(meeting).endTime}`}
                  {formatFileDuration(meeting.duration) && ` ・ ${formatFileDuration(meeting.duration)}`}
                </p>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
