import clsx from 'clsx'
import { Title } from '@/components/typography/Title'
import AIIcon from '@/components/icons/AI'
import { Subtitle } from '@/components/typography/Subtitle'
import { InfoIcon } from '@/components/icons/Info'
import Validation from '@/components/meeting/sidebar/Validation'
import React from 'react'
import * as PropTypes from 'prop-types'
import { BulletIcon } from '@/components/icons/Bullet'

export default function ExecutiveSummary({ meetingOverview, intervieweeName }) {
  const { executive_summary, validation } = meetingOverview
  const takeaways = Object.entries(executive_summary).filter(([key, value]) => key.startsWith('takeaway_') && value)
  return (
    <div
      className={clsx(
        'mt-[30px] bg-white px-[28px] pt-[28px] rounded-[5px] border border-[#e4e4e4] flex flex-col gap-[16px]',
        validation ? 'pb-[35px]' : 'pb-[28px]'
      )}
    >
      <div className="flex items-center justify-between">
        <Title size="text-[22px]">Executive Summary</Title>
        <AIIcon />
      </div>
      <div className=" border-b border-b-[#e4e4e4] pb-[10px]">
        <Subtitle size="text-[12px]" weight={'font-extrabold'}>
          {intervieweeName}
        </Subtitle>
      </div>

      <div className="flex flex-wrap items-center pb-[10px] border-b border-b-[#e4e4e4] gap-x-[8px] gap-y-[10px]">
        <div className="flex items-center gap-[6px] bg-[#f9f9f9] rounded-[30px] py-[4px] px-[10px]">
          <InfoIcon />
          <p className="text-[#62708d] text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">
            {executive_summary?.interviewee_bg1 || 'Not Specified'}
          </p>
        </div>

        <div className="flex items-center gap-[6px] bg-[#f9f9f9] rounded-[30px] px-[10px] py-[4px]">
          <InfoIcon />
          <p className="text-[#62708d] text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">
            {executive_summary?.interviewee_bg2 || 'Not Specified'}
          </p>
        </div>

        <div className="flex items-center gap-[6px] bg-[#f9f9f9] rounded-[30px] px-[10px] py-[4px]">
          <InfoIcon />
          <p className="text-[#62708d] text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">
            {executive_summary?.interviewee_bg3 || 'Not Specified'}
          </p>
        </div>
      </div>

      {!!takeaways.length && (
        <>
          <div className=" border-b border-b-[#e4e4e4] pb-[2px]">
            <Subtitle size="text-[12px]" weight={'font-extrabold'}>
              {intervieweeName} CARES ABOUT
            </Subtitle>
          </div>
          <ul className="pb-[10px] border-b border-b-[#e4e4e4] flex flex-col gap-[20px]">
            {takeaways.map(([key, value], index) => (
              <li key={index} className="flex gap-[6px] items-center">
                <BulletIcon />
                <p className="text-[#3f424a] text-base font-normal font-['Manrope'] leading-[23px]">{value}</p>
              </li>
            ))}
          </ul>
        </>
      )}

      {!!validation && ['yes', 'no', 'maybe'].includes(validation?.is_prospect_to_use_it) && (
        <Validation meetingOverview={meetingOverview} intervieweeName={intervieweeName} />
      )}
    </div>
  )
}

ExecutiveSummary.propTypes = {
  meetingOverview: PropTypes.any,
  intervieweeName: PropTypes.string,
}
